import { Typography, Box, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext } from 'react'
import { trackPromise } from 'react-promise-tracker'
import ButtonLoader from '../../../components/button-loader'
import { StoreContext } from '../../../stores/store.context'
import WarningIcon from '@mui/icons-material/Warning'
import TransferUserDataModal from './transfer-user-data.modal'

interface DeleteUserModalProps {
  id: string
}

const DeleteUserModal: React.FC<DeleteUserModalProps> = (props: DeleteUserModalProps) => {
  const { modalStore, i18nStore, userStore, toastStore, authStore } = useContext(StoreContext)

  const deleteUser = async () => {
    try {
      const deleted = await trackPromise(userStore.deleteUser(props.id), 'delete-user')
      if (deleted) {
        toastStore.pushToast({ message: i18nStore.dictionary.userDeleted, type: 'success' })
        if (authStore.tokenData) {
          await userStore.listUsersByTenant(
            authStore.tokenData.tenant,
            parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
            parseInt(process.env.REACT_APP_DATA_LIMIT as string)
          )
        }
      }
      modalStore.handleClose()
    } catch (e: any) {
      modalStore.handleClose()

      if (e.code === 403) {
        modalStore.openModal(<TransferUserDataModal message={e.message} id={props.id} action="delete" />, {
          size: 'sm',
        })
        return
      }

      toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  return (
    <Fragment>
      <WarningIcon color="warning" sx={{ alignSelf: 'center', fontSize: '5rem' }} />

      <Typography variant="h4" color={'text.primary'} align="center" sx={{ my: 3 }}>
        {i18nStore.dictionary.areYouSureDeleteUser}
      </Typography>

      <Typography variant="body2" color={'secondary.lighter'} align="center" sx={{ mb: 3 }}>
        {i18nStore.dictionary.deleteUserMessage}
      </Typography>

      <Typography variant="body2" color={'secondary.lighter'} align="center" sx={{ mb: 3 }}>
        {i18nStore.dictionary.cannotBeUndoneAction}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          disableElevation
          size="large"
          type="button"
          variant="text"
          color="primary"
          onClick={() => modalStore.handleClose()}
          sx={{ mr: 2 }}
        >
          {i18nStore.dictionary.noBack}
        </Button>

        <ButtonLoader
          loadingText="Processando..."
          text={i18nStore.dictionary.yesContinue}
          area="delete-user"
          disabled={!props.id}
          onClick={() => deleteUser()}
        />
      </Box>
    </Fragment>
  )
}

export default observer(DeleteUserModal)
