import { Box, Container, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext } from 'react'
import ContentArea from '../../../components/content-area'
import { StoreContext } from '../../../stores/store.context'
import HowItWorks from '../components/how-it-works'

const DashboardAdmin: React.FC = () => {
  const { authStore, i18nStore, themeStore } = useContext(StoreContext)

  return (
    <Fragment>
      <Box
        sx={{
          background: `linear-gradient(to right, #12192f, ${themeStore.theme.palette.primary.main})`,
          width: 'calc(100vw)',
          marginLeft: 'calc(-50vw + 50%)',
          display: 'flex',
          paddingY: 4,
          alignItems: 'center',
          mb: 5,
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h2" color="primary.contrastText" fontWeight={400} sx={{ maxWidth: '335px' }}>
            {i18nStore.dictionary.bannerText05}{' '}
            <Box display="inline" fontWeight={700} color="primary.main">
              {i18nStore.dictionary.bannerText06}{' '}
            </Box>
            {i18nStore.dictionary.bannerText07}{' '}
            <Box display="inline" fontWeight={700} color="primary.main">
              {i18nStore.dictionary.bannerText08}
            </Box>
          </Typography>
        </Container>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' } }}>
        <Typography variant="h2">{i18nStore.dictionary.welcomeMessage} </Typography>
        <Typography color="primary" variant="h2" sx={{ textAlign: { xs: 'center', sm: 'left' }, ml: { xs: 0, sm: 2 } }}>
          {authStore.loggedUserFullName}
        </Typography>
      </Box>

      <ContentArea>
        <Grid container>
          <Grid item xs={12}>
            <HowItWorks />
          </Grid>
        </Grid>
      </ContentArea>
    </Fragment>
  )
}

export default observer(DashboardAdmin)
