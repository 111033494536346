import { Menu, MenuList, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteCompetenceModal from '../modals/delete-competence.modal'
import { StoreContext } from '../../../stores/store.context'
import { Competence } from '../../../models/competence.model'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ModalOpts } from '../../../models/modal.model'
import AddCompetenceForm from '../forms/add-competence-form'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import EditIcon from '@mui/icons-material/Edit'
import { RolesEnum } from '../../../enums/roles.enum'

interface CompetenceMenuProps {
  competence: Competence
}

const CompetenceMenu: React.FC<CompetenceMenuProps> = (props: CompetenceMenuProps) => {
  const { i18nStore, modalStore, tenantStore, authStore } = useContext(StoreContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const checkAccess = () => {
    return (
      tenantStore.tenant &&
      authStore.tokenData &&
      (tenantStore.tenant.settings.allowEvaluatorsCreateAndDeleteCompetencies ||
        authStore.tokenData.role === RolesEnum.administrator)
    )
  }

  return (
    <Fragment>
      <IconButton color="primary" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        sx={{
          width: 320,
          maxWidth: '100%',
          '& .MuiPaper-root': {
            backgroundColor: 'background.default',
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              const modalOpts: ModalOpts = {
                size: 'lg',
              }
              modalStore.openModal(<AddCompetenceForm _id={props.competence._id} />, modalOpts)
              handleClose()
            }}
          >
            <ListItemIcon>
              <RemoveRedEyeIcon fontSize="small" sx={{ color: 'primary.main' }} />
            </ListItemIcon>
            <ListItemText>{i18nStore.dictionary.view}</ListItemText>
          </MenuItem>
          {checkAccess() && (
            <MenuItem
              onClick={() => {
                const modalOpts: ModalOpts = {
                  size: 'lg',
                }
                modalStore.openModal(<AddCompetenceForm _id={props.competence._id} />, modalOpts)
                handleClose()
              }}
            >
              <ListItemIcon>
                <EditIcon fontSize="small" sx={{ color: 'warning.main' }} />
              </ListItemIcon>
              <ListItemText>{i18nStore.dictionary.edit}</ListItemText>
            </MenuItem>
          )}
          {checkAccess() && (
            <MenuItem
              onClick={() => {
                modalStore.openModal(<DeleteCompetenceModal id={props.competence._id} />, { size: 'sm' })
                handleClose()
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" sx={{ color: 'error.main' }} />
              </ListItemIcon>
              <ListItemText>{i18nStore.dictionary.delete}</ListItemText>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Fragment>
  )
}

export default observer(CompetenceMenu)
