import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect } from 'react'
import { StoreContext } from '../../stores/store.context'
import { Box, Typography, Grid, FormControlLabel, Switch } from '@mui/material'
import ContentArea from '../../components/content-area'
import BreadCrumb from '../../components/breadcrumb'
import ButtonLoader from '../../components/button-loader'
import { trackPromise } from 'react-promise-tracker'

const SettingsPage: React.FC = () => {
  const { breadcrumbStore, i18nStore, authStore, tenantStore, toastStore } = useContext(StoreContext)

  useEffect(() => {
    breadcrumbStore.setBreadcrumbItems([
      {
        name: i18nStore.dictionary.settings,
        link: '/secure/settings',
      },
    ])

    const loadTenantSettings = async () => {
      if (authStore.tokenData) {
        await tenantStore.getTenantById(authStore.tokenData.tenant)
      }
    }

    loadTenantSettings()
  }, [authStore.tokenData, breadcrumbStore, i18nStore.dictionary.settings, tenantStore])

  const saveSettings = async () => {
    const settingsSaved = await trackPromise(tenantStore.saveSettings(), 'save-settings')
    if (settingsSaved) {
      toastStore.pushToast({
        message: i18nStore.dictionary.settingsSaved,
        type: 'success',
      })
    }
  }

  return (
    <Fragment>
      <Box>
        <BreadCrumb />
        <Typography variant="h2">{i18nStore.dictionary.systemSettings}</Typography>
      </Box>

      <ContentArea>
        <Grid container>
          {tenantStore.tenant && (
            <Grid container display="flex" justifyContent="space-between" alignItems="center" spacing={2}>
              <Grid item xs={12} display="flex">
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(event) => {
                        if (tenantStore.tenant) {
                          tenantStore.tenant = {
                            ...tenantStore.tenant,
                            settings: {
                              ...tenantStore.tenant.settings,
                              allowEvaluatorsCreateAndDeleteCompetencies: event.target.checked,
                            },
                          }
                        }
                      }}
                      checked={tenantStore.tenant.settings.allowEvaluatorsCreateAndDeleteCompetencies}
                    />
                  }
                  label={i18nStore.dictionary.allowEvaluatorsCreateAndDeleteCompetencies}
                />
              </Grid>
              <Grid item xs={12} display="flex">
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(event) => {
                        if (tenantStore.tenant) {
                          tenantStore.tenant = {
                            ...tenantStore.tenant,
                            settings: {
                              ...tenantStore.tenant.settings,
                              allowEvaluatorsInviteAndDeleteUsers: event.target.checked,
                            },
                          }
                        }
                      }}
                      checked={tenantStore.tenant.settings.allowEvaluatorsInviteAndDeleteUsers}
                    />
                  }
                  label={i18nStore.dictionary.allowEvaluatorsInviteAndDeleteUsers}
                />
              </Grid>
              <Grid item xs={12} display="flex">
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(event) => {
                        if (tenantStore.tenant) {
                          tenantStore.tenant = {
                            ...tenantStore.tenant,
                            settings: {
                              ...tenantStore.tenant.settings,
                              allowEvaluatorsCreateAndDeleteAssessments: event.target.checked,
                            },
                          }
                        }
                      }}
                      checked={tenantStore.tenant.settings.allowEvaluatorsCreateAndDeleteAssessments}
                    />
                  }
                  label={i18nStore.dictionary.allowEvaluatorsCreateAndDeleteAssessments}
                />
              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center', mt: 4 }}>
                <ButtonLoader
                  onClick={saveSettings}
                  loadingText={i18nStore.dictionary.loading}
                  text={i18nStore.dictionary.save}
                  area="save-settings"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </ContentArea>
    </Fragment>
  )
}

export default observer(SettingsPage)
