import axios from 'axios'
import { AssessmentUserReport, CompetenceReport } from '../models/report.model'
import { Pagination } from '../models/pagination.model'

export class ReportService {
  async generateReportAssessmentUser(assessmentId: string, userId: string): Promise<AssessmentUserReport> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/report/assessment/${assessmentId}/user/${userId}`)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async generateReportCompetence(
    tenandId: string,
    competenceId: string,
    competenceValueId: string,
    reached: Array<number>,
    priority: Array<number>,
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<CompetenceReport>>> {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API +
          `/report/competence/${tenandId}?competenceId=${competenceId}&valueId=${competenceValueId}`,
        {
          params: {
            reachedStart: reached[0],
            reachedEnd: reached[1],
            priorityStart: priority[0],
            priorityEnd: priority[1],
            page,
            limit,
            orderBy,
            orderByDirection,
            filter,
          },
        }
      )
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async exportReportCompetence(
    tenandId: string,
    competenceId: string,
    competenceValueId: string,
    reached: Array<number>,
    priority: Array<number>,
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<CompetenceReport>>> {
    try {
      const res = await axios.get(
        process.env.REACT_APP_API +
          `/report/competence/${tenandId}/export?competenceId=${competenceId}&valueId=${competenceValueId}`,
        {
          params: {
            reachedStart: reached[0],
            reachedEnd: reached[1],
            priorityStart: priority[0],
            priorityEnd: priority[1],
            page,
            limit,
            orderBy,
            orderByDirection,
            filter,
          },
        }
      )
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }
}
