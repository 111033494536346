import { Box, Button, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import ThemeSwitcher from '../../components/theme-switcher'
import AuthForm from './auth-form'
import LoginBackground from '../../assets/images/login-bg'
import { Fragment, useContext, useEffect } from 'react'
import { StoreContext } from '../../stores/store.context'
import { useLocation, useNavigate } from 'react-router-dom'
import LanguageSwitcher from '../../components/language-switcher'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const maxVH = {
  minHeight: '100vh',
}

const LoginPage: React.FC = () => {
  const { authStore, i18nStore } = useContext(StoreContext)
  const location = useLocation()
  let navigate = useNavigate()

  useEffect(() => {
    if (authStore.authenticated) {
      navigate(!location.state?.from?.pathname ? '/secure/dashboard' : location.state?.from?.pathname)
    }
  }, [authStore.authenticated, location, navigate])

  return (
    <Fragment>
      <Grid container justifyContent="center" alignItems="center" sx={{ overflowY: 'hidden' }}>
        <Grid container item xs={6} display={{ xs: 'none', sm: 'flex' }} sx={{ position: 'relative', ...maxVH }}>
          <LoginBackground />
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', px: 6 }}>
            <Typography variant="h1" color={'white'} sx={{ marginBottom: 'auto', marginTop: 'auto' }}>
              {i18nStore.dictionary.welcomeTitle}
            </Typography>
          </Box>
        </Grid>
        <Grid
          container
          direction={'column'}
          item
          xs={12}
          sm={6}
          paddingLeft={6}
          paddingRight={6}
          justifyContent="space-between"
          alignItems="center"
          sx={maxVH}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pr: 6, width: '100%' }}>
            <Button
              variant="text"
              onClick={() => navigate('/')}
              sx={{
                mr: 2,
                color: 'text.primary',
              }}
            >
              <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} />{' '}
              <Typography variant="body2">{i18nStore.dictionary.backHome}</Typography>
            </Button>

            <LanguageSwitcher />
          </Box>
          <Box
            sx={{
              maxWidth: '410px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 'auto',
              marginTop: 'auto',
            }}
          >
            <Box sx={{ alignSelf: 'flex-end' }}>
              <ThemeSwitcher />
            </Box>

            <AuthForm />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default observer(LoginPage)
