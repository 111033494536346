/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  debounce,
  Slider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Rating,
  Link,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import ContentArea from '../../components/content-area'
import { StoreContext } from '../../stores/store.context'
import BreadCrumb from '../../components/breadcrumb'
import { trackPromise } from 'react-promise-tracker'
import ButtonLoader from '../../components/button-loader'
import { Competence } from '../../models/competence.model'
import NotFoundData from '../../components/not-found'
import { saveAs } from 'file-saver'
const ExcelJS = require('exceljs')

const ReportCompetencePage: React.FC = () => {
  const { i18nStore, authStore, reportStore, competenceStore, breadcrumbStore, themeStore } = useContext(StoreContext)
  const [openCompetencies, setOpenCompetencies] = useState(false)
  const [selectedCompetence, setCompetence] = useState<Competence | null>(null)
  const [competenceInputValue, setCompetenceInputValue] = useState('')
  const [openCompetenceValues, setOpenCompetenceValues] = useState(false)
  const [selectedCompetenceValue, setCompetenceValue] = useState<string | null>(null)
  const [competenceValueInputValue, setCompetenceValueInputValue] = useState('')
  const [reached, setReached] = useState<number[]>([0, 5])
  const [priority, setPriority] = useState<number[]>([1, 3])
  const [page] = useState<number>(1)

  const fetchCompetenciesOnType = useMemo(
    () =>
      debounce((request: { input: string }) => {
        if (authStore.tokenData) {
          var filter = JSON.stringify({
            name: request.input,
            status: 'active',
            lang: i18nStore.language,
          })

          getCompetencies(filter)
        }
      }, 1000),
    []
  )

  const getCompetencies = async (filter: any) => {
    await trackPromise(
      competenceStore.listCompetencies(
        authStore.tokenData!.tenant,
        competenceStore.competenciesListPage,
        parseInt(process.env.REACT_APP_DATA_LIMIT as string),
        'createdAt',
        'desc',
        filter
      ),
      'loading-Assessments'
    )
  }

  useEffect(() => {
    breadcrumbStore.setBreadcrumbItems([
      {
        name: i18nStore.dictionary.reportCompetenciesTitle,
        link: '/secure/reports/assessments',
      },
    ])

    reportStore.reportAssessmentUser = null
    getCompetencies({})
  }, [])

  useEffect(() => {
    if (reportStore.reportCompetenceListPage > 1) {
      onGenerateReport()
    }
  }, [reportStore.reportCompetenceListPage])

  const onGenerateReport = async () => {
    if (reached && priority) {
      await trackPromise(
        reportStore.generateReportCompetence(
          selectedCompetence ? selectedCompetence._id : '',
          selectedCompetenceValue ?? '',
          reached,
          priority,
          reportStore.reportCompetenceListPage,
          parseInt(process.env.REACT_APP_DATA_LIMIT as string),
          'createdAt',
          'desc',
          ''
        ),
        'generating-report'
      )
    }
  }

  const onExportReport = async () => {
    if (reached && priority) {
      await trackPromise(
        reportStore.exportReportCompetence(
          selectedCompetence ? selectedCompetence._id : '',
          selectedCompetenceValue ?? '',
          reached,
          priority,
          page,
          parseInt(process.env.REACT_APP_DATA_LIMIT as string),
          'createdAt',
          'desc',
          ''
        ),
        'exporting-report'
      )

      handleDownload()
    }
  }

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook()

    workbook.addWorksheet('sheet1')
    const worksheet = workbook.getWorksheet('sheet1')

    worksheet.columns = [
      { header: i18nStore.dictionary.assessment, key: 'assessment' },
      { header: i18nStore.dictionary.competence, key: 'competence' },
      { header: i18nStore.dictionary.nameLabel, key: 'name' },
      { header: i18nStore.dictionary.value, key: 'value' },
      { header: i18nStore.dictionary.reached, key: 'reachedLevel' },
      { header: i18nStore.dictionary.priority, key: 'priority' },
      { header: i18nStore.dictionary.assessmentSuggestionLabel, key: 'suggestion' },
    ]

    for (const repo of reportStore.reportCompetenceExport!.paginatedData) {
      worksheet.addRow([
        repo.assessment,
        repo.competence,
        repo.userName,
        repo.competenceValue,
        repo.reachedLevel,
        repo.priorityLevel,
        repo.suggestion,
      ])
    }

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const a = window.document.createElement('a')
      const downloadUrl = window.URL.createObjectURL(blob)
      a.href = downloadUrl
      a.download = `data.xlsx`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(downloadUrl)
    })
  }

  return (
    <Fragment>
      <Box>
        <BreadCrumb />
        <Typography variant="h2">{i18nStore.dictionary.reportCompetenciesTitle}</Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {i18nStore.dictionary.reportCompetenciesSubtitle}
        </Typography>
      </Box>

      <ContentArea>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="competencies-autocomplete"
              open={openCompetencies}
              onOpen={() => setOpenCompetencies(true)}
              onClose={() => setOpenCompetencies(false)}
              onKeyUp={(e) => fetchCompetenciesOnType({ input: (e.target as any).value })}
              onChange={(_, newValue, reason) => {
                setCompetence(newValue ? newValue : null)
                if (reason === 'clear') {
                  getCompetencies({})
                  return
                }
              }}
              onInputChange={(_, newInputValue) => setCompetenceInputValue(newInputValue)}
              inputValue={competenceInputValue}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              includeInputInList
              getOptionLabel={(option: any) => option.name[i18nStore.language]}
              options={competenceStore.competencies ? competenceStore.competencies.paginatedData : []}
              renderInput={(params) => (
                <>
                  <TextField
                    name="competence"
                    {...params}
                    label={i18nStore.dictionary.competence}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
                    }}
                  />
                </>
              )}
            />
          </Grid>

          {selectedCompetence && (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                id="competence-values-autocomplete"
                open={openCompetenceValues}
                onOpen={() => setOpenCompetenceValues(true)}
                onClose={() => setOpenCompetenceValues(false)}
                onChange={(_, newValue, reason) => {
                  if (newValue && newValue._id) {
                    setCompetenceValue(newValue ? newValue._id : null)
                  }

                  if (reason === 'clear') {
                    setCompetenceValue(null)
                    return
                  }
                }}
                onInputChange={(_, newInputValue) => setCompetenceValueInputValue(newInputValue)}
                inputValue={competenceValueInputValue}
                isOptionEqualToValue={(option, value) => option._id === value._id}
                includeInputInList
                getOptionLabel={(option: any) => option.name[i18nStore.language]}
                options={selectedCompetence.competenceValues ? selectedCompetence.competenceValues : []}
                renderInput={(params) => (
                  <TextField
                    name="competence-value"
                    {...params}
                    label={i18nStore.dictionary.values}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <Fragment>{params.InputProps.endAdornment}</Fragment>,
                    }}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={4} textAlign={'center'} sx={{ mt: 2 }}>
            <Fragment>
              <Slider
                disableSwap
                name={`reached`}
                value={reached}
                defaultValue={0}
                step={1}
                marks
                min={0}
                max={5}
                valueLabelDisplay="on"
                onChange={(e) => setReached((e.target as any).value)}
              />
              <Typography variant="subtitle1">{i18nStore.dictionary.reached}</Typography>
            </Fragment>
          </Grid>

          <Grid item xs={12} sm={4} textAlign={'center'} sx={{ mt: 2 }}>
            <Fragment>
              <Slider
                disableSwap
                name={`priority`}
                value={priority}
                defaultValue={0}
                step={1}
                marks
                min={0}
                max={3}
                valueLabelDisplay="on"
                onChange={(e) => setPriority((e.target as any).value)}
              />
              <Typography variant="subtitle1">{i18nStore.dictionary.priority}</Typography>
            </Fragment>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center" alignItems={'center'} sx={{ marginLeft: 'auto' }}>
            <ButtonLoader
              variant="contained"
              onClick={onGenerateReport}
              loadingText={i18nStore.dictionary.loading}
              text={i18nStore.dictionary.generateReport}
              area="generating-report"
              sx={{ width: '100%', flexBasis: 'content' }}
            />

            <ButtonLoader
              variant="contained"
              onClick={onExportReport}
              loadingText={i18nStore.dictionary.loading}
              text={i18nStore.dictionary.exportExcel}
              area="exporting-report"
              sx={{
                width: '100%',
                flexBasis: 'content',
                ml: '10px',
                background: themeStore.theme.palette.success.main,
              }}
            />

            {reportStore.downloadReport && <Link onClick={handleDownload}>Download</Link>}
          </Grid>
        </Grid>

        {reportStore.reportCompetence && reportStore.reportCompetence.paginatedData.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{i18nStore.dictionary.assessment}</TableCell>
                  <TableCell>{i18nStore.dictionary.competence}</TableCell>
                  <TableCell>{i18nStore.dictionary.value}</TableCell>
                  <TableCell>{i18nStore.dictionary.user}</TableCell>
                  <TableCell>{i18nStore.dictionary.assessmentSuggestionLabel}</TableCell>
                  <TableCell align="center">{i18nStore.dictionary.reached}</TableCell>
                  <TableCell align="center">{i18nStore.dictionary.priority}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportStore.reportCompetence.paginatedData.map((row, i) => (
                  <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.assessment}
                    </TableCell>
                    <TableCell>{row.competence}</TableCell>
                    <TableCell>{row.competenceValue}</TableCell>
                    <TableCell>{row.userName}</TableCell>
                    <TableCell>{row.suggestion}</TableCell>
                    <TableCell align="center">
                      <Rating name="read-only" value={row.reachedLevel} readOnly />
                    </TableCell>
                    <TableCell align="center">
                      <Rating name="read-only" value={row.priorityLevel} max={3} readOnly />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {reportStore.reportCompetence && reportStore.reportCompetence.totalPages > 0 && (
          <Grid container sx={{ mt: 6, display: 'flex', alignItems: 'center' }}>
            <Grid item xs={12} sm={3} display="flex" justifyContent="center" sx={{ marginLeft: 'auto' }}>
              <ButtonLoader
                variant="contained"
                onClick={() => (reportStore.reportCompetenceListPage = reportStore.reportCompetenceListPage + 1)}
                disabled={reportStore.reportCompetence.totalPages === reportStore.reportCompetenceListPage}
                loadingText={i18nStore.dictionary.loading}
                text={i18nStore.dictionary.loadMore}
                area="load-more"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}
              sx={{ display: 'flex', mt: { xs: 2, sm: 0 }, justifyContent: { xs: 'center', sm: 'end' } }}
            >
              <Typography component="span" variant="subtitle2">
                {i18nStore.dictionary.showing}
              </Typography>
              <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
                {reportStore.reportCompetence.paginatedData.length}
              </Typography>
              <Typography component="span" variant="subtitle2" sx={{ textTransform: 'lowercase' }}>
                {i18nStore.dictionary.of}
              </Typography>
              <Typography component="span" variant="subtitle2" sx={{ ml: 1 }}>
                {reportStore.reportCompetence.totalItems}
              </Typography>
            </Grid>
          </Grid>
        )}

        {reportStore.reportCompetence && reportStore.reportCompetence.paginatedData.length === 0 && (
          <Grid item xs={12}>
            <NotFoundData />
          </Grid>
        )}
      </ContentArea>
    </Fragment>
  )
}

export default observer(ReportCompetencePage)
