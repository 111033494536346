/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../stores/store.context'
import BreadCrumb from '../../components/breadcrumb'
import ContentArea from '../../components/content-area'
import { UserData } from '../../models/user.model'
import ButtonLoader from '../../components/button-loader'
import InviteUserForm from './forms/invite-user-form'
import { ModalOpts } from '../../models/modal.model'
import UserMenu from './menus/user-menu'
import NotFoundData from '../../components/not-found'
import { SearchOutlined } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import { RolesEnum } from '../../enums/roles.enum'
import PageLoader from '../../components/page-loader'
import { trackPromise } from 'react-promise-tracker'
import GridSwitcher from '../../components/grid-switcher'

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: `0px 2px 5px -1px ${theme.palette.grey[300]}, 0px 1px 5px 0px ${theme.palette.grey[200]}, 0px 1px 15px 0px ${theme.palette.grey[100]}`,
}))

const ManageUsersPage: React.FC = () => {
  let [page, setPage] = useState<number>(1)
  const [status] = useState('all')
  const [search, setSearch] = useState('')
  const [tempSearch, setTempSearch] = useState('')
  const [searchPosition, setSearchPosition] = useState('')
  const [gridView, setGridView] = useState<string>('card')

  const { userStore, authStore, breadcrumbStore, i18nStore, modalStore, generalStore, tenantStore } =
    useContext(StoreContext)

  const searchBy = (event: any) => {
    if (tempSearch.includes(':')) {
      var searchField = tempSearch.split(':')[0].toLowerCase().trim()
      var searchValue = tempSearch.split(':')[1].toLowerCase().trim()
      if (searchField) {
        if (searchField === 'position' || searchField === 'cargo') {
          setSearchPosition(searchValue)
          setSearch('')
        }
      }
    } else {
      setSearchPosition('')
      setSearch(event && event.target ? (event.target.value as string) : tempSearch)
    }
  }

  useEffect(() => {
    breadcrumbStore.setBreadcrumbItems([
      {
        name: i18nStore.dictionary.manageUsers,
        link: '/secure/manage-users',
      },
    ])
    const loadUsersByTenant = async () => {
      if (authStore.tokenData) {
        var filter = JSON.stringify({
          name: search,
          position: searchPosition,
          status: status === 'all' ? '' : status,
        })

        await trackPromise(
          userStore.listUsersByTenant(
            authStore.tokenData.tenant,
            search ? 1 : page,
            parseInt(process.env.REACT_APP_DATA_LIMIT as string),
            'createdAt',
            'desc',
            filter
          ),
          'page-loader'
        )
      }
    }

    loadUsersByTenant()
  }, [authStore, userStore, breadcrumbStore, i18nStore.dictionary, search, searchPosition, page])

  return (
    <Fragment>
      <PageLoader />
      <Box>
        <BreadCrumb />
        <Typography variant="h2">{i18nStore.dictionary.manageUsers}</Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {i18nStore.dictionary.manageUsersSubtitle}
        </Typography>
      </Box>

      <ContentArea>
        <Grid container>
          <Grid container display="flex" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={5} display="flex">
              <TextField
                label={i18nStore.dictionary.searchByUserName}
                value={tempSearch}
                onChange={(e) => setTempSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') searchBy(e)
                }}
                fullWidth
                placeholder={i18nStore.dictionary.searchBy}
                InputProps={{
                  startAdornment: (
                    <IconButton color="primary" onClick={() => setSearch(tempSearch)}>
                      <SearchOutlined />
                    </IconButton>
                  ),
                  endAdornment: tempSearch && (
                    <IconButton
                      onClick={() => {
                        setSearch('')
                        setTempSearch('')
                        setPage(1)
                      }}
                    >
                      <CancelIcon sx={{ color: 'primary.main' }} />
                    </IconButton>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <GridSwitcher onChangeGridView={(e) => setGridView(e)} />
            </Grid>

            {tenantStore.tenant &&
              authStore.tokenData &&
              (tenantStore.tenant.settings.allowEvaluatorsInviteAndDeleteUsers ||
                authStore.tokenData.role === RolesEnum.administrator ||
                authStore.tokenData.role === RolesEnum.master) && (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  display="flex"
                  sx={{ justifyContent: { xs: 'center', sm: 'end' }, mt: { xs: 2, sm: 0 } }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      const modalOpts: ModalOpts = {
                        size: 'md',
                      }
                      modalStore.openModal(<InviteUserForm />, modalOpts)
                    }}
                  >
                    {i18nStore.dictionary.inviteEmployee}
                  </Button>
                </Grid>
              )}
          </Grid>

          <Grid container sx={{ my: 3 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {userStore.users && (
            <Grid container spacing={2}>
              {gridView === 'card' && (
                <>
                  {userStore.users.paginatedData.map((user: UserData, index: number) => {
                    return (
                      <Grid item xs={12} sm={4} key={index}>
                        <StyledCard>
                          <CardHeader
                            avatar={
                              <Avatar sx={{ backgroundColor: 'secondary.main' }}>
                                {userStore.getUserInitials(user.firstName, user.lastName)}
                              </Avatar>
                            }
                            action={<UserMenu user={user} />}
                            title={<Typography variant="h5">{user.firstName + ' ' + user.lastName}</Typography>}
                            subheader={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography color="text.primary" variant="caption">
                                  {i18nStore.dictionary.createdAt}:
                                </Typography>
                                <Typography variant="caption" color="text.primary" sx={{ ml: 1 }}>
                                  {new Date(user.createdAt).toLocaleDateString('pt-BR', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  })}
                                </Typography>
                              </Box>
                            }
                          />
                          <Divider />
                          <CardContent sx={{ minHeight: '85px' }}>
                            <Typography variant="body2">
                              {user.role === RolesEnum.user
                                ? i18nStore.dictionary.evaluator +
                                  ': ' +
                                  user.evaluator.firstName +
                                  ' ' +
                                  user.evaluator.lastName
                                : ''}
                            </Typography>
                            {user && user.position && (
                              <Typography variant="body2">
                                {i18nStore.dictionary.position + ': ' + user.position.position}
                              </Typography>
                            )}
                            <Typography variant="body2">
                              {user.lastAccess
                                ? i18nStore.dictionary.lastAccess +
                                  ': ' +
                                  new Date(user.lastAccess).toLocaleDateString('pt-BR', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                  })
                                : i18nStore.dictionary.lastAccess + ': ' + i18nStore.dictionary.statusWaiting}
                            </Typography>
                          </CardContent>
                          <CardActions
                            disableSpacing
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                          >
                            <Chip
                              label={generalStore.getRoleTranslated(user.role as RolesEnum)}
                              color="warning"
                              size="small"
                            />
                            <Chip
                              label={generalStore.getStatusTranslated(user.status)}
                              color={generalStore.getBackgroundByStatus(user.status)}
                              size="small"
                            />
                          </CardActions>
                        </StyledCard>
                      </Grid>
                    )
                  })}
                </>
              )}

              {gridView === 'table' && (
                <TableContainer>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{i18nStore.dictionary.nameLabel}</TableCell>
                        <TableCell>{i18nStore.dictionary.position}</TableCell>
                        <TableCell>{i18nStore.dictionary.evaluator}</TableCell>
                        <TableCell>{i18nStore.dictionary.createdAt}</TableCell>
                        <TableCell>{i18nStore.dictionary.lastAccess}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userStore.users.paginatedData.map((user: UserData, index: number) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="body2">{user.firstName + ' ' + user.lastName}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {user && user.position
                                  ? i18nStore.dictionary.position + ': ' + user.position.position
                                  : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {user.role === RolesEnum.user
                                  ? i18nStore.dictionary.evaluator +
                                    ': ' +
                                    user.evaluator.firstName +
                                    ' ' +
                                    user.evaluator.lastName
                                  : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {new Date(user.createdAt).toLocaleDateString('pt-BR', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                })}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {user.lastAccess
                                  ? i18nStore.dictionary.lastAccess +
                                    ': ' +
                                    new Date(user.lastAccess).toLocaleDateString('pt-BR', {
                                      hour: 'numeric',
                                      minute: 'numeric',
                                    })
                                  : i18nStore.dictionary.lastAccess + ': ' + i18nStore.dictionary.statusWaiting}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <UserMenu user={user} />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {userStore.users.totalPages === 0 && (
                <Grid item xs={12}>
                  <NotFoundData
                    children={
                      tenantStore.tenant &&
                      authStore.tokenData &&
                      (tenantStore.tenant.settings.allowEvaluatorsInviteAndDeleteUsers ||
                        authStore.tokenData.role === RolesEnum.administrator ||
                        authStore.tokenData.role === RolesEnum.master) ? (
                        search === '' ? (
                          <Button
                            sx={{ mt: 2 }}
                            variant="text"
                            onClick={() => {
                              const modalOpts: ModalOpts = {
                                size: 'md',
                              }
                              modalStore.openModal(<InviteUserForm />, modalOpts)
                            }}
                          >
                            {i18nStore.dictionary.startAddingUser}
                          </Button>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )
                    }
                  />
                </Grid>
              )}

              {userStore.users.totalPages > 0 && (
                <Grid container sx={{ mt: 6, display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={12} sm={3} display="flex" justifyContent="center" sx={{ marginLeft: 'auto' }}>
                    <ButtonLoader
                      variant="contained"
                      onClick={() => setPage((page = page + 1))}
                      disabled={userStore.users.totalPages === page}
                      loadingText={i18nStore.dictionary.loading}
                      text={i18nStore.dictionary.loadMore}
                      area="auth"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{ display: 'flex', mt: { xs: 2, sm: 0 }, justifyContent: { xs: 'center', sm: 'end' } }}
                  >
                    <Typography component="span" variant="subtitle2">
                      {i18nStore.dictionary.showing}
                    </Typography>
                    <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
                      {userStore.users.paginatedData.length}
                    </Typography>
                    <Typography component="span" variant="subtitle2" sx={{ textTransform: 'lowercase' }}>
                      {i18nStore.dictionary.of}
                    </Typography>
                    <Typography component="span" variant="subtitle2" sx={{ ml: 1 }}>
                      {userStore.users.totalItems}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </ContentArea>
    </Fragment>
  )
}

export default observer(ManageUsersPage)
