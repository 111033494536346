import { Box, Container, Divider, Typography } from '@mui/material'
import { Fragment, useContext } from 'react'
import WebsiteNavBar from '../../components/navbar/website-navbar'
import { StoreContext } from '../../stores/store.context'
import { observer } from 'mobx-react-lite'

const Timeline: React.FC = () => {
  const { i18nStore } = useContext(StoreContext)

  return (
    <Fragment>
      <WebsiteNavBar />
      <Container maxWidth="md" sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            mt: 8,
            minWidth: '435px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start' }}>
            <Typography variant="h2" color="primary">
              30/06/2023
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
              <Typography variant="h2" color="text.primary" fontWeight={400} sx={{ maxWidth: '435px' }}>
                {i18nStore.dictionary.oficialReleaseMessage01}{' '}
                <Box component="span" fontWeight={700} color="primary.main">
                  {i18nStore.dictionary.oficialReleaseMessage02}{' '}
                </Box>
                {i18nStore.dictionary.oficialReleaseMessage03}
              </Typography>
            </Box>
          </Box>

          <Divider
            sx={{
              backgroundColor: 'grey.400',
              width: '1px',
              minHeight: '45vh',
            }}
          />

          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 16, minWidth: '435px' }}>
            <Typography variant="h2" color="primary">
              31/05/2023
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
              <Typography variant="h2" color="text.primary" fontWeight={400} sx={{ maxWidth: '435px' }}>
                {i18nStore.dictionary.closedBetaEndMessage01}{' '}
                <Box component="span" fontWeight={700} color="primary.main">
                  {i18nStore.dictionary.closedBetaEndMessage02}{' '}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', minWidth: '435px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start' }}>
            <Typography variant="h2" color="primary">
              21/04/2023
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
              <Typography variant="h2" color="text.primary" fontWeight={400} sx={{ maxWidth: '435px' }}>
                {i18nStore.dictionary.closedBetaMessage01}{' '}
                <Box component="span" fontWeight={700} color="primary.main">
                  {i18nStore.dictionary.closedBetaMessage02}{' '}
                </Box>
                {i18nStore.dictionary.closedBetaMessage03}
              </Typography>
            </Box>
          </Box>

          <Divider
            sx={{
              backgroundColor: 'grey.400',
              width: '1px',
              minHeight: '45vh',
            }}
          />

          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 16, minWidth: '435px' }}>
            <Typography variant="h2" color="primary">
              15/03/2023
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
              <Typography variant="h2" color="text.primary" fontWeight={400} sx={{ maxWidth: '435px' }}>
                {i18nStore.dictionary.alphaMessage01}{' '}
                <Box component="span" fontWeight={700} color="primary.main">
                  {i18nStore.dictionary.alphaMessage02}{' '}
                </Box>
                {i18nStore.dictionary.alphaMessage03}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', minWidth: '435px' }}>
            <Typography variant="h2" color="primary">
              09/12/2022
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
              <Typography variant="h2" color="text.primary" fontWeight={400} sx={{ maxWidth: '435px' }}>
                <Box component="span" fontWeight={700} color="primary.main">
                  {i18nStore.dictionary.developmentProcessMenssage01}{' '}
                </Box>
                {i18nStore.dictionary.developmentProcessMenssage02}{' '}
                <Box component="span" fontWeight={700} color="primary.main">
                  {i18nStore.dictionary.developmentProcessMenssage03}{' '}
                </Box>
              </Typography>
            </Box>
          </Box>

          <Divider
            sx={{
              backgroundColor: 'grey.400',
              width: '1px',
              minHeight: '45vh',
            }}
          />

          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 16, minWidth: '435px' }}>
            <Typography variant="h2" color="primary">
              02/12/2022
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
              <Typography variant="h2" color="text.primary" fontWeight={400} sx={{ maxWidth: '435px' }}>
                {i18nStore.dictionary.roadMapMessage01}{' '}
                <Box component="span" fontWeight={700} color="primary.main">
                  {i18nStore.dictionary.roadMapMessage02}{' '}
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </Fragment>
  )
}

export default observer(Timeline)
