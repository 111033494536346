/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Stack, TextField, FormHelperText, MenuItem } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import ButtonLoader from '../../../components/button-loader'
import * as Yup from 'yup'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { StoreContext } from '../../../stores/store.context'
import { Roles } from '../../../models/roles.model'
import { CreateUserRequest, UpdateUserRequest, UserData } from '../../../models/user.model'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import { RolesEnum } from '../../../enums/roles.enum'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { debounce } from '@mui/material/utils'
import TransferUserDataModal from '../modals/transfer-user-data.modal'
import DynamicSelect from '../../../components/dynamic-select'
import { Option } from '../../../models/option'
import DeletePositionModal from '../modals/delete-position.modal'

interface InviteUserFormProps {
  user?: UserData
}

const InviteUserForm: React.FC<InviteUserFormProps> = (props: InviteUserFormProps) => {
  const { tenantStore, i18nStore, userStore, toastStore, modalStore, authStore } = useContext(StoreContext)
  const [openEvaluators, setOpenEvaluators] = useState(false)
  const [search] = useState('')
  const { promiseInProgress } = usePromiseTracker({ area: 'loading-evaluators' })
  const [position, selectedPosition] = useState<string | null>('')
  const [positionAdded, setPositionAdded] = useState<boolean | null>(null)

  const [options, setOptions] = useState<Option[]>([{ label: i18nStore.dictionary.addNewPosition, value: 'add' }])

  const handleSelectChange = (newValue: Option, operation: string) => {
    if (operation === 'add') {
      const exists = tenantStore.positions!.some((item) => item._id === newValue.value)
      if (!exists) selectedPosition(newValue ? newValue.value : '')
    } else {
      if (newValue) selectedPosition(newValue.value)
    }
  }

  const handleAddPosition = async (newOption: string) => {
    const newOptionObj = { label: newOption, value: newOption }

    const addedPosition = await tenantStore.addTenantPosition(tenantStore.tenant!._id, newOptionObj.label)

    if (addedPosition) {
      toastStore.pushToast({ message: i18nStore.dictionary.addedPosition, type: 'success' })
      const newPosition = { label: addedPosition.position, value: addedPosition._id }
      setOptions([...options, newPosition])
      selectedPosition(newPosition.value)
      setPositionAdded(true)
    }
  }

  const handleRemovePosition = (option: Option) => {
    modalStore.openModal(
      <DeletePositionModal
        id={option.value!}
        position={option.label}
        onRemoved={(position) => {
          const removePosition = { label: position.position, value: position._id }

          const exists = options.some((option) => option.value === removePosition.value)

          if (exists) {
            const newOptions = options.filter((option) => option.value !== removePosition.value)
            setOptions(newOptions)
            selectedPosition('')
            setPositionAdded(true)
          }
        }}
      />
    )
  }

  const fetchEvaluatorsOnType = useMemo(
    () =>
      debounce((request: { input: string }) => {
        if (authStore.tokenData) {
          var filter = JSON.stringify({
            name: request.input,
            status: 'active|new',
          })

          getEvaluators(filter)
        }
      }, 500),
    []
  )

  const getEvaluators = async (filter: any) => {
    await trackPromise(
      userStore.listEvaluators(
        authStore.tokenData!.tenant,
        parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
        parseInt(process.env.REACT_APP_DATA_LIMIT as string),
        'createdAt',
        'desc',
        filter
      ),
      'loading-evaluators'
    )

    if (userStore.evaluators) {
      userStore.evaluators.paginatedData = userStore.evaluators.paginatedData.filter(
        (user) => user._id.toString() !== props.user?._id.toString()
      )
    }
  }

  const getPositions = async () => {
    await trackPromise(tenantStore.getTenantPositions(authStore.tokenData!.tenant), 'loading-positions')
    tenantStore.positions?.forEach((position) => {
      setOptions((prevValue) => {
        var newValue = { label: position.position, value: position._id }
        prevValue.push(newValue)
        return prevValue
      })
    })
  }

  useEffect(() => {
    const getTenant = async () => {
      if (authStore.tokenData) {
        await tenantStore.getTenantById(authStore.tokenData.tenant)
        if (!tenantStore.tenant) {
          modalStore.handleClose()
        }
      }
    }

    getTenant()
    getPositions()
    getEvaluators({})
  }, [authStore.tokenData, modalStore, search, tenantStore, userStore])

  return (
    <Formik
      initialValues={{
        firstName: props.user?.firstName ?? '',
        lastName: props.user?.lastName ?? '',
        email: props.user?.email ?? '',
        role: (props.user?.role as Roles) ?? ('' as Roles),
        position: props.user && props.user.position ? props.user.position._id : '',
        evaluator: props.user && props.user.evaluator ? (props.user.evaluator._id as string) : '',
      }}
      validateOnMount={true}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required(i18nStore.dictionary.requiredField),
        lastName: Yup.string().required(i18nStore.dictionary.requiredField),
        email: Yup.string()
          .email(i18nStore.dictionary.invalidEmail)
          .required(i18nStore.dictionary.requiredField)
          .test('test-domain', i18nStore.dictionary.invalidDomain, (value) => {
            if (tenantStore.tenant && value) {
              value = value.split('@')[1]
              if (tenantStore.tenant.domains.indexOf(value) === -1) {
                return false
              }
            }

            return true
          }),
        role: Yup.string().required(i18nStore.dictionary.requiredField),
        evaluator: Yup.string().when('role', {
          is: 'user',
          then:
            authStore.tokenData?.role !== RolesEnum.evaluator
              ? Yup.string().required(i18nStore.dictionary.requiredField)
              : Yup.string(),
        }),
      })}
      onSubmit={async (values) => {
        if (props.user) {
          let updateData: UpdateUserRequest = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            role: values.role,
            position: position,
            evaluator: values.evaluator || '',
          }

          /// Changing user role from evaluator to user
          if (props.user.role === RolesEnum.evaluator && props.user.role !== values.role) {
            /// Cannot change user role if already has users linked to him
            const users = await userStore.checkEvaluatorLinkedUsers(props.user._id)

            if (users && users.length > 0) {
              modalStore.handleClose()
              modalStore.openModal(
                <TransferUserDataModal
                  message={i18nStore.dictionary.editEvaluatorWithLinkedUsers}
                  id={props.user._id}
                  action=""
                  callback={async () => {
                    const userUpdated = await trackPromise(
                      userStore.updateUser(props.user!._id, updateData),
                      'invite-user'
                    )

                    if (userUpdated) {
                      toastStore.pushToast({
                        message: i18nStore.dictionary.dataUpdated,
                        type: 'success',
                      })

                      await userStore.listUsersByTenant(
                        authStore.tokenData!.tenant,
                        parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
                        parseInt(process.env.REACT_APP_DATA_LIMIT as string)
                      )
                    }
                  }}
                />,
                { size: 'sm' }
              )
              return
            }
          }

          if (values.role === RolesEnum.evaluator) {
            updateData.evaluator = null
          }

          const userUpdated = await trackPromise(userStore.updateUser(props.user!._id, updateData), 'invite-user')

          if (userUpdated) {
            toastStore.pushToast({
              message: i18nStore.dictionary.dataUpdated,
              type: 'success',
            })
          }
        } else {
          const userData: CreateUserRequest = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            role: values.role,
            position: position !== 'add' ? position : null,
            evaluator: values.evaluator || '',
          }

          if (authStore.tokenData!.role === RolesEnum.evaluator) {
            userData.evaluator = authStore.tokenData!._id
          }

          const userCreated = await trackPromise(userStore.sendInvite(userData), 'invite-user')

          if (userCreated) {
            toastStore.pushToast({
              message: i18nStore.dictionary.inviteSended,
              type: 'success',
            })
          }
        }

        if (authStore.tokenData) {
          await trackPromise(
            userStore.listUsersByTenant(
              authStore.tokenData.tenant,
              1,
              parseInt(process.env.REACT_APP_DATA_LIMIT as string),
              'createdAt',
              'desc'
            ),
            'page-loader'
          )
        }

        modalStore.handleClose()
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isValid,
        touched,
        values,
        setFieldTouched,
        setFieldError,
        setErrors,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h4">{i18nStore.dictionary.fillNewEmployeeDataTitle}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <TextField
                  fullWidth
                  error={Boolean(touched.firstName && errors.firstName)}
                  type="firstName"
                  value={values.firstName}
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={i18nStore.dictionary.namePlaceholder}
                  variant="outlined"
                  label={i18nStore.dictionary.nameLabel}
                  inputProps={{
                    autoComplete: 'user-firstName',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
                {touched.firstName && errors.firstName && <FormHelperText error>{errors.firstName}</FormHelperText>}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <TextField
                  fullWidth
                  error={Boolean(touched.lastName && errors.lastName)}
                  type="lastName"
                  value={values.lastName}
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={i18nStore.dictionary.lastNamePlaceholder}
                  variant="outlined"
                  label={i18nStore.dictionary.lastNameLabel}
                  inputProps={{
                    autoComplete: 'user-lastName',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
                {touched.lastName && errors.lastName && <FormHelperText error>{errors.lastName}</FormHelperText>}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <TextField
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={i18nStore.dictionary.emailPlaceholder}
                  variant="outlined"
                  label={i18nStore.dictionary.emailLabel}
                  inputProps={{
                    autoComplete: 'user-email',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
                {touched.email && errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                error={Boolean(touched.role && errors.role)}
                type="role"
                value={values.role}
                name="role"
                onBlur={handleBlur}
                onChange={async (e) => {
                  handleChange(e)
                  if (e.target.value === 'user') {
                    setFieldTouched('evaluator', true, false)
                    setFieldValue('evaluator', '')
                    setFieldError('evaluator', i18nStore.dictionary.requiredField)
                    await getEvaluators({})
                    let evaluatorAutocomplete = document.getElementById('evaluators-autocomplete')
                    if (evaluatorAutocomplete) {
                      evaluatorAutocomplete.focus()
                    }
                  }
                }}
                placeholder={i18nStore.dictionary.role}
                variant="outlined"
                label={i18nStore.dictionary.role}
                inputProps={{
                  autoComplete: 'user-role',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              >
                {(authStore.tokenData!.role === RolesEnum.evaluator ||
                  authStore.tokenData!.role === RolesEnum.administrator) && (
                  <MenuItem value={'user'}>{i18nStore.dictionary.user}</MenuItem>
                )}
                {(authStore.tokenData!.role === RolesEnum.master ||
                  authStore.tokenData!.role === RolesEnum.administrator) && (
                  <MenuItem value={'evaluator'}>{i18nStore.dictionary.evaluator}</MenuItem>
                )}
                {authStore.tokenData!.role === RolesEnum.master && (
                  <MenuItem value={'administrator'}>{i18nStore.dictionary.administrator}</MenuItem>
                )}
              </TextField>
              {touched.role && errors.role && <FormHelperText error>{errors.role}</FormHelperText>}
            </Grid>

            <Grid item xs={12} sm={12}>
              <DynamicSelect
                options={options}
                positionAdded={positionAdded}
                onChange={(e, newValue, operation) => {
                  handleChange(e)
                  handleSelectChange(newValue, operation)
                }}
                onAdd={handleAddPosition}
                onRemove={handleRemovePosition}
                label={i18nStore.dictionary.position}
                buttonLabel={i18nStore.dictionary.addPositionLabel}
                initialValue={
                  props.user && props.user.position
                    ? { label: props.user.position.position, value: props.user.position._id! }
                    : null
                }
              />
            </Grid>

            {authStore.tokenData!.role === RolesEnum.administrator &&
              values.role === 'user' &&
              userStore.evaluators && (
                <Grid item xs={12}>
                  <Autocomplete
                    id="evaluators-autocomplete"
                    open={openEvaluators}
                    onOpen={() => {
                      touched.evaluator = true
                      setOpenEvaluators(true)
                    }}
                    defaultValue={props.user ? props.user.evaluator : null}
                    onChange={(e, value, reason) => {
                      handleChange(e)
                      setFieldValue('evaluator', value !== null ? value._id : '')
                      setOpenEvaluators(false)
                      if (reason === 'clear') {
                        getEvaluators({})
                        return
                      }
                    }}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    includeInputInList
                    onInputChange={(_, newInputValue) => fetchEvaluatorsOnType({ input: newInputValue })}
                    getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                    options={userStore.evaluators.paginatedData}
                    loading={promiseInProgress}
                    renderInput={(params) => (
                      <>
                        <TextField
                          name="evaluator"
                          {...params}
                          label={i18nStore.dictionary.evaluator}
                          error={Boolean(touched.evaluator && errors.evaluator)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <Fragment>
                                {promiseInProgress ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </Fragment>
                            ),
                          }}
                        />
                        {touched.evaluator && errors.evaluator && (
                          <FormHelperText error>{errors.evaluator}</FormHelperText>
                        )}
                      </>
                    )}
                  />
                </Grid>
              )}

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <ButtonLoader
                loadingText={i18nStore.dictionary.loading}
                text={props.user ? i18nStore.dictionary.update : i18nStore.dictionary.sendInvite}
                area="invite-user"
                disabled={!isValid}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default observer(InviteUserForm)
