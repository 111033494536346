import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect } from 'react'
import { StoreContext } from '../../stores/store.context'
import { Box, Typography, Grid } from '@mui/material'
import ContentArea from '../../components/content-area'
import BreadCrumb from '../../components/breadcrumb'

const UserAchievementsPage: React.FC = () => {
  const { i18nStore, breadcrumbStore, achievementStore, userStore, authStore } = useContext(StoreContext)

  useEffect(() => {
    breadcrumbStore.setBreadcrumbItems([
      {
        name: i18nStore.dictionary.achievements,
        link: '/secure/user/achievements',
      },
    ])
    const loadAchievements = async () => await achievementStore.getAchievements()

    loadAchievements()
  }, [achievementStore, authStore.tokenData, breadcrumbStore, i18nStore.dictionary.achievements, userStore])

  return (
    <Fragment>
      <Box>
        <BreadCrumb />
        <Typography variant="h2">{i18nStore.dictionary.achievements}</Typography>
      </Box>

      <ContentArea>
        <Grid container display="flex" spacing={3}>
          {achievementStore.achievements &&
            achievementStore.achievements.map((achievement, index) => {
              if (userStore.user && userStore.user.achievements) {
                // return userStore.user.achievements.map((userAchievement) => {
                return (
                  <Grid item xs={4} key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        opacity: userStore.user.achievements.includes(achievement._id) ? 1 : 0.5,
                      }}
                    >
                      <Box
                        sx={{
                          p: 1,
                          objectFit: 'cover',
                          width: '80px',
                          height: '80px',
                          borderRadius: '50%',
                          borderWidth: '1px',
                          borderStyle: 'solid',
                          borderColor: userStore.user.achievements.includes(achievement._id)
                            ? 'success.main'
                            : 'grey.500',
                        }}
                        component="img"
                        src={process.env.REACT_APP_API + '/uploads/achievements/' + achievement.icon}
                      />
                      <Typography variant="body1" color="text.primary" sx={{ mt: 1 }}>
                        {achievement.title[i18nStore.language]}
                      </Typography>
                      <Typography variant="body2" color="text.primary" sx={{ mt: 1 }}>
                        {achievement.description[i18nStore.language]}
                      </Typography>
                    </Box>
                  </Grid>
                )
                // })
              }
              return <>not completed</>
            })}
        </Grid>
      </ContentArea>
    </Fragment>
  )
}

export default observer(UserAchievementsPage)
