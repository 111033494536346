import { observer } from 'mobx-react-lite'
import Stack from '@mui/material/Stack'
import { StoreContext } from '../stores/store.context'
import { useContext } from 'react'
import { FormControl, Select, MenuItem, Box, SelectChangeEvent } from '@mui/material'
import BrazilFlag from '../assets/images/brazil-flag'
import { Language } from '../models/i18n.model'
import USAFlag from '../assets/images/usa-flag'
import SpainFlag from '../assets/images/spain-flag'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface LanguageSwitcherProps {
  color?: string
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (props: LanguageSwitcherProps) => {
  const { i18nStore } = useContext(StoreContext)

  return (
    <>
      <Stack direction="row" spacing={1}>
        <FormControl fullWidth>
          <Select
            value={i18nStore.language}
            onChange={(e: SelectChangeEvent) => (i18nStore.language = e.target.value as Language)}
            IconComponent={KeyboardArrowDownIcon}
            inputProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundColor: 'background.default',
                  },
                },
              },
            }}
            sx={{
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: props.color ?? 'primary.main',
              },
              '& .MuiSvgIcon-root': {
                color: props.color ?? 'primary.main',
              },
            }}
          >
            <MenuItem value={'en'} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '35px', display: 'flex' }}>
                <USAFlag />
              </Box>
            </MenuItem>
            <MenuItem value={'pt'} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '35px', display: 'flex' }}>
                <BrazilFlag />
              </Box>
            </MenuItem>
            <MenuItem value={'es'} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '35px', display: 'flex' }}>
                <SpainFlag />
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </>
  )
}

export default observer(LanguageSwitcher)
