import { makeAutoObservable } from 'mobx'
import { I18nStore } from './i18n.store'
import { ToastStore } from './toast.store'
import { ReportService } from '../services/report.service'
import { AssessmentUserReport, CompetenceReport } from '../models/report.model'
import { Pagination } from '../models/pagination.model'
import { AuthStore } from './auth.store'

export class ReportStore {
  private _reportAssessmentUser: AssessmentUserReport | null = null
  private _reportCompetence: Pagination<Array<CompetenceReport>> | null = null
  private _reportCompetenceExport: Pagination<Array<CompetenceReport>> | null = null
  private _reportCompetenceListPage: number = parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string)
  private _downloadReport: boolean = false

  toastStore: ToastStore
  i18nStore: I18nStore
  authStore: AuthStore

  constructor(i18nStore: I18nStore, toastStore: ToastStore, authStore: AuthStore) {
    makeAutoObservable(this)
    this.toastStore = toastStore
    this.i18nStore = i18nStore
    this.authStore = authStore
  }

  get downloadReport() {
    return this._downloadReport
  }

  set downloadReport(value: boolean) {
    this._downloadReport = value
  }

  async generateReportAssessmentUser(assessmentId: string, userId: string): Promise<void> {
    try {
      const reportService = new ReportService()
      const res: AssessmentUserReport = await reportService.generateReportAssessmentUser(assessmentId, userId)
      this.reportAssessmentUser = res
    } catch (e: any) {
      this.reportAssessmentUser = null
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  get reportCompetenceListPage() {
    return this._reportCompetenceListPage
  }

  set reportCompetenceListPage(page: number) {
    this._reportCompetenceListPage = page
  }

  set reportCompetenceExport(data: Pagination<Array<CompetenceReport>> | null) {
    this._reportCompetenceExport = data
  }

  get reportCompetenceExport() {
    return this._reportCompetenceExport
  }

  set reportAssessmentUser(data: AssessmentUserReport | null) {
    this._reportAssessmentUser = data
  }

  get reportAssessmentUser() {
    return this._reportAssessmentUser
  }

  async generateReportCompetence(
    competenceId: string,
    competenceValueId: string,
    reached: Array<number>,
    priority: Array<number>,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const reportService = new ReportService()
      const res: Pagination<Array<CompetenceReport>> = await reportService.generateReportCompetence(
        this.authStore.tokenData!.tenant,
        competenceId,
        competenceValueId,
        reached,
        priority,
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      )
      if (res && page === 1) {
        this.reportCompetence = res
      } else {
        this.pushReportCompetences(res.paginatedData)
      }
    } catch (e: any) {
      this.reportCompetence = null
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  pushReportCompetences = (assessments: Array<CompetenceReport>) =>
    (this._reportCompetence!.paginatedData = [...this._reportCompetence!.paginatedData, ...assessments])

  set reportCompetence(data: Pagination<Array<CompetenceReport>> | null) {
    this._reportCompetence = data
  }

  get reportCompetence() {
    return this._reportCompetence
  }

  async exportReportCompetence(
    competenceId: string,
    competenceValueId: string,
    reached: Array<number>,
    priority: Array<number>,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const reportService = new ReportService()
      var res: Pagination<Array<CompetenceReport>> = await reportService.exportReportCompetence(
        this.authStore.tokenData!.tenant,
        competenceId,
        competenceValueId,
        reached,
        priority,
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      )

      if (res) {
        // this.downloadReport = true
        this.reportCompetenceExport = res
      }
    } catch (e: any) {
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }
}
