import { Box, Container, Typography } from '@mui/material'
import { useContext } from 'react'
import { RolesEnum } from '../../enums/roles.enum'
import { StoreContext } from '../../stores/store.context'
import DashboardEvaluator from './evaluator/'
import DashboardUser from './user/'
import DashboardAdmin from './admin/'

const DashboardPage: React.FC = () => {
  const { authStore } = useContext(StoreContext)

  return (
    <Box sx={{ mt: -5 }}>
      {authStore.tokenData && authStore.tokenData.role === RolesEnum.evaluator && <DashboardEvaluator />}
      {authStore.tokenData && authStore.tokenData.role === RolesEnum.user && <DashboardUser />}
      {authStore.tokenData &&
        (authStore.tokenData.role === RolesEnum.administrator || authStore.tokenData.role === RolesEnum.master) && (
          <DashboardAdmin />
        )}
    </Box>
  )
}

export default DashboardPage
