import { Grid, Typography, Button, Divider, IconButton, Box, Avatar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'
import { StoreContext } from '../../../stores/store.context'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { ModalOpts } from '../../../models/modal.model'
import DeleteIcon from '@mui/icons-material/Delete'
import { UserData } from '../../../models/user.model'
import UsersListModal from '../../manage-users/modals/users-list.modal'

interface AssessmentFormStep3Props {
  step: number
}

const AssessmentFormStep3: React.FC<AssessmentFormStep3Props> = (props: AssessmentFormStep3Props) => {
  const { i18nStore, modalStore, assessmentStore, userStore } = useContext(StoreContext)

  useEffect(() => {}, [assessmentStore.assessmentFormData])

  const onSelectUsers = (users: Array<UserData>) => {
    let assessmentUsers: Array<{ user: UserData; progress: string; step: string }> = []
    users.forEach((user: UserData) => {
      assessmentUsers.push({ user: user, progress: 'not_started', step: 'self_evaluation' })
    })

    assessmentStore.assessmentFormData = {
      ...assessmentStore.assessmentFormData,
      users: assessmentUsers,
    }
    assessmentStore.validateStep(props.step)
  }

  const handleRemoveUser = (user: UserData, index: number) => {
    assessmentStore.assessmentFormData = {
      ...assessmentStore.assessmentFormData,
      users: assessmentStore.assessmentFormData.users!.filter((obj) => user._id !== obj.user._id),
    }
    assessmentStore.validateStep(props.step)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="subtitle2">{i18nStore.dictionary.informUsers}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button
            color="primary"
            variant="text"
            onClick={() => {
              const modalOpts: ModalOpts = {
                size: 'sm',
              }
              modalStore.openModal(<UsersListModal emitSelected={onSelectUsers} />, modalOpts)
            }}
          >
            <AddCircleIcon />
            <Typography variant="body2" sx={{ ml: 2 }}>
              {i18nStore.dictionary.addUser}
            </Typography>
          </Button>

          <Typography variant="body2">
            {i18nStore.dictionary.totalUsers}: {assessmentStore.assessmentFormData.users!.length}
          </Typography>
        </Box>

        <Divider sx={{ mt: 2 }} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={3} sx={{ width: '100%', display: 'flex' }}>
          {assessmentStore.assessmentFormData.users!.map((assessment, index) => {
            return (
              <Grid item key={index} xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center' }}>
                {assessment.user && !assessment.user.image ? (
                  <Avatar
                    sx={{
                      backgroundColor: 'secondary.main',
                      width: '40px',
                      height: '40px',
                    }}
                  >
                    {userStore.getUserInitials(assessment.user.firstName, assessment.user.lastName)}
                  </Avatar>
                ) : (
                  assessment.user && (
                    <Box
                      sx={{
                        objectFit: 'cover',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: 'grey.300',
                      }}
                      component="img"
                      src={process.env.REACT_APP_API + '/uploads/user/' + assessment.user.image}
                    />
                  )
                )}

                <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2 }}>
                  <Typography variant="subtitle2">
                    {assessment.user.firstName + ' ' + assessment.user.lastName}
                  </Typography>
                  {assessment.user && assessment.user.position && (
                    <Typography variant="body2" color="primary">
                      {assessment.user.position.position}
                    </Typography>
                  )}
                </Box>

                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveUser(assessment.user, index)}>
                  <DeleteIcon sx={{ color: 'error.main' }} />
                </IconButton>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(AssessmentFormStep3)
