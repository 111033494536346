import { Box, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UserData } from '../../models/user.model'
import { StoreContext } from '../../stores/store.context'
import ResetPasswordForm from './forms/reset-password'

const UserActivation: React.FC = () => {
  const [userData, setUserData] = useState<UserData | null>(null)
  const { userStore, i18nStore } = useContext(StoreContext)
  let { activationHash } = useParams()

  useEffect(() => {
    const checkActivationToken = async () => {
      if (activationHash) {
        const userData = await userStore.checkActivationToken(activationHash)

        if (userData && userData.email) {
          setUserData(userData)
        }
      }
    }

    checkActivationToken()
  }, [userStore, activationHash])

  return (
    <Fragment>
      <Grid container>
        {userData && (
          <Fragment>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h1" textAlign="center">
                  {i18nStore.dictionary.welcome + ', '}
                </Typography>
                <Typography variant="h1" textAlign="center" color="primary.main">
                  &nbsp;{userData.firstName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Typography variant="subtitle1" textAlign="center">
                {i18nStore.dictionary.activateAccountMessage}
              </Typography>
            </Grid>
            <ResetPasswordForm userData={userData} action="activate" />
          </Fragment>
        )}
      </Grid>
    </Fragment>
  )
}

export default observer(UserActivation)
