import { Grid, Typography, Step, Stepper, Box, Button, StepLabel } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../stores/store.context'
import React from 'react'
import SaveIcon from '@mui/icons-material/Save'
import ButtonLoader from '../../../components/button-loader'
import { trackPromise } from 'react-promise-tracker'
import { AssessmentForm } from '../../../models/assessment.model'
import AssessmentFormStep1 from './assessment-form-step1'
import AssessmentFormStep2 from './assessment-form-step2'
import AssessmentFormStep3 from './assessment-form-step3'
import AssessmentFormStep4 from './assessment-form-step4'
import { RolesEnum } from '../../../enums/roles.enum'

interface AddAssessmentFormProps {
  _id?: string
}

const AddCompetenceForm: React.FC<AddAssessmentFormProps> = (props: AddAssessmentFormProps) => {
  const { i18nStore, assessmentStore, toastStore, modalStore, authStore, tenantStore } = useContext(StoreContext)
  const [edit, setEdit] = useState(false)
  let [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    assessmentStore.assessmentFormSteps = [
      { title: i18nStore.dictionary.addAssessmentStep1Title, completed: false },
      { title: i18nStore.dictionary.addAssessmentStep2Title, completed: false },
      { title: i18nStore.dictionary.addAssessmentStep3Title, completed: false },
      { title: i18nStore.dictionary.addAssessmentStep4Title, completed: false },
    ]

    assessmentStore.assessmentFormData = new AssessmentForm()

    if (props._id) {
      const getById = async (id: string) => {
        await assessmentStore.getAssessmentData(id)
        if (assessmentStore.assessment) assessmentStore.assessmentFormData = assessmentStore.assessment
        assessmentStore.assessmentFormSteps.forEach((_, index) => assessmentStore.validateStep(index))
      }

      getById(props._id)
    }
  }, [assessmentStore, props._id, i18nStore])

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const handleStep = (step: number) => () => {
    assessmentStore.validateStep(step - 1)
    setActiveStep(step)
  }

  const handleNext = () => {
    setActiveStep((activeStep = activeStep + 1))
    assessmentStore.validateStep(activeStep - 1)
  }

  const handleSaveDraft = async () => {
    var isDraft = assessmentStore.assessmentFormData.status !== 'active'

    const formData = JSON.parse(JSON.stringify(assessmentStore.assessmentFormData))
    const savedDraft = await trackPromise(assessmentStore.save(formData, isDraft), 'save-draft')
    if (savedDraft && !edit) {
      setEdit(true)
      assessmentStore.assessmentFormData!._id = savedDraft._id
    }

    if (savedDraft) {
      toastStore.pushToast({
        message: isDraft ? i18nStore.dictionary.draftSaved : i18nStore.dictionary.dataUpdated,
        type: 'success',
      })
    }
  }

  const handlePublish = async () => {
    const formData = JSON.parse(JSON.stringify(assessmentStore.assessmentFormData))
    const published = await trackPromise(assessmentStore.save(formData, false), 'publishing')

    if (published) {
      toastStore.pushToast({ message: i18nStore.dictionary.assessmentPublished, type: 'success' })
      modalStore.handleClose()

      // TODO: Fix list reload (not updating page from parent)
      await assessmentStore.listAssessmentsByTenant(
        authStore.tokenData!.tenant,
        parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
        parseInt(process.env.REACT_APP_DATA_LIMIT as string),
        'createdAt',
        'desc',
        ''
      )
    }
  }

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{i18nStore.dictionary.fillNewAssessmentData}</Typography>
        </Grid>
      </Grid>

      {assessmentStore.assessmentFormSteps.length > 0 && (
        <Fragment>
          <Stepper nonLinear activeStep={activeStep} sx={{ my: 4 }}>
            {assessmentStore.assessmentFormSteps.map((step, index) => (
              <Step key={step.title} completed={step.completed}>
                <StepLabel
                  error={index !== 3 && !step.completed}
                  sx={{
                    cursor: 'pointer',
                    '.MuiStepLabel-label': { color: 'text.primary' },
                  }}
                  onClick={handleStep(index)}
                >
                  {step.title}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Fragment>
            <Box sx={{ display: activeStep === 0 ? 'block' : 'none' }}>
              <AssessmentFormStep1 step={activeStep} />
            </Box>

            <Box sx={{ display: activeStep === 1 ? 'block' : 'none' }}>
              <AssessmentFormStep2 step={activeStep} />
            </Box>

            <Box sx={{ display: activeStep === 2 ? 'block' : 'none' }}>
              <AssessmentFormStep3 step={activeStep} />
            </Box>

            <Box sx={{ display: activeStep === 3 ? 'block' : 'none' }}>
              <AssessmentFormStep4 step={activeStep} />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mt: 4 }}>
              <ButtonLoader
                onClick={() => handleSaveDraft()}
                variant="text"
                loadingText={i18nStore.dictionary.loading}
                text={
                  <Fragment>
                    <SaveIcon sx={{ mr: 2 }} />
                    <Typography variant="button">
                      {assessmentStore.assessmentFormData.status === 'active'
                        ? i18nStore.dictionary.update
                        : i18nStore.dictionary.saveDraft}
                    </Typography>
                  </Fragment>
                }
                area="save-draft"
                sx={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}
              />

              {activeStep !== 0 && (
                <Button variant="text" onClick={handleBack} sx={{ mr: 2 }}>
                  {i18nStore.dictionary.back}
                </Button>
              )}

              {activeStep !== 3 ? (
                <Button variant="contained" onClick={() => handleNext()}>
                  {i18nStore.dictionary.next}
                </Button>
              ) : (
                assessmentStore.assessmentFormData.status !== 'active' &&
                tenantStore.tenant &&
                authStore.tokenData &&
                (tenantStore.tenant.settings.allowEvaluatorsCreateAndDeleteAssessments ||
                  authStore.tokenData.role === RolesEnum.administrator) && (
                  <ButtonLoader
                    onClick={handlePublish}
                    variant="contained"
                    loadingText={i18nStore.dictionary.loading}
                    disabled={!assessmentStore.assessmentFormValid}
                    text={
                      <Fragment>
                        <SaveIcon sx={{ mr: 2 }} />
                        <Typography variant="button">{i18nStore.dictionary.publish}</Typography>
                      </Fragment>
                    }
                    area="publishing"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  />
                )
              )}
            </Box>
          </Fragment>
        </Fragment>
      )}
    </Fragment>
  )
}

export default observer(AddCompetenceForm)
