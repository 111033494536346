import { Typography, Box, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext } from 'react'
import { trackPromise } from 'react-promise-tracker'
import ButtonLoader from '../../../components/button-loader'
import { StoreContext } from '../../../stores/store.context'
import WarningIcon from '@mui/icons-material/Warning'

interface FinishAssessmentModalProps {
  id: string
}

const FinishAssessmentModal: React.FC<FinishAssessmentModalProps> = (props: FinishAssessmentModalProps) => {
  const { modalStore, i18nStore, assessmentStore, toastStore } = useContext(StoreContext)

  const finishAssessment = async () => {
    try {
      const finished = await trackPromise(
        assessmentStore.updateAssessment(props.id, { status: 'done' }),
        'finish-assessment'
      )
      if (finished) {
        toastStore.pushToast({ message: i18nStore.dictionary.asssessmentFinished, type: 'success' })
      }
      modalStore.handleClose()
    } catch (e: any) {
      toastStore.pushToast({ message: e.toString(), type: 'error' })
      modalStore.handleClose()
    }
  }

  return (
    <Fragment>
      <WarningIcon color="warning" sx={{ alignSelf: 'center', fontSize: '5rem' }} />

      <Typography variant="h4" color={'text.primary'} align="center" sx={{ my: 3 }}>
        {i18nStore.dictionary.areYouSureFinishAssessment}
      </Typography>

      <Typography variant="body2" color={'secondary.lighter'} align="center" sx={{ mb: 3 }}>
        {i18nStore.dictionary.noMoreAvailableToUsers}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          disableElevation
          size="large"
          type="button"
          variant="text"
          color="primary"
          onClick={() => modalStore.handleClose()}
          sx={{ mr: 2 }}
        >
          {i18nStore.dictionary.noBack}
        </Button>

        <ButtonLoader
          loadingText="Processando..."
          text={i18nStore.dictionary.yesContinue}
          area="finish-assessment"
          disabled={!props.id}
          onClick={() => finishAssessment()}
        />
      </Box>
    </Fragment>
  )
}

export default observer(FinishAssessmentModal)
