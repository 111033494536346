/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Typography } from '@mui/material'
import { Fragment, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../stores/store.context'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { observer } from 'mobx-react-lite'

const HowItWorks: React.FC = () => {
  const { i18nStore, themeStore } = useContext(StoreContext)

  const [howItWorksSteps, setHowItWorksSteps] = useState<Array<{ title: string; description: string }>>([])

  useEffect(() => {
    setHowItWorksSteps(() => {
      return [
        {
          title: i18nStore.dictionary.inviteEmployess,
          description: i18nStore.dictionary.howItWorks01,
        },
        {
          title: i18nStore.dictionary.createCompetencies,
          description: i18nStore.dictionary.howItWorks02,
        },
        {
          title: i18nStore.dictionary.createAssessmentCycle,
          description: i18nStore.dictionary.howItWorks03,
        },
        {
          title: i18nStore.dictionary.selfAssessmentStep,
          description: i18nStore.dictionary.howItWorks04,
        },
        {
          title: i18nStore.dictionary.assessmentStep,
          description: i18nStore.dictionary.howItWorks05,
        },
        {
          title: i18nStore.dictionary.feedbackStep,
          description: i18nStore.dictionary.howItWorks06,
        },
        {
          title: i18nStore.dictionary.dataStudyAvailable,
          description: i18nStore.dictionary.howItWorks07,
        },
      ]
    })
  }, [i18nStore.language])

  return (
    <Fragment>
      <Typography variant="h4">{i18nStore.dictionary.howItWorks}</Typography>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {howItWorksSteps &&
          howItWorksSteps.map((step, index) => {
            return (
              <Box
                key={index}
                sx={{
                  mt: 1,
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  p: 2,
                  alignItems: 'center',
                  borderStyle: 'solid',
                  borderWidth: '.5px',
                  borderRadius: '10px',
                  borderColor: themeStore.theme.palette.grey[300],
                  borderRightColor: 'warning.main',
                  borderRightWidth: '5px',
                  textAlign: { xs: 'center', sm: 'left' },
                }}
              >
                <Typography color="warning.main" variant="h2" sx={{ minWidth: { sm: '100px' } }}>
                  0{index + 1} <ArrowRightIcon sx={{ display: 'inline' }} />
                </Typography>

                <Typography color="primary.main" variant="h5" sx={{ minWidth: { sm: '300px' }, py: { xs: 1, sm: 0 } }}>
                  {step.title}
                </Typography>

                <Typography color="text.primary" variant="subtitle2" sx={{ maxWidth: { sm: '500px' } }}>
                  {step.description}
                </Typography>
              </Box>
            )
          })}
      </Box>
    </Fragment>
  )
}

export default observer(HowItWorks)
