import { PaletteOptions } from '@mui/material'
import { deepOrange, blue, grey, green } from '@mui/material/colors'

const Palette = (mode: string): PaletteOptions => {
  return {
    ...(mode === 'light'
      ? {
          primary: {
            main: blue[700],
            contrastText: '#ffffff',
            dark: '#000000',
          },
          secondary: {
            main: blue[400],
          },
          background: {
            default: '#ffffff',
          },
          divider: grey[300],
          warning: {
            main: '#ed6c02',
            light: '#ffaf6e',
          },
          success: {
            main: green[700],
            light: green[500],
          },
          text: {
            primary: '#3c3c3c',
            secondary: '#ffffff',
            disabled: '#3c3c3c',
          },
          info: {
            main: '#73b3f3',
          },
          grey: grey,
          action: {
            disabled: '#3c3c3c',
            disabledBackground: grey[300],
          },
          error: {
            main: '#f44336',
          },
        }
      : {
          primary: {
            main: deepOrange[700],
            contrastText: '#ffffff',
          },
          secondary: {
            main: deepOrange[400],
          },
          background: {
            default: '#212121',
          },
          divider: grey[200],
          success: {
            main: green[700],
          },
          text: {
            primary: '#ffffff',
            secondary: '#3c3c3c',
            disabled: '#3c3c3c',
          },
          info: {
            main: '#73b3f3',
          },
          grey: grey,
          shadow: {
            50: '#ffffff1f',
            100: '#ffffff24',
            200: '#ffffff33',
          },
          action: {
            disabled: '#3c3c3c',
            disabledBackground: grey[400],
          },
          error: {
            main: '#f44336',
          },
        }),
  }
}

export default Palette
