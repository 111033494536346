import { makeAutoObservable } from 'mobx'
import { DefaultColors } from '../models/default-colors.model'
import { I18nStore } from './i18n.store'
import { RolesEnum } from '../enums/roles.enum'

export class GeneralStore {
  constructor(private _i18nStore: I18nStore) {
    makeAutoObservable(this)
  }

  getRoleTranslated(role: RolesEnum): string {
    let translatedRole = ''

    switch (role) {
      case RolesEnum.administrator:
        translatedRole = this._i18nStore.dictionary.administrator
        break
      case RolesEnum.evaluator:
        translatedRole = this._i18nStore.dictionary.evaluator
        break
      case RolesEnum.user:
        translatedRole = this._i18nStore.dictionary.user
        break
    }

    return translatedRole
  }

  getStatusTranslated(status: string | null): string {
    let translatedStatus = ''
    if (status) {
      switch (status) {
        case 'new':
          translatedStatus = this._i18nStore.dictionary.statusNew
          break
        case 'active':
          translatedStatus = this._i18nStore.dictionary.statusActive
          break
        case 'draft':
          translatedStatus = this._i18nStore.dictionary.statusDraft
          break
        case 'not_started':
          translatedStatus = this._i18nStore.dictionary.statusNotStarted
          break
        case 'in_progress':
          translatedStatus = this._i18nStore.dictionary.statusInProgress
          break
        case 'done':
          translatedStatus = this._i18nStore.dictionary.statusDone
          break
        case 'waiting':
          translatedStatus = this._i18nStore.dictionary.statusWaiting
          break
        case 'pending':
          translatedStatus = this._i18nStore.dictionary.statusPending
          break
        case 'invoiced':
          translatedStatus = this._i18nStore.dictionary.statusInvoiced
          break
        case 'feedback':
          translatedStatus = this._i18nStore.dictionary.statusFeedback
          break
        case 'waiting_manager_evaluation':
          translatedStatus = this._i18nStore.dictionary.waitingManager
          break
        case 'manager_evaluation_in_progress':
          translatedStatus = this._i18nStore.dictionary.evaluationinProgress
          break
      }
    }
    return translatedStatus
  }

  getBackgroundByStatus(status: string | null): DefaultColors {
    let backgroundColor: DefaultColors = 'primary'
    if (status) {
      switch (status) {
        case 'new':
        case 'in_progress':
        case 'manager_evaluation_in_progress':
          backgroundColor = 'primary'
          break
        case 'active':
        case 'done':
        case 'invoiced':
          backgroundColor = 'success'
          break
        case 'draft':
        case 'not_started':
        case 'waiting':
        case 'waiting_manager_evaluation':
        case 'pending':
        case 'feedback':
          backgroundColor = 'warning'
          break
      }
    }
    return backgroundColor
  }
}
