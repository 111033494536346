import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext } from 'react'
import { StoreContext } from '../../../stores/store.context'

interface AssessmentFormStep4Props {
  step: number
}

const AssessmentFormStep4: React.FC<AssessmentFormStep4Props> = (props: AssessmentFormStep4Props) => {
  const { assessmentStore, i18nStore, userStore } = useContext(StoreContext)

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="h4">{i18nStore.dictionary.addAssessmentStep4Title}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Typography variant="h5">{i18nStore.dictionary.assessment}:</Typography>
        <Typography variant="subtitle2" sx={{ ml: 2 }}>
          {assessmentStore.assessmentFormData!.name![i18nStore.language]}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {assessmentStore.assessmentFormData && assessmentStore.assessmentFormData.competencies && (
          <Grid container spacing={1} sx={{ mt: 2, overflow: 'auto', maxHeight: '50vh' }}>
            <Grid item xs={12}>
              <Typography variant="h5">{i18nStore.dictionary.competencies}:</Typography>
            </Grid>
            {assessmentStore.assessmentFormData.competencies.map((competence, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Typography variant="subtitle2">{competence.name[i18nStore.language]}</Typography>
                </Grid>
              )
            })}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        {assessmentStore.assessmentFormData && assessmentStore.assessmentFormData.users && (
          <Grid container spacing={1} sx={{ mt: 2, overflow: 'auto', maxHeight: '50vh' }}>
            <Grid item xs={12}>
              <Typography variant="h5">{i18nStore.dictionary.users}:</Typography>
            </Grid>
            {assessmentStore.assessmentFormData.users.map((assessment, index) => {
              return (
                <Grid item key={index} xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center' }}>
                  {assessment.user && !assessment.user.image ? (
                    <Avatar
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '40px',
                        height: '40px',
                      }}
                    >
                      {userStore.getUserInitials(assessment.user.firstName, assessment.user.lastName)}
                    </Avatar>
                  ) : (
                    assessment.user && (
                      <Box
                        sx={{
                          objectFit: 'cover',
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          borderWidth: '1px',
                          borderStyle: 'solid',
                          borderColor: 'grey.300',
                        }}
                        component="img"
                        src={process.env.REACT_APP_API + '/uploads/user/' + assessment.user.image}
                      />
                    )
                  )}

                  <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2 }}>
                    <Typography variant="subtitle2">
                      {assessment.user.firstName + ' ' + assessment.user.lastName}
                    </Typography>
                    {assessment.user && assessment.user.position && (
                      <Typography variant="body2" color="primary">
                        {assessment.user.position.position}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default observer(AssessmentFormStep4)
