import { Box, Container, Grid, Link, Typography } from '@mui/material'
import { useContext } from 'react'
import { StoreContext } from '../stores/store.context'
import { useLocation } from 'react-router-dom'
import pjson from '../../package.json'

const Footer: React.FC = () => {
  const { i18nStore, authStore } = useContext(StoreContext)
  var location = useLocation()

  return (
    <Box sx={{ mt: 10, width: '100%' }}>
      <Box sx={{ backgroundColor: 'primary.main', py: 4 }}>
        <Container maxWidth="md">
          <Grid
            container
            flexDirection={{ xs: 'column', sm: 'row' }}
            justifyContent={{ xs: 'center', sm: 'space-between' }}
            alignItems="center"
          ></Grid>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: 'secondary.main', py: 1 }}>
        <Container maxWidth="md">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item sx={{ display: 'flex' }}>
              <Link
                href={authStore.tokenData ? '../privacy-policy.pdf' : './privacy-policy.pdf'}
                target="_blank"
                sx={{ textUnderlineOffset: 4, color: 'primary.contrastText' }}
              >
                <Typography variant="body2">{i18nStore.dictionary.privacyPolicy}</Typography>
              </Link>
            </Grid>

            {location.pathname.split('/')[1] === 'secure' && (
              <Grid item>
                <Typography variant="body2" sx={{ color: 'primary.contrastText' }}>
                  {i18nStore.dictionary.version}: {pjson.version}
                </Typography>
              </Grid>
            )}

            <Grid item sx={{ display: 'flex' }}>
              <Link
                href="https://i2web.com.br"
                target="_blank"
                sx={{ textUnderlineOffset: 4, color: 'primary.contrastText' }}
              >
                <Typography variant="body2">{i18nStore.dictionary.poweredBy} I2 Web</Typography>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

export default Footer
