const DictionaryES = {
  manageUsers: 'Administrar usuarios',
  manageUsersSubtitle: 'Administre su usuarios',
  showing: 'Demostración',
  of: 'De',
  loadMore: 'Carga más',
  loading: 'Cargando',
  searchByUserName: 'Buscar por nombre de empleado o posición',
  inviteEmployee: 'Invitar colaborador',
  requiredField: 'Campo requerido',
  invalidEmail: 'Correo electrónico inválido',
  fillNewEmployeeDataTitle: 'Rellene los datos del nuevo empleado',
  nameLabel: 'Nombre',
  namePlaceholder: 'Ingrese el nombre',
  lastNameLabel: 'Apellido',
  lastNamePlaceholder: 'Ingrese el apellido',
  emailPlaceholder: 'Ingrese el correo electrónico corporativo del empleado',
  emailLabel: 'Correo electrónico',
  sendInvite: 'Enviar invitación',
  role: 'Role',
  inviteSended: '¡Invitación enviada con éxito!',
  invalidDomain: 'Dominio inválido',
  user: 'Usuario',
  evaluator: 'Evaluador',
  delete: 'Borrar',
  areYouSureDeleteUser: '¿Tem certeza de que desea eliminar esse usuário?',
  cannotBeUndoneAction: 'Esta acción no se puede deshacer.',
  noBack: 'No, vuelve atrás',
  userDeleted: 'Usuario eliminado con éxito',
  statusNew: 'Nuevo',
  statusActive: 'Activo',
  statusDraft: 'Bosquejo',
  statusPending: 'Pendiente',
  statusInvoiced: 'Facturado',
  resendActivationLink: 'Enviar enlace de activación',
  activationLinkSended: 'Enlace de activación enviado con éxito',
  welcome: 'Bienvenido',
  activateAccountMessage: 'Para activar su cuenta debe configurar su contraseña',
  passwordMustContainUppercase: 'Debe contener al menos una letra mayúscula',
  passwordMustContainNumber: 'Debe contener al menos un número',
  passwordMustContainSpecialChar: 'Debe contener al menos un carácter especial',
  passwordMustContainSixChars: 'Debe contener al menos 6 caracteres',
  passwordPlaceholder: 'Ingrese su contraseña',
  passwordLabel: 'Contraseña',
  confirmPasswordPlaceholder: 'Vuelva a escribir su contraseña',
  confirmPasswordLabel: 'Confirmación de contraseña',
  passwordDontMatch: 'Contraseña no coincide',
  invalidToken: 'Token no válido',
  activate: 'Activar',
  userActivated: 'Usuario activado con éxito',
  redirecting: 'Redireccionando',
  notFoundData: 'No se encontró ningún registro',
  leaving: 'Partida',
  manageCompetencies: 'Gestionar competencias',
  manageCompetenciesSubtitle: 'Gestionar sus competencias',
  searchByCompetenceName: 'Buscar por nombre de competencia',
  addCompetence: 'Agregar competencia',
  startAddingUser: 'Comece agregando un nuevo usuario',
  startAddingCompetence: 'Empezar a agregar una nueva competencia',
  save: 'Ahorrar',
  fillNewCompetenceData: 'Rellene los datos de la nueva competencia',
  competenceNamePTLabel: 'Nombre de la competencia (PT)',
  competenceNamePTPlaceholder: 'Ingrese el nombre que se mostrará en portugués',
  competenceNameENLabel: 'Nombre de la competencia (EN)',
  competenceNameENPlaceholder: 'Ingrese el nombre que se mostrará en inglés',
  competenceNameESLabel: 'Nombre de la competencia (ES)',
  competenceNameESPlaceholder: 'Ingrese el nombre a mostrar en español',
  competenceDescriptionPTLabel: 'Breve descripción de la competencia (PT)',
  competenceDescriptionPTPlaceholder: 'Escriba un resumen de competencias para ser presentado en portugués',
  competenceDescriptionENLabel: 'Breve descripción de la competencia (EN)',
  competenceDescriptionENPlaceholder: 'Escriba un resumen de competencias que se presentará en inglés',
  competenceDescriptionESLabel: 'Descripción breve de la competencia (ES)',
  competenceDescriptionESPlaceholder: 'Escriba un resumen de competencias para presentar en español',
  next: 'Próximo',
  back: 'Atras',
  saveDraft: 'Guardar borrador',
  addCompetenceStep1Title: 'Informaciones básicas',
  addCompetenceStep2Title: 'Valores',
  addCompetenceStep3Title: 'Resumen',
  edit: 'Editar',
  publish: 'Publicar',
  draftSaved: 'borrador guardado',
  pleaseFillCurrentCompetenceValues: 'Ingrese los datos de competencia actuales antes de agregar uno nuevo',
  competence: 'Competencia',
  values: 'Valores',
  competencePublished: 'Competencia publicada con éxito',
  manageAssessments: 'Gestionar evaluaciones',
  manageAssessmentsSubtitle: 'Cree evaluaciones para su equipo',
  createAssessment: 'Crear nueva evaluación',
  searchByAssessmentName: 'Buscar por nombre de evaluación',
  startAddingAssessment: 'Comience creando una nueva evaluación',
  addAssessmentStep1Title: 'Información básica',
  addAssessmentStep2Title: 'Habilidades',
  addAssessmentStep3Title: 'Colaboradores',
  addAssessmentStep4Title: 'Resumen',
  fillNewAssessmentData: 'Rellene los nuevos datos de evaluación',
  assessmentNamePTLabel: 'Nombre de la evaluación (PT)',
  assessmentNamePTPlaceholder: 'Nombre que será presentado en portugués',
  assessmentNameENLabel: 'Nombre de la evaluación (EN)',
  assessmentNameENPlaceholder: 'Nombre que se mostrará en inglés',
  assessmentNameESLabel: 'Nombre de la evaluación (ES)',
  assessmentNameESPlaceholder: 'Nombre que se presentará en española',
  endDate: 'Fecha de finalización',
  informCompetences: 'Agregue las habilidades que compondrán la evaluación',
  confirm: 'Confirmar',
  selectCompetences: 'Seleccionar competencias',
  selectAll: 'Seleccionar todo',
  informUsers: 'Agregar usuarios que podrán responder a la encuesta',
  addUser: 'Añadir Usuario',
  totalUsers: 'Usuarios totales',
  totalCompetencies: 'Competencias totales',
  selectUsers: 'Seleccionar usuarios',
  assessment: 'Evaluación',
  competencies: 'Competencias',
  users: 'Usuarios',
  assessmentPublished: 'Evaluación publicada con éxito',
  myAssessments: 'Mis evaluaciones',
  myAssessmentsSubtitle: 'Seleccione una de las evaluaciones disponibles',
  start: 'Iniciar',
  limiteDate: 'Fecha límite para enviar',
  statusNotStarted: 'No iniciado',
  statusInProgress: 'En progreso',
  statusDone: 'Terminado',
  continue: 'Continuar',
  noAssessmentFound: 'No se encontró ninguna evaluación',
  selfEvaluation: 'Autoevaluación',
  reached: 'Alcanzado',
  expected: 'Esperado',
  priority: 'Prioridad',
  finish: 'Finalizar',
  backAssessments: 'Volver a las evaluaciones',
  view: 'Ver',
  managerEvaluation: 'Evaluación del gerente',
  summary: 'Resumen',
  noNotifications: 'No se encontraron notificaciones',
  markAllAsRead: 'Marcar todo como leído',
  followLink: 'Seguir enlace',
  markAsRead: 'Marcar como leído',
  markAsUnread: 'Marcar como no leído',
  welcomeMessage: 'Hola, bienvenido',
  evaluate: 'Evaluar',
  areYouSuredeleteAssessment: '¿Está seguro de que desea eliminar esta evaluación?',
  allAnswersWillBeDeleted: 'Todas las respuestas y el progreso del usuario también serán eliminados',
  selfEvaluationDone: 'Autoevaluación completada con éxito',
  selfEvaluationDoneMessage: 'Se ha enviado una notificación a su gerente para continuar con el siguiente paso',
  managerEvaluationDone: 'Evaluación completada',
  managerEvaluationDoneMessage: 'Se ha enviado una notificación al empleado',
  statusWaiting: 'Esperando',
  evaluationDone: 'Evaluación finalizada',
  asssessmentDeleted: 'Evaluación eliminada con éxito',
  usersWaitingEvaluation: 'Usuarios en espera de evaluación',
  viewAll: 'Ver todo',
  employee: 'Empleado',
  lastUpdate: 'Última actualización',
  status: 'Estado',
  showingLastSix: 'Mostrando los últimos 6 registros',
  waitingEvaluations: 'Esperando evaluaciones',
  allowCertification: 'Permitir certificación',
  yes: 'Si',
  no: 'No',
  competenceDeleted: 'Competencia eliminada con éxito',
  areYouSureDeleteCompetence: '¿Está seguro de que desea eliminar esta competencia?',
  hasCertification: 'Tiene certificación',
  validity: 'Validez',
  pendingAssessments: 'Evaluaciones pendientes',
  billingInfo: 'Información de facturación',
  manageBillingInfo: 'Administrar información de facturación',
  billingDate: 'Fecha de facturación',
  value: 'Valor',
  profile: 'Perfil',
  keepYourDataUpdated: 'Mantenga sus datos actualizados',
  dataUpdated: 'Datos actualizados',
  update: 'Actualizar',
  profileImageUpdated: 'Imagen de perfil actualizada',
  changeImage: 'Cambiar imagen',
  failedToSendMail: 'Error al enviar el correo electrónico',
  instructionsResetPasswordSendByEmail: 'Instrucciones de recuperación de contraseña enviadas por correo electrónico',
  ok: 'OK',
  resetPasswordTitle:
    'Ingrese su correo electrónico y le enviaremos un enlace con los pasos para restablecer su contraseña',
  send: 'Enviar',
  informEmail: 'Ingrese el correo electrónico',
  setNewPasswordMessage: 'Siga las instrucciones a continuación para restablecer su contraseña',
  reset: 'Reiniciar',
  passwordReseted: 'Contraseña actualizada con éxito',
  welcomeTitle: 'Bienvenido a la plataforma de mapeo de competencias',
  hello: 'Hola',
  loginSubtitle:
    'Identifique las habilidades técnicas y de comportamiento dentro de su empresa. Reconocer a los profesionales alineados con las expectativas y retener el talento',
  forgotMyPassword: 'Olvidé mi contraseña',
  rememberAccess: 'Lembrar acesso',
  signIn: 'Entrar',
  authenticating: 'Autenticando',
  bannerText01: 'Muestra tu potencial',
  bannerText02: 'crecimiento',
  bannerText03: 'Trabaja tus debilidades y muestra las tuyas',
  bannerText04: 'habilidades',
  bannerText05: 'Su empresa tiene mucha gente',
  bannerText06: 'talentosas',
  bannerText07: 'y te ayudaremos',
  bannerText08: 'encuéntrelos',
  statusFeedback: 'Feedback',
  waitingFeedback: 'Espera feedback',
  evaluationFeedbackDone: 'Feedback hecho',
  feedbackDoneMessage: 'Paso de feedback completado',
  waitingManager: 'Esperando al evaluador',
  evaluationinProgress: 'Evaluación en progreso',
  backAssessmentSummary: 'Volver al resumen de evaluación',
  stepFinished: 'Paso completado con éxito',
  selfEvaluationinProgress: 'Autoevaluación en curso',
  administrator: 'Administrador',
  settings: 'Configuración',
  systemSettings: 'Configuración de la plataforma',
  allowEvaluatorsCreateAndDeleteCompetencies: 'Permitir a los evaluadores crear y eliminar sus propias competencias',
  allowEvaluatorsInviteAndDeleteUsers:
    'Permitir que los evaluadores inviten a los usuarios a su equipo, cómo eliminar a sus usuarios',
  allowEvaluatorsCreateAndDeleteAssessments: 'Permite a los evaluadores crear y eliminar sus propias evaluaciones',
  settingsSaved: 'Configuración guardada',
  createdBy: 'Creado por',
  createdAt: 'Creado en',
  howItWorks: 'Cómo funciona',
  inviteEmployess: 'Invitar empleados',
  howItWorks01: 'Con solo unos pocos datos, puede invitar a los usuarios de la empresa a participar',
  createCompetencies: 'Crear las competencias',
  howItWorks02: 'Agregue las competencias que serán evaluadas',
  createAssessmentCycle: 'Crear ciclos de evaluación',
  howItWorks03:
    'Ahora todo lo que tienes que hacer es crear un ciclo de evaluación, informar qué competencias se evaluarán en esta evaluación y quién podrá responder',
  selfAssessmentStep: 'Paso de autoevaluación',
  howItWorks04:
    'Ahora los usuarios pueden responder las evaluaciones que se pusieron a disposición y autoalinearse en cada competencia',
  assessmentStep: 'Paso de evaluación',
  howItWorks05: 'Cada gerente recibe las autoevaluaciones completas de su equipo y pasa a la etapa de evaluación',
  feedbackStep: 'Paso de feedback',
  howItWorks06:
    'Finalmente, el gerente y su subordinado se reúnen para la etapa de feedback, y así finalizar la etapa de evaluación',
  dataStudyAvailable: 'Estudiar los datos',
  howItWorks07:
    'Con datos en la mano, es más fácil tomar decisiones y comprender las necesidades de cada empleado, sus fortalezas y debilidades. Identifique cuáles son las habilidades técnicas y de comportamiento dentro de su empresa. Reconocer profesionales alineados con las expectativas y retener talentos',
  achievements: 'Logros',
  about: 'Acerca de',
  login: 'Iniciar sesión',
  accountedUsers: 'Usuarios contabilizados',
  backHome: 'Volver a la página principal',
  assessmentSuggestionPlaceholder:
    'Ej.: Tomar un curso profesional;Visita a clientes;Portal de capacitación LMS de la empresa',
  assessmentSuggestionLabel: 'Sugerencia',
  doesNotDemonstrate: 'No demuestra',
  basic: 'Básico',
  intermediate: 'Intermedio',
  advanced: 'Avanzado',
  expert: 'Experto',
  mastery: 'Dominio',
  high: 'Alta',
  medium: 'Meio',
  low: 'Bajo',
  areYouSureFinishAssessment: '¿Está seguro de que desea terminar esta evaluación?',
  asssessmentFinished: 'Evaluación completada con éxito',
  noMoreAvailableToUsers: 'Ya no estará disponible para los usuarios',
  yesContinue: 'Sí, continuar',
  weAreInClosedBeta: 'Estamos en etapa de closed beta',
  knowMore: 'Más información',
  closedBetaMessage01: 'Actualmente estamos ejecutando una versión',
  closedBetaMessage02: 'beta cerrada',
  closedBetaMessage03: 'para algunos de nuestros clientes',
  alphaMessage01: 'Una versión',
  alphaMessage02: 'Alpha',
  alphaMessage03: 'ha sido liberado',
  roadMapMessage01: 'Primero',
  roadMapMessage02: 'Roadmap',
  developmentProcessMenssage01: 'Prototipos',
  developmentProcessMenssage02: 'y primer desarrollo',
  developmentProcessMenssage03: 'MVP',
  timeLine: 'Timeline',
  closedBetaEndMessage01: 'Fim de la',
  closedBetaEndMessage02: 'beta cerrada',
  oficialReleaseMessage01: 'Lanzamiento de la',
  oficialReleaseMessage02: 'primera versión',
  oficialReleaseMessage03: 'estable',
  knowHow: 'Aprender cómo',
  stayTuned: 'Estén atentos a las noticias',
  newsEmailPlaceholder: 'Ingrese su correo electrónico',
  thanksNewsletter: '¡Gracias por suscribirte! Enviaremos novedades al email registrado',
  privacyPolicy: 'Política de privacidad',
  poweredBy: 'Desarrollado por',
  reportUserAssessmentTitle: 'Evaluacíon de colaborador',
  reportUserAssessmentSubtitle: 'Califique el estado del colaborador en la evaluacíon seleccionada',
  generateReport: 'Generar informe',
  userReachedReportLabel: 'Alcanzado: Usuario',
  managerReachedReportLabel: 'Alcanzado: Gerente',
  lastAccess: 'Último acesso',
  generatePDF: 'Generar PDF',
  competenceUpdated: 'Competencia actualizada con éxito',
  competenceAlreadyInUseMessage:
    'Esta competencia ya está en uso por una o más evaluaciones, por lo que no es posible agregar nuevos valores',
  uninformed: 'No informado',
  reportCompetenciesTitle: 'Informe de competencias',
  reportCompetenciesSubtitle: 'Ver el resultado de la competencia seleccionada de todos los usuarios',
  version: 'Versión',
  performanceManagement: 'Gestión del rendimiento',
  behavioralManagement: 'Gestión del comportamiento',
  performanceDevelopment: 'Gestión del rendimiento',
  performanceManagementText:
    'Tenga una forma sencilla de analizar los objetivos y competencias de su equipo. A través de evaluaciones cíclicas, es posible monitorear el equipo y controlar el desempeño durante un período.',
  behavioralManagementText:
    'Comprender el comportamiento de los empleados de la organización y aprovechar al máximo sus habilidades. Identifique patrones de comportamiento y coloque a las personas en los lugares y roles correctos, aumentando la satisfacción y reduciendo la rotación.',
  performanceDevelopmentText:
    'Con base en indicadores de desempeño, es posible realizar un diagnóstico organizacional y una alineación continua de expectativas con sus empleados. Mediante la gestión de las habilidades y competencias de los profesionales, priorizar los resultados obtenidos y la eficiencia de los procesos.',
  deleteUserMessage:
    'Se eliminarán todos los datos relacionados con ese usuario, evaluaciones, respuestas, competencias creadas, evaluaciones a las que estaban vinculados, etc.',
  transferUserData: 'Transferir datos de usuario',
  selectNewEvaluator: 'Seleccionar un nuevo tasador',
  editEvaluatorWithLinkedUsers:
    'Antes de modificar el acceso de este usuario, es necesario transferir sus datos mientras evaluador a otro usuario con el mismo acceso',
  assessmentExpired: 'Evaluación Caducada',
  weAreLive: '¡Estamos en vivo!d',
  startFeedback: 'Iniciar feedback',
  usersAdded: 'Usuarios agregados con éxito',
  waitingEvaluator: 'Esperando al evaluador',
  position: 'Cargo',
  addNewPosition: 'Añadir nueva posición',
  addPositionLabel: 'Informar el nuevo cargo',
  addedPosition: 'Posición agregada con éxito',
  areYouSureDeletePosition: '¿Está seguro de que desea eliminar esta posición?',
  positionDeleted: '¡Posición eliminada con éxito!',
  development: 'Desarrollo',
  notApplicable: 'Not applicable',
  addEvidence: 'Agregar evidencia',
  replaceFile: 'Reemplazar archivo',
  validate: 'Validar',
  evidenceValidated: 'Evidencia validada con éxito',
  evidenceAttached: 'Evidencia adjunta con éxito',
  pricing: 'Precios',
  until: 'Hasta',
  contactUs: 'Contáctenos',
  supportPricingStarter: 'Soporte vía correo electrónico',
  supportPricingPro: 'Soporte vía whatsapp, teléfono y correo electrónico',
  supportPricingEnterprise: 'Soporte vía whatsapp, teléfono y correo electrónico',
  infrastructurePricingStarter: 'Servidor compartido',
  infrastructurePricingPro: 'Servidor compartido',
  infrastructurePricingEnterprise: 'Servidor dedicado',
  requestQuote: 'Solicitar presupuesto',
  month: 'Mês',
  storage: 'Almacenamiento',
  exceededMaxSize: 'Excedeu o tamanho máximo',
  attachment: 'Adjunto',
  maxFileSize: 'Tamaño máximo permitido',
  allowedFormats: 'Formatos permitidos',
  confirmAction: "Presiona enter o haz clic en '+' para confirmar",
  exportExcel: 'Exportar excel',
}

export default DictionaryES
