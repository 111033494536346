import { Menu, MenuList, MenuItem, ListItemIcon, ListItemText, Divider, IconButton } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import DeleteUserModal from '../modals/delete-user.modal'
import SendIcon from '@mui/icons-material/Send'
import { StoreContext } from '../../../stores/store.context'
import { trackPromise } from 'react-promise-tracker'
import { UserData } from '../../../models/user.model'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { RolesEnum } from '../../../enums/roles.enum'
import { ModalOpts } from '../../../models/modal.model'
import InviteUserForm from '../forms/invite-user-form'
import EditIcon from '@mui/icons-material/Edit'

interface UserMenuProps {
  user: UserData
}

const UserMenu: React.FC<UserMenuProps> = (props: UserMenuProps) => {
  const { i18nStore, modalStore, toastStore, userStore, tenantStore, authStore } = useContext(StoreContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const sendActivationLink = async (id: string) => {
    handleClose()

    const sended = await trackPromise(userStore.sendActivationLink(id), 'page-loader')

    if (sended) {
      toastStore.pushToast({ message: i18nStore.dictionary.activationLinkSended, type: 'success' })
    }
  }

  const checkAccess = () => {
    return (
      tenantStore.tenant &&
      authStore.tokenData &&
      ((authStore.tokenData.role === RolesEnum.administrator && props.user.role !== RolesEnum.administrator) ||
        (tenantStore.tenant.settings.allowEvaluatorsInviteAndDeleteUsers &&
          authStore.tokenData.role === RolesEnum.evaluator &&
          props.user.role === RolesEnum.user) ||
        authStore.tokenData.role === RolesEnum.master)
    )
  }

  return (
    <Fragment>
      {props.user.role !== 'master' && (
        <Fragment>
          <IconButton color="primary" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            sx={{
              width: 320,
              maxWidth: '100%',
              '& .MuiPaper-root': {
                backgroundColor: 'background.default',
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuList>
              {props.user.status === 'new' && (
                <MenuItem onClick={() => sendActivationLink(props.user._id)}>
                  <ListItemIcon>
                    <SendIcon fontSize="small" sx={{ color: 'text.primary' }} />
                  </ListItemIcon>
                  <ListItemText>{i18nStore.dictionary.resendActivationLink}</ListItemText>
                </MenuItem>
              )}

              {checkAccess() && (
                <MenuItem
                  onClick={() => {
                    const modalOpts: ModalOpts = {
                      size: 'md',
                    }
                    modalStore.openModal(<InviteUserForm user={props.user} />, modalOpts)
                    handleClose()
                  }}
                >
                  <ListItemIcon>
                    <EditIcon fontSize="small" sx={{ color: 'warning.main' }} />
                  </ListItemIcon>
                  <ListItemText>{i18nStore.dictionary.edit}</ListItemText>
                </MenuItem>
              )}

              {checkAccess() && (
                <MenuItem
                  onClick={() => {
                    modalStore.openModal(<DeleteUserModal id={props.user._id} />, { size: 'sm' })
                    handleClose()
                  }}
                >
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" sx={{ color: 'error.main' }} />
                  </ListItemIcon>
                  <ListItemText>{i18nStore.dictionary.delete}</ListItemText>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Fragment>
      )}
    </Fragment>
  )
}

export default observer(UserMenu)
