import TableRowsIcon from '@mui/icons-material/TableRows'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { Box, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

interface GridSwitcherProps {
  onChangeGridView: (e: string) => void
}

const GridSwitcher: React.FC<GridSwitcherProps> = (props: GridSwitcherProps) => {
  const [active, setActive] = useState<string>('card')

  return (
    <Box sx={{ width: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Button
        onClick={() => {
          props.onChangeGridView('table')
          setActive('table')
        }}
      >
        <TableRowsIcon sx={{ color: active === 'table' ? 'secondary.lighter' : 'grey.500' }} />
      </Button>

      <Button
        onClick={() => {
          props.onChangeGridView('card')
          setActive('card')
        }}
      >
        <DashboardIcon sx={{ color: active === 'card' ? 'secondary.lighter' : 'grey.500' }} />
      </Button>
    </Box>
  )
}

export default observer(GridSwitcher)
