import {
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  Link,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import ButtonLoader from '../../../components/button-loader'
import { StoreContext } from '../../../stores/store.context'
import { SearchOutlined } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import { Competence } from '../../../models/competence.model'
import NotFoundData from '../../../components/not-found'
import { Link as RouterLink } from 'react-router-dom'

interface CompetencesListModalProps {
  emitSelected: Function
}

const CompetencesListModal: React.FC<CompetencesListModalProps> = (props: CompetencesListModalProps) => {
  const { modalStore, i18nStore, authStore, competenceStore } = useContext(StoreContext)
  const [search, setSearch] = useState('')
  const [tempSearch, setTempSearch] = useState('')
  const [selectedCompetences, setCompetences] = useState([] as Array<Competence>)
  let [checkedAll, setCheckedAll] = useState(false)
  let [page, setPage] = useState<number>(parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string))

  const searchByCompetenceName = (event: any) => setSearch(event.target.value as string)

  const handleToggle = (competence: Competence, index: number) => {
    let newChecked = [...selectedCompetences]

    const exists = selectedCompetences.some((obj: any) => obj._id === competence._id)
    if (!exists) {
      newChecked.push(competence)
    } else {
      newChecked = newChecked.filter((obj: any) => obj._id !== competence._id)
    }

    competence = { ...competence, checked: !competence.checked }
    competenceStore.updateCompetenciesList(index, competence)

    setCompetences(newChecked)

    if (newChecked.length === 0) {
      setCheckedAll(false)
    }
  }

  const handleToggleAll = () => {
    if (competenceStore.competencies) {
      setCheckedAll((checkedAll = !checkedAll))
      let newChecked = [...selectedCompetences]
      competenceStore.competencies.paginatedData.forEach((competence: Competence, index: number) => {
        const exists = selectedCompetences.some((obj: any) => obj._id === competence._id)
        if (checkedAll && !exists) {
          newChecked.push(competence)
        } else if (!checkedAll) {
          newChecked = []
        }

        competence = { ...competence, checked: checkedAll }
        competenceStore.updateCompetenciesList(index, competence)
        setCompetences(newChecked)
      })
    }
  }

  useEffect(() => {
    const listCompetencies = async () => {
      if (authStore.tokenData) {
        var filter = JSON.stringify({
          name: search,
        })

        await competenceStore.listCompetencies(
          authStore.tokenData.tenant,
          page,
          parseInt(process.env.REACT_APP_DATA_LIMIT as string),
          'createdAt',
          'desc',
          filter
        )
      }
    }
    listCompetencies()
  }, [authStore, competenceStore, i18nStore.dictionary, search, page])

  const confirm = async () => {
    props.emitSelected(selectedCompetences)
    modalStore.handleClose()
  }

  return (
    <Fragment>
      <Typography variant="h4" color={'text.primary'} align="center" sx={{ my: 3 }}>
        {i18nStore.dictionary.selectCompetences}
      </Typography>

      {competenceStore.competencies && (
        <Grid container>
          <Grid item xs={12} display="flex">
            <TextField
              label={i18nStore.dictionary.searchByCompetenceName}
              value={tempSearch}
              onChange={(e) => setTempSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') searchByCompetenceName(e)
              }}
              fullWidth
              placeholder={i18nStore.dictionary.searchByCompetenceName}
              InputProps={{
                startAdornment: (
                  <IconButton color="primary" onClick={() => setSearch(tempSearch)}>
                    <SearchOutlined />
                  </IconButton>
                ),
                endAdornment: tempSearch && (
                  <IconButton
                    onClick={() => {
                      setSearch('')
                      setTempSearch('')
                    }}
                  >
                    <CancelIcon sx={{ color: 'primary.main' }} />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {competenceStore.competencies.paginatedData.length > 0 ? (
              <Box sx={{ height: 500, overflow: 'auto' }}>
                <List sx={{ width: '100%' }}>
                  <ListItem disablePadding>
                    <ListItemButton role={undefined} onClick={() => handleToggleAll()} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          sx={{ color: 'primary.main' }}
                          size="small"
                          checked={checkedAll}
                          value={checkedAll}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={i18nStore.dictionary.selectAll} />
                    </ListItemButton>
                  </ListItem>

                  {competenceStore.competencies.paginatedData.map((competence: Competence, index: number) => {
                    const labelId = `checkbox-list-label-${index}`

                    return (
                      <ListItem key={index} disablePadding>
                        <ListItemButton role={undefined} onClick={() => handleToggle(competence, index)} dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              sx={{ color: 'primary.main' }}
                              size="small"
                              checked={competence.checked}
                              value={competence._id}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={competence.name[i18nStore.language]} />
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
                </List>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4 }}>
                  <ButtonLoader
                    onClick={() => setPage((page = page + 1))}
                    variant="text"
                    loadingText={i18nStore.dictionary.loading}
                    text={i18nStore.dictionary.loadMore}
                    area="load-more"
                    sx={{ display: 'flex', pointerEvents: 'all', paddingX: 0 }}
                    disabled={competenceStore.competencies.totalPages === page}
                  />
                </Box>
              </Box>
            ) : (
              <NotFoundData />
            )}
          </Grid>
        </Grid>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4 }}>
        <Button
          disableElevation
          size="large"
          type="button"
          variant="text"
          color="primary"
          onClick={() => modalStore.handleClose()}
        >
          {i18nStore.dictionary.back}
        </Button>

        {competenceStore.competencies && competenceStore.competencies.paginatedData.length > 0 && (
          <ButtonLoader loadingText="Processando..." text={i18nStore.dictionary.confirm} onClick={() => confirm()} />
        )}
      </Box>
    </Fragment>
  )
}

export default observer(CompetencesListModal)
