import { Grid, Typography, Step, Stepper, Box, Button, StepLabel } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../stores/store.context'
import React from 'react'
import AddCompetenceStep1 from './add-competence-step1'
import SaveIcon from '@mui/icons-material/Save'
import ButtonLoader from '../../../components/button-loader'
import { trackPromise } from 'react-promise-tracker'
import AddCompetenceStep2 from './add-competence-step2'
import AddCompetenceStep3 from './add-competence-step3'
import { CompetenceForm } from '../../../models/competence.model'
import { RolesEnum } from '../../../enums/roles.enum'

interface AddCompetenceFormProps {
  _id?: string
}

const AddCompetenceForm: React.FC<AddCompetenceFormProps> = (props: AddCompetenceFormProps) => {
  const { i18nStore, competenceStore, toastStore, modalStore, tenantStore, authStore } = useContext(StoreContext)
  const [edit, setEdit] = useState(false)
  let [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    competenceStore.competenceFormSteps = [
      { title: i18nStore.dictionary.addCompetenceStep1Title, completed: false },
      { title: i18nStore.dictionary.addCompetenceStep2Title, completed: false },
      { title: i18nStore.dictionary.addCompetenceStep3Title, completed: false },
    ]

    competenceStore.competenceFormData = new CompetenceForm()

    if (props._id) {
      const getById = async (id: string) => {
        setEdit(true)
        await competenceStore.getById(id)
        if (competenceStore.competence) competenceStore.competenceFormData = competenceStore.competence
        competenceStore.competenceFormSteps.forEach((_, index) => competenceStore.validateStep(index))
      }

      getById(props._id)
    }
  }, [competenceStore, props._id])

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const handleStep = (step: number) => () => {
    competenceStore.validateStep(step - 1)
    setActiveStep(step)
  }

  const handleNext = () => {
    setActiveStep((activeStep = activeStep + 1))
    competenceStore.validateStep(activeStep - 1)
  }

  const handleSaveDraft = async () => {
    const formData = JSON.parse(JSON.stringify(competenceStore.competenceFormData))
    const savedDraft = await trackPromise(competenceStore.save(formData, true), 'save-draft')
    if (savedDraft && !edit) {
      setEdit(true)
      competenceStore.competenceFormData!._id = savedDraft._id
    }

    if (savedDraft) {
      toastStore.pushToast({ message: i18nStore.dictionary.draftSaved, type: 'success' })
    }
  }

  const handlePublish = async () => {
    const formData = JSON.parse(JSON.stringify(competenceStore.competenceFormData))
    const published = await trackPromise(competenceStore.save(formData, false), 'publishing')

    if (published) {
      toastStore.pushToast({ message: i18nStore.dictionary.competencePublished, type: 'success' })
      modalStore.handleClose()
    }
  }

  const handleUpdate = async () => {
    const formData = JSON.parse(JSON.stringify(competenceStore.competenceFormData))
    const published = await trackPromise(competenceStore.save(formData, false), 'save-draft')

    if (published) {
      toastStore.pushToast({ message: i18nStore.dictionary.competenceUpdated, type: 'success' })
    }
  }

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h4">{i18nStore.dictionary.fillNewCompetenceData}</Typography>
        </Grid>
      </Grid>

      {competenceStore.competenceFormSteps.length > 0 && (
        <Fragment>
          <Stepper nonLinear activeStep={activeStep} sx={{ my: 4 }}>
            {competenceStore.competenceFormSteps.map((step, index) => (
              <Step key={step.title} completed={step.completed}>
                <StepLabel
                  error={index !== 2 && !step.completed}
                  sx={{
                    cursor: 'pointer',
                    '.MuiStepLabel-label': { color: 'text.primary' },
                  }}
                  onClick={handleStep(index)}
                >
                  {step.title}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <Fragment>
            <Box sx={{ display: activeStep === 0 ? 'block' : 'none' }}>
              <AddCompetenceStep1 step={activeStep} />
            </Box>

            <Box sx={{ display: activeStep === 1 ? 'block' : 'none' }}>
              <AddCompetenceStep2 step={activeStep} edit={edit} />
            </Box>

            {activeStep === 2 && (
              <Box>
                <AddCompetenceStep3 step={activeStep} />
              </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mt: 4 }}>
              <ButtonLoader
                onClick={competenceStore.competenceFormData.status === 'active' ? handleUpdate : handleSaveDraft}
                variant="text"
                loadingText={i18nStore.dictionary.loading}
                text={
                  <Fragment>
                    <SaveIcon sx={{ mr: 2 }} />
                    <Typography variant="button">
                      {competenceStore.competenceFormData.status === 'active'
                        ? i18nStore.dictionary.update
                        : i18nStore.dictionary.saveDraft}
                    </Typography>
                  </Fragment>
                }
                area="save-draft"
                sx={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}
              />

              {activeStep !== 0 && (
                <Button variant="text" onClick={handleBack} sx={{ mr: 2 }}>
                  {i18nStore.dictionary.back}
                </Button>
              )}

              {activeStep !== 2 ? (
                <Button variant="contained" onClick={() => handleNext()}>
                  {i18nStore.dictionary.next}
                </Button>
              ) : (
                competenceStore.competenceFormData.status !== 'active' &&
                tenantStore.tenant &&
                authStore.tokenData &&
                (tenantStore.tenant.settings.allowEvaluatorsCreateAndDeleteCompetencies ||
                  authStore.tokenData.role === RolesEnum.administrator) && (
                  <ButtonLoader
                    onClick={handlePublish}
                    variant="contained"
                    loadingText={i18nStore.dictionary.loading}
                    disabled={!competenceStore.competenceFormValid}
                    text={
                      <Fragment>
                        <SaveIcon sx={{ mr: 2 }} />
                        <Typography variant="button">{i18nStore.dictionary.publish}</Typography>
                      </Fragment>
                    }
                    area="publishing"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  />
                )
              )}
            </Box>
          </Fragment>
        </Fragment>
      )}
    </Fragment>
  )
}

export default observer(AddCompetenceForm)
