/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Typography,
  Grid,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slider,
  FormControlLabel,
  Switch,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import ContentArea from '../../components/content-area'
import { StoreContext } from '../../stores/store.context'
import BreadCrumb from '../../components/breadcrumb'
import { AssessmentEvaluationForm, AssessmentSteps } from '../../models/assessment.model'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ProgressBarWithLabel from '../../components/progress-bar'
import WarningIcon from '@mui/icons-material/Warning'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ButtonLoader from '../../components/button-loader'
import SaveIcon from '@mui/icons-material/Save'
import { trackPromise } from 'react-promise-tracker'
import AssessmentEvaluateUserSuccess from './components/assessment-evaluate-user-success'
import AssessmentEvaluateUserNavigation from './components/assessment-evaluate-user-navigation'
import { action } from 'mobx'
import { MobileDatePicker } from '@mui/x-date-pickers'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import LevelsBox from './components/levels-box'
import PriorityBox from './components/priority-box'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { RolesEnum } from '../../enums/roles.enum'

const AssessmentEvaluateUserPage: React.FC = () => {
  const { i18nStore, assessmentStore, breadcrumbStore, tenantStore, authStore, toastStore, generalStore, modalStore } =
    useContext(StoreContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [showSuccess, toggleSuccess] = useState(false)
  const params = useParams()

  useEffect(() => {
    const getTenantDevelopments = async () => await tenantStore.getTenantDevelopments(authStore.tokenData!.tenant!)

    const getById = async (id: string) => {
      const userId = params.user

      if (userId) {
        await assessmentStore.getAssessmentData(id, userId)
        if (params.id) {
          assessmentStore.assessmentEvaluationForm = new AssessmentEvaluationForm(params.id, userId)
          assessmentStore.setAssessmentEvaluationFormDefaultValues()
        }

        if (searchParams.get('edit') || searchParams.get('view')) {
          await assessmentStore.getAssessmentUserAnswers(id, userId)

          if (isManagerStep()) {
            if (assessmentStore.assessmentUserData?.step === 'evaluation_completed') {
              assessmentStore.assessmentEvaluationForm!.currentCompetenceManager = 1
            }
            assessmentStore.assessmentEvaluationForm!.answersManager.forEach((competence, i) => {
              competence.competenceValues.forEach((_, j) => {
                assessmentStore.checkCompetenceValueValidity(i, j, isManagerStep())
              })
            })
          } else {
            assessmentStore.assessmentEvaluationForm!.answers.forEach((competence, i) => {
              competence.competenceValues.forEach((_, j) => {
                assessmentStore.checkCompetenceValueValidity(i, j, isManagerStep())
              })
            })
          }
        }
      }

      if (assessmentStore.assessment && params.id) {
        let userBackBreadcrumb = [
          {
            name: i18nStore.dictionary.myAssessments,
            link: '/secure/assessment',
          },
          {
            name: assessmentStore.assessment.name[i18nStore.language],
            link: `/secure/assessment/${assessmentStore.assessment._id}`,
          },
        ]

        if (searchParams.get('step') && authStore.tokenData!.role === 'evaluator') {
          userBackBreadcrumb = [
            {
              name: i18nStore.dictionary.manageAssessments,
              link: '/secure/manage-assessments',
            },
            {
              name: assessmentStore.assessment.name[i18nStore.language],
              link: `/secure/assessment/${assessmentStore.assessment._id}/summary`,
            },
            {
              name:
                assessmentStore.assessmentUserData!.user.firstName +
                ' ' +
                assessmentStore.assessmentUserData!.user.lastName,
              link: ``,
            },
          ]
        }

        breadcrumbStore.setBreadcrumbItems(userBackBreadcrumb)
      }
    }

    if (params.id && params.user) {
      getById(params.id)
      getTenantDevelopments()
    }
  }, [assessmentStore, params.id, i18nStore, breadcrumbStore, authStore.tokenData, params.user])

  const handleSave = async (draft: boolean) => {
    const isEditting: boolean = searchParams.get('edit') ? true : false
    if (assessmentStore.assessmentEvaluationForm) {
      if (draft) {
        await trackPromise(
          assessmentStore.saveEvaluation(assessmentStore.assessmentEvaluationForm, isEditting, isManagerStep()),
          'saving-draft'
        )
        toastStore.pushToast({ message: i18nStore.dictionary.draftSaved, type: 'success' })

        if (isManagerStep()) {
          if (searchParams.get('progress') === 'waiting_manager_evaluation') {
            setSearchParams(`?edit=true&step=managers_evaluation&progress=manager_evaluation_in_progress`)
          }
        } else {
          if (!searchParams.get('edit')) {
            setSearchParams(`?edit=true&progress=in_progress`)
          } else {
            setSearchParams(`${searchParams}&progress=manager_evaluation_in_progress`)
          }
        }
      } else {
        await trackPromise(
          assessmentStore.saveEvaluation(assessmentStore.assessmentEvaluationForm, isEditting, isManagerStep()),
          'finishing-evaluation'
        )

        if (params.id) {
          await assessmentStore.getAssessmentData(params.id, params.user)
          toggleSuccess(true)
        }
      }
    }
  }

  const isManagerStep = (): boolean => {
    return Boolean(
      searchParams.get('step') &&
        (searchParams.get('step') === 'managers_evaluation' ||
          searchParams.get('step') === 'evaluation_completed' ||
          searchParams.get('step') === 'evaluation_feedback')
    )
  }

  const toggleCompetence = (i: number): boolean => {
    if (assessmentStore.assessmentEvaluationForm) {
      if (isManagerStep()) {
        if (searchParams.get('step') === 'evaluation_feedback') {
          return assessmentStore.assessmentEvaluationForm.currentCompetenceFeedback === i + 1
        }

        return assessmentStore.assessmentEvaluationForm.currentCompetenceManager === i + 1
      }
      return assessmentStore.assessmentEvaluationForm.currentCompetence === i + 1
    }
    return false
  }

  const handleValueChange = (e: any, field: any, competenceIndex: number, competenceValueIndex: number) => {
    assessmentStore.updateCompetenceValue(e, field, competenceIndex, competenceValueIndex, isManagerStep())
    assessmentStore.checkCompetenceValueValidity(competenceIndex, competenceValueIndex, isManagerStep())
  }

  const checkProgress = (): number => {
    if (assessmentStore.assessmentEvaluationForm) {
      if (searchParams.get('step') === 'evaluation_feedback') {
        return assessmentStore.assessmentEvaluationForm.currentProgressFeedback
      }

      if (!searchParams.get('view') && assessmentStore.assessmentUserData!.step !== 'evaluation_completed') {
        if (isManagerStep() && searchParams.get('progress') !== 'feedback') {
          return assessmentStore.assessmentEvaluationForm.currentProgressManager
        } else if (!isManagerStep() && searchParams.get('progress') !== 'feedback') {
          return assessmentStore.assessmentEvaluationForm.currentProgress
        } else if (searchParams.get('progress') !== 'feedback') {
          return assessmentStore.assessmentEvaluationForm.currentProgressFeedback
        }
      }
    }
    return 100
  }

  const handleFieldValue = (i: number, j: number, field: string) => {
    if (assessmentStore.assessmentEvaluationForm) {
      if (isManagerStep()) {
        if (
          typeof assessmentStore.assessmentEvaluationForm.answersManager[i].competenceValues[j][field] === 'undefined'
        ) {
          assessmentStore.assessmentEvaluationForm.answersManager[i].competenceValues[j][field] = ''
        }

        if (
          assessmentStore.assessmentEvaluationForm.answersManager[i].competenceValues[j][field] === 0 ||
          assessmentStore.assessmentEvaluationForm.answersManager[i].competenceValues[j][field] === ''
        ) {
          if (field === 'limitDate') {
            assessmentStore.assessmentEvaluationForm.answersManager[i].competenceValues[j][field] = new Date()
          }

          if (field === 'reached') {
            return assessmentStore.assessmentEvaluationForm.answersManager[i].competenceValues[j][field]
          }
        }
        return assessmentStore.assessmentEvaluationForm.answersManager[i].competenceValues[j][field]
      }
      return assessmentStore.assessmentEvaluationForm.answers[i].competenceValues[j][field]
    }

    return null
  }

  return (
    assessmentStore.assessment && (
      <Fragment>
        <Box>
          <BreadCrumb />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h2">{assessmentStore.assessment.name[i18nStore.language]}</Typography>

            <Chip
              label={generalStore.getStatusTranslated(searchParams.get('progress'))}
              color={generalStore.getBackgroundByStatus(searchParams.get('progress'))}
              size="small"
              sx={{ ml: 4 }}
            />
          </Box>

          <Typography variant="body2" sx={{ mt: 2 }}>
            {i18nStore.dictionary.limiteDate}:{' '}
            {new Date(assessmentStore.assessment.endDate!).toLocaleDateString(i18nStore.locale)}
          </Typography>
        </Box>

        <ContentArea>
          {assessmentStore.assessmentUserData && assessmentStore.assessmentEvaluationForm && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  {!searchParams.get('view') &&
                    !showSuccess &&
                    authStore.tokenData?.role !== RolesEnum.administrator && (
                      <ButtonLoader
                        onClick={() => handleSave(true)}
                        variant="text"
                        loadingText={i18nStore.dictionary.loading}
                        text={
                          <Fragment>
                            <SaveIcon sx={{ mr: 2 }} />
                            <Typography variant="button">{i18nStore.dictionary.saveDraft}</Typography>
                          </Fragment>
                        }
                        area="saving-draft"
                        sx={{ display: 'flex', alignItems: 'center' }}
                      />
                    )}
                  <Chip
                    label={assessmentStore.getStepLabel(assessmentStore.assessmentUserData.step as AssessmentSteps)}
                    color={'warning'}
                    size="small"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                {!showSuccess && (
                  <AssessmentEvaluateUserNavigation
                    isManagerStep={isManagerStep}
                    handleSave={handleSave}
                    step={searchParams.get('step') as string}
                  />
                )}
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <ProgressBarWithLabel variant="determinate" value={checkProgress()} />
              </Grid>
              {assessmentStore.assessment.competencies &&
                assessmentStore.assessment.competencies.map((competence, i) => {
                  return (
                    !showSuccess && (
                      <Grid container key={i} sx={{ mx: 4, my: 6, display: toggleCompetence(i) ? 'flex' : 'none' }}>
                        <Grid item xs={12} textAlign="center">
                          <Typography variant="h2">{competence.name[i18nStore.language]}</Typography>
                          <Typography variant="h6" sx={{ mt: 2 }}>
                            {competence.description[i18nStore.language]}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center" sx={{ mt: 3 }}>
                          {competence.competenceValues.map((competenceValue, j) => {
                            return (
                              <Accordion key={j} sx={{ boxShadow: 1, '&:not(:first-of-type)': { mt: 2 } }}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon color="primary" />}
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  {!competenceValue.isValid ? (
                                    <WarningIcon sx={{ mr: 2 }} color="warning" />
                                  ) : (
                                    <CheckCircleIcon sx={{ mr: 2 }} color="success" />
                                  )}
                                  <Typography variant="subtitle1">
                                    {competenceValue.name[i18nStore.language]}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ mt: 2 }}>
                                  {competenceValue.allowCertification && (
                                    <Grid container spacing={4} alignItems="flex-end">
                                      <Grid item xs={12} sm={4} textAlign="left">
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              disabled={isManagerStep() || Boolean(searchParams.get('view'))}
                                              name={`hasCertification.${i}.${j}`}
                                              sx={{ color: 'primary.main' }}
                                              onChange={(e) => {
                                                action(() => {
                                                  assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[
                                                    j
                                                  ] = {
                                                    ...assessmentStore.assessmentEvaluationForm!.answers[i]
                                                      .competenceValues[j],
                                                    certification: {
                                                      hasCertification:
                                                        !assessmentStore.assessmentEvaluationForm!.answers[i]
                                                          .competenceValues[j].certification?.hasCertification,
                                                    },
                                                  }
                                                })()
                                              }}
                                              value={Boolean(
                                                assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[j]
                                                  .certification?.hasCertification
                                              )}
                                              checked={
                                                !!assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[
                                                  j
                                                ].certification?.hasCertification
                                              }
                                            />
                                          }
                                          label={
                                            Boolean(
                                              assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[j]
                                                .certification?.hasCertification
                                            )
                                              ? i18nStore.dictionary.hasCertification + ' ' + i18nStore.dictionary.yes
                                              : i18nStore.dictionary.hasCertification + ' ' + i18nStore.dictionary.no
                                          }
                                        />
                                      </Grid>

                                      {assessmentStore.assessmentEvaluationForm &&
                                        assessmentStore.assessmentEvaluationForm.answers[i].competenceValues[j]
                                          .certification &&
                                        Boolean(
                                          assessmentStore.assessmentEvaluationForm.answers[i].competenceValues[j]
                                            .certification!.hasCertification
                                        ) && (
                                          <Grid item xs={12} sm={6} textAlign="left">
                                            <MobileDatePicker
                                              disabled={isManagerStep() || Boolean(searchParams.get('view'))}
                                              disablePast
                                              label={i18nStore.dictionary.validity}
                                              value={
                                                assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[j]
                                                  .certification?.expirationDate ?? new Date()
                                              }
                                              onChange={(newValue) => {
                                                action(() => {
                                                  if (assessmentStore.assessmentEvaluationForm && newValue) {
                                                    assessmentStore.assessmentEvaluationForm.answers[
                                                      i
                                                    ].competenceValues[j] = {
                                                      ...assessmentStore.assessmentEvaluationForm.answers[i]
                                                        .competenceValues[j],
                                                      certification: {
                                                        ...assessmentStore.assessmentEvaluationForm.answers[i]
                                                          .competenceValues[j].certification,
                                                        expirationDate: newValue,
                                                      },
                                                    }
                                                  }
                                                })()
                                              }}
                                              renderInput={(params) => <TextField variant="standard" {...params} />}
                                            />
                                          </Grid>
                                        )}
                                    </Grid>
                                  )}

                                  <Grid container spacing={4} sx={{ mt: competenceValue.allowCertification ? 1 : 0 }}>
                                    <Grid item xs={12} sm={4}>
                                      <Slider
                                        disabled={Boolean(searchParams.get('view'))}
                                        name={`reached.${i}.${j}`}
                                        value={handleFieldValue(i, j, 'reached')}
                                        defaultValue={handleFieldValue(i, j, 'reached')}
                                        step={1}
                                        marks
                                        min={0}
                                        max={5}
                                        valueLabelDisplay="on"
                                        onChange={(e) => handleValueChange(e, 'reached', i, j)}
                                        sx={{
                                          '::after': {
                                            content: `"${
                                              assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[j]
                                                .reached
                                            }"`,
                                            fontWeight: 500,
                                            lineHeight: 1.5,
                                            fontSize: '0.75rem',
                                            color: '#fff',
                                            left: assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[
                                              j
                                            ].reached
                                              ? assessmentStore.assessmentEvaluationForm!.answers[i].competenceValues[j]
                                                  .reached! *
                                                  20 +
                                                '%'
                                              : '0',
                                            position: 'absolute',
                                            width: '20px',
                                            height: '20px',
                                            boxSizing: 'border-box',
                                            borderRadius: '50%',
                                            outline: 0,
                                            backgroundColor: 'warning.main',
                                            display: isManagerStep() ? 'flex' : 'none',
                                            WebkitAlignItems: 'center',
                                            WebkitBoxAlign: 'center',
                                            MsFlexAlign: 'center',
                                            alignItems: 'center',
                                            WebkitBoxPack: 'center',
                                            MsFlexPack: 'center',
                                            WebkitJustifyContent: 'center',
                                            justifyContent: 'center',
                                            WebkitTransition:
                                              'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                            transition:
                                              'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                            top: '50%',
                                            WebkitTransform: 'translate(-50%, -50%)',
                                            MozTransform: 'translate(-50%, -50%)',
                                            MsTransform: 'translate(-50%, -50%)',
                                            transform: 'translate(-50%, -50%)',
                                          },
                                          color:
                                            isManagerStep() &&
                                            assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                              .competenceValues[j]['reached'] === null
                                              ? 'warning.main'
                                              : 'primary.main',
                                        }}
                                      />
                                      <Typography variant="subtitle1">
                                        {i18nStore.dictionary.reached}{' '}
                                        <IconButton
                                          sx={{ alignSelf: 'flex-start' }}
                                          onClick={() => modalStore.openModal(<LevelsBox />)}
                                        >
                                          <HelpOutlineIcon fontSize="small" color="primary" />
                                        </IconButton>
                                      </Typography>
                                    </Grid>
                                    {isManagerStep() && (
                                      <Fragment>
                                        <Grid item xs={12} sm={4}>
                                          <Slider
                                            disabled={Boolean(searchParams.get('view'))}
                                            name={`expected.${i}.${j}`}
                                            value={handleFieldValue(i, j, 'expected')}
                                            defaultValue={handleFieldValue(i, j, 'expected')}
                                            step={1}
                                            marks
                                            min={0}
                                            max={5}
                                            valueLabelDisplay="on"
                                            onChange={(e) => handleValueChange(e, 'expected', i, j)}
                                          />
                                          <Typography variant="subtitle1">
                                            {i18nStore.dictionary.expected}{' '}
                                            <IconButton
                                              sx={{ alignSelf: 'flex-start' }}
                                              onClick={() => modalStore.openModal(<LevelsBox />)}
                                            >
                                              <HelpOutlineIcon fontSize="small" color="primary" />
                                            </IconButton>
                                          </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                          <Slider
                                            disabled={
                                              Boolean(searchParams.get('view')) ||
                                              assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                .competenceValues[j]['reached'] ===
                                                assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                  .competenceValues[j]['expected']
                                            }
                                            name={`priority.${i}.${j}`}
                                            value={
                                              assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                .competenceValues[j]['reached'] ===
                                              assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                .competenceValues[j]['expected']
                                                ? 0
                                                : handleFieldValue(i, j, 'priority')
                                            }
                                            defaultValue={handleFieldValue(i, j, 'priority')}
                                            step={1}
                                            marks
                                            min={
                                              assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                .competenceValues[j]['reached'] ===
                                              assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                .competenceValues[j]['expected']
                                                ? 0
                                                : 1
                                            }
                                            max={3}
                                            valueLabelDisplay="on"
                                            onChange={(e) => handleValueChange(e, 'priority', i, j)}
                                          />
                                          <Typography variant="subtitle1">
                                            {i18nStore.dictionary.priority}{' '}
                                            <IconButton
                                              sx={{ alignSelf: 'flex-start' }}
                                              onClick={() => modalStore.openModal(<PriorityBox />)}
                                            >
                                              <HelpOutlineIcon fontSize="small" color="primary" />
                                            </IconButton>
                                          </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                          <TextField
                                            disabled={Boolean(searchParams.get('view'))}
                                            fullWidth
                                            type="text"
                                            value={handleFieldValue(i, j, 'suggestion') ?? ''}
                                            name={`suggestion.${i}.${j}`}
                                            onChange={(e) => handleValueChange(e, 'suggestion', i, j)}
                                            placeholder={i18nStore.dictionary.assessmentSuggestionPlaceholder}
                                            variant="outlined"
                                            label={i18nStore.dictionary.assessmentSuggestionLabel}
                                            inputProps={{
                                              autoComplete: 'assessment-suggestion',
                                              form: {
                                                autocomplete: 'off',
                                              },
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={4}>
                                          {tenantStore.developments && (
                                            <FormControl fullWidth>
                                              <InputLabel>{i18nStore.dictionary.development}</InputLabel>
                                              <Select
                                                disabled={
                                                  Boolean(authStore.tokenData?.role === 'user') ||
                                                  Boolean(searchParams.get('view'))
                                                }
                                                sx={{ textAlign: 'left' }}
                                                value={handleFieldValue(i, j, 'development') ?? ''}
                                                onChange={(e) => handleValueChange(e, 'development', i, j)}
                                                label={i18nStore.dictionary.development}
                                              >
                                                <MenuItem value={''}>{i18nStore.dictionary.notApplicable}</MenuItem>
                                                {tenantStore.developments.map((development, index) => {
                                                  return (
                                                    <MenuItem value={development._id} key={index}>
                                                      {development.title[i18nStore.language]}
                                                    </MenuItem>
                                                  )
                                                })}
                                              </Select>
                                            </FormControl>
                                          )}
                                        </Grid>
                                        {assessmentStore.assessmentEvaluationForm!.answersManager[i].competenceValues[j]
                                          .development &&
                                          assessmentStore.assessmentEvaluationForm!.answersManager[i].competenceValues[
                                            j
                                          ].development !== '' && (
                                            <Fragment>
                                              <Grid item xs={2} sx={{ textAlign: 'left' }}>
                                                <MobileDatePicker
                                                  disabled={
                                                    Boolean(authStore.tokenData?.role === 'user') ||
                                                    Boolean(searchParams.get('view'))
                                                  }
                                                  label={i18nStore.dictionary.endDate}
                                                  value={handleFieldValue(i, j, 'limitDate') ?? new Date()}
                                                  onChange={(e) => handleValueChange(e, 'limitDate', i, j)}
                                                  renderInput={(params) => <TextField {...params} />}
                                                />
                                              </Grid>
                                              <Grid
                                                item
                                                xs={6}
                                                sx={{
                                                  textAlign: 'left',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                <Chip
                                                  label={generalStore.getStatusTranslated(
                                                    assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                      .competenceValues[j].developmentStatus || 'pending'
                                                  )}
                                                  color={generalStore.getBackgroundByStatus(
                                                    assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                      .competenceValues[j].developmentStatus || 'pending'
                                                  )}
                                                  size="small"
                                                />

                                                {assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                  .competenceValues[j].developmentStatus !== 'done' && (
                                                  <Stack direction="row" alignItems="center" spacing={2} sx={{ ml: 2 }}>
                                                    {authStore.tokenData?.role === 'evaluator' &&
                                                      assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                        .competenceValues[j].developmentFile && (
                                                        <ButtonLoader
                                                          component="label"
                                                          variant="outlined"
                                                          loadingText={i18nStore.dictionary.loading}
                                                          area={`mark-development-done-${i}-${j}`}
                                                          text={
                                                            <>
                                                              <CheckCircleOutlineIcon
                                                                sx={{ mr: 1 }}
                                                                color={'success'}
                                                              />
                                                              {i18nStore.dictionary.validate}
                                                            </>
                                                          }
                                                          onClick={async () => {
                                                            const assessmentCompetenceValueEvidence = {
                                                              user: assessmentStore.assessmentEvaluationForm!.user,
                                                              assessment:
                                                                assessmentStore.assessmentEvaluationForm!.assessment,
                                                              competence: i,
                                                              competenceValue: j,
                                                            }

                                                            await trackPromise(
                                                              assessmentStore.validateEvidence(
                                                                assessmentCompetenceValueEvidence
                                                              ),
                                                              `mark-development-done-${i}-${j}`
                                                            )

                                                            action(() => {
                                                              assessmentStore.assessmentEvaluationForm!.answersManager[
                                                                i
                                                              ].competenceValues[j].developmentStatus = 'done'
                                                            })()

                                                            toastStore.pushToast({
                                                              message: i18nStore.dictionary.evidenceValidated,
                                                              type: 'success',
                                                            })
                                                          }}
                                                        />
                                                      )}

                                                    {authStore.tokenData?.role === 'user' && (
                                                      <>
                                                        <ButtonLoader
                                                          component="label"
                                                          variant="outlined"
                                                          loadingText={i18nStore.dictionary.loading}
                                                          area={`add-evidence-${i}-${j}`}
                                                          text={
                                                            <>
                                                              <FileUploadOutlinedIcon sx={{ mr: 2 }} />{' '}
                                                              {assessmentStore.assessmentEvaluationForm!.answersManager[
                                                                i
                                                              ].competenceValues[j].developmentStatus !== 'waiting'
                                                                ? i18nStore.dictionary.addEvidence
                                                                : i18nStore.dictionary.replaceFile}
                                                              <input
                                                                onChange={async (e) => {
                                                                  const assessmentCompetenceValueEvidence = {
                                                                    user: assessmentStore.assessmentEvaluationForm!
                                                                      .user,
                                                                    assessment:
                                                                      assessmentStore.assessmentEvaluationForm!
                                                                        .assessment,
                                                                    competence: i,
                                                                    competenceValue: j,
                                                                  }

                                                                  if (e.target.files && e.target.files.length > 0) {
                                                                    const evidenceFile = e.target.files[0]
                                                                    const allowedExt = [
                                                                      'image/jpeg',
                                                                      'image/png',
                                                                      'application/pdf',
                                                                    ]
                                                                    const fileSizeInBytes = evidenceFile.size
                                                                    const fileSizeInKB = fileSizeInBytes / 1024
                                                                    const fileSizeInMB = fileSizeInKB / 1024

                                                                    if (
                                                                      Math.round(fileSizeInMB) <= 1 &&
                                                                      allowedExt.includes(evidenceFile.type)
                                                                    ) {
                                                                      await trackPromise(
                                                                        assessmentStore.addEvidenceFile(
                                                                          assessmentCompetenceValueEvidence,
                                                                          evidenceFile
                                                                        ),
                                                                        `add-evidence-${i}-${j}`
                                                                      )

                                                                      action(() => {
                                                                        assessmentStore.assessmentEvaluationForm!.answersManager[
                                                                          i
                                                                        ].competenceValues[j].developmentFile =
                                                                          evidenceFile
                                                                      })()

                                                                      toastStore.pushToast({
                                                                        message: i18nStore.dictionary.evidenceAttached,
                                                                        type: 'success',
                                                                      })
                                                                    } else {
                                                                      toastStore.pushToast({
                                                                        message: i18nStore.dictionary.exceededMaxSize,
                                                                        type: 'error',
                                                                      })
                                                                    }
                                                                  }
                                                                }}
                                                                hidden
                                                                accept="image/*, .pdf"
                                                                type="file"
                                                              />
                                                            </>
                                                          }
                                                        />
                                                        <IconButton
                                                          sx={{ alignSelf: 'flex-start' }}
                                                          onClick={() => {
                                                            action(() => {
                                                              modalStore.openModal(
                                                                <>
                                                                  <Typography variant="body2">
                                                                    {i18nStore.dictionary.maxFileSize}: 1MB
                                                                  </Typography>
                                                                  <Typography variant="body2">
                                                                    {i18nStore.dictionary.allowedFormats}: jpg, png, pdf
                                                                  </Typography>
                                                                </>,
                                                                { size: 'xs' }
                                                              )
                                                            })()
                                                          }}
                                                        >
                                                          <HelpOutlineIcon fontSize="small" color="primary" />
                                                        </IconButton>
                                                      </>
                                                    )}
                                                  </Stack>
                                                )}
                                                {assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                  .competenceValues[j].developmentFile && (
                                                  <Typography
                                                    variant="body2"
                                                    sx={{
                                                      ml: 2,
                                                      fontWeight: 700,
                                                      color: 'primary.main',
                                                      cursor: 'pointer',
                                                      textDecoration: 'underline',
                                                    }}
                                                    onClick={() => {
                                                      const file =
                                                        assessmentStore.assessmentEvaluationForm!.answersManager[i]
                                                          .competenceValues[j].developmentFile
                                                      if (file && typeof file !== 'string') {
                                                        const blob = new Blob([file], {
                                                          type: 'application/octet-stream',
                                                        })
                                                        const url = URL.createObjectURL(blob)

                                                        const link = document.createElement('a')
                                                        link.href = url
                                                        link.setAttribute('download', `evidence_file.${file.name}`)
                                                        link.click()

                                                        URL.revokeObjectURL(url)
                                                      } else if (file && typeof file === 'string') {
                                                        const link = document.createElement('a')
                                                        link.href =
                                                          process.env.REACT_APP_API + `/uploads/evidence/${file}`
                                                        link.setAttribute('download', `${file}`)
                                                        link.setAttribute('target', '_blank')
                                                        link.click()
                                                      }
                                                    }}
                                                  >
                                                    {i18nStore.dictionary.attachment}
                                                  </Typography>
                                                )}
                                              </Grid>
                                            </Fragment>
                                          )}
                                      </Fragment>
                                    )}
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            )
                          })}
                        </Grid>
                      </Grid>
                    )
                  )
                })}

              {showSuccess && (
                <AssessmentEvaluateUserSuccess step={searchParams.get('step')} view={searchParams.get('view')} />
              )}
            </Grid>
          )}
        </ContentArea>
      </Fragment>
    )
  )
}

export default observer(AssessmentEvaluateUserPage)
