import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import { Fragment, useContext } from 'react'
import WebsiteNavBar from '../../components/navbar/website-navbar'
import { StoreContext } from '../../stores/store.context'
import { observer } from 'mobx-react-lite'
import Footer from '../../components/footer'
import UserAsssessmentReport from '../../assets/images/user-assessment-report.png'
import CompetenceReport from '../../assets/images/competence-report.png'
import AssessmentStep from '../../assets/images/assessment-step.png'

const AboutPage: React.FC = () => {
  const { i18nStore, themeStore } = useContext(StoreContext)

  return (
    <Fragment>
      <WebsiteNavBar />

      <Container maxWidth="md" sx={{ position: 'relative' }}>
        <Box
          sx={{
            background: `linear-gradient(to right, #12192f, ${themeStore.theme.palette.primary.main})`,
            width: 'calc(100vw)',
            marginLeft: 'calc(-50vw + 50%)',
            display: 'flex',
            paddingY: 4,
            alignItems: 'center',
            mb: 5,
          }}
        >
          <Container maxWidth="md">
            <Typography variant="h2" color="primary.contrastText" fontWeight={400} sx={{ maxWidth: '335px' }}>
              {i18nStore.dictionary.bannerText05}{' '}
              <Box display="inline" fontWeight={700} color="primary.main">
                {i18nStore.dictionary.bannerText06}{' '}
              </Box>
              {i18nStore.dictionary.bannerText07}{' '}
              <Box display="inline" fontWeight={700} color="primary.main">
                {i18nStore.dictionary.bannerText08}
              </Box>
            </Typography>
          </Container>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <Typography
              variant="h4"
              sx={{ color: 'primary.main', textDecoration: 'underline', textUnderlineOffset: '3px' }}
            >
              {i18nStore.dictionary.performanceManagement}
            </Typography>
            <Typography variant="subtitle2" sx={{ maxWidth: '420px', mt: 2 }}>
              {i18nStore.dictionary.performanceManagementText}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Box component="img" src={UserAsssessmentReport} />
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 12 }}>
          <Grid item xs={12} sm={8}>
            <Box component="img" src={CompetenceReport} />
          </Grid>

          <Grid item xs={12} sm={4} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <Typography
              variant="h4"
              sx={{ color: 'primary.main', textDecoration: 'underline', textUnderlineOffset: '3px' }}
            >
              {i18nStore.dictionary.behavioralManagement}
            </Typography>
            <Typography variant="subtitle2" sx={{ maxWidth: '420px', mt: 2 }}>
              {i18nStore.dictionary.behavioralManagementText}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ mt: 12 }}>
          <Grid item xs={12} sm={4} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <Typography
              variant="h4"
              sx={{ color: 'primary.main', textDecoration: 'underline', textUnderlineOffset: '3px' }}
            >
              {i18nStore.dictionary.performanceDevelopment}
            </Typography>
            <Typography variant="subtitle2" sx={{ maxWidth: '420px', mt: 2 }}>
              {i18nStore.dictionary.performanceDevelopmentText}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Box component="img" src={AssessmentStep} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Fragment>
  )
}

export default observer(AboutPage)
