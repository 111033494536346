import { makeAutoObservable } from 'mobx'
import { ToastStore } from './toast.store'
import { Achievement } from '../models/achievement.model'
import { AchievementService } from '../services/achievement.service'

export class AchievementStore {
  private _achievements: Array<Achievement> | null = null

  toastStore: ToastStore

  constructor(toastStore: ToastStore) {
    makeAutoObservable(this)
    this.toastStore = toastStore
  }

  getAchievements = async (): Promise<void> => {
    try {
      const achievementService = new AchievementService()
      const achievements = await achievementService.getAchievements()
      this.achievements = achievements
    } catch (e: any) {
      this.achievements = null
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: 'error' })
      }
    }
  }

  get achievements() {
    return this._achievements
  }

  set achievements(achievements: Array<Achievement> | null) {
    this._achievements = achievements
  }
}
