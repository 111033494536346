import {
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { StoreContext } from '../../../stores/store.context'

interface AddCompetenceStep3Props {
  step: number
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: `0px 1px 2px -1px ${theme.palette.grey[100]}, 0px 1px 2px 0px ${theme.palette.grey[500]}, 0px 1px 10px 0px ${theme.palette.grey[400]} !important`,
}))

const AddCompetenceStep3: React.FC<AddCompetenceStep3Props> = (props: AddCompetenceStep3Props) => {
  const { competenceStore, i18nStore } = useContext(StoreContext)

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="h2">{i18nStore.dictionary.addCompetenceStep3Title}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Typography variant="h4">{i18nStore.dictionary.competence}:</Typography>
        <Typography variant="body1" sx={{ ml: 2 }}>
          {competenceStore.competenceFormData!.name![i18nStore.language]}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {competenceStore.competenceFormData && competenceStore.competenceFormData.competenceValues && (
          <Grid container spacing={1} sx={{ mt: 2, overflow: 'auto', maxHeight: '50vh' }}>
            <StyledTableContainer>
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{i18nStore.dictionary.values}</TableCell>
                    <TableCell>{i18nStore.dictionary.allowCertification}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {competenceStore.competenceFormData.competenceValues.map((competenceValue, index) => {
                    return (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {competenceValue.name[i18nStore.language]}
                        </TableCell>
                        <TableCell>
                          {competenceValue.allowCertification ? i18nStore.dictionary.yes : i18nStore.dictionary.no}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default observer(AddCompetenceStep3)
