import { useContext } from 'react'
import { StoreContext } from '../../../stores/store.context'
import { observer } from 'mobx-react-lite'
import { View, StyleSheet, Document, Page, Text } from '@react-pdf/renderer'

interface AssessmentPDFProps {
  data: any
}

const AssessmentPDF: React.FC<AssessmentPDFProps> = (props: AssessmentPDFProps) => {
  const styles = StyleSheet.create({
    page: { backgroundColor: 'white' },
    section: { margin: 30 },
  })

  const { i18nStore } = useContext(StoreContext)

  return (
    <Document>
      {props.data && (
        <Page size="A4" style={styles.page} wrap={false}>
          <View style={{ ...styles.section, textAlign: 'center' }}>
            <Text style={{ fontSize: '28px', color: '#1976d2' }}>
              {props.data.user.firstName + ' ' + props.data.user.lastName}
            </Text>
          </View>

          <View style={{ ...styles.section }}>
            {props.data.answersManager.map((answer: any, i: number) => {
              return (
                <View key={i} style={{ marginBottom: '10px' }} wrap={false}>
                  <Text
                    style={{
                      fontSize: '20px',
                      color: '#1976d2',
                      marginBottom: '10px',
                      borderBottom: '1px solid #9e9e9e',
                    }}
                  >
                    {answer.competence.name[i18nStore.language]}
                  </Text>
                  {answer.competence.competenceValues.map((competenceValue: any, j: any) => {
                    return (
                      <View style={{ marginBottom: '10px' }} key={j}>
                        <Text style={{ fontSize: '14px', color: 'black', marginLeft: '10px' }}>
                          {competenceValue.name[i18nStore.language]}
                        </Text>
                        {answer.competenceValues.map((competenceValueAnswer: any, k: number) => {
                          return (
                            competenceValueAnswer.competenceValue.toString() === competenceValue._id?.toString() && (
                              <View key={k} style={{ marginBottom: '10px' }}>
                                <Text
                                  style={{ fontSize: '10px', color: 'black', marginLeft: '20px', marginTop: '5px' }}
                                >
                                  {i18nStore.dictionary.reached}: {competenceValueAnswer.reached}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: '10px',
                                    color: 'black',
                                    marginLeft: '20px',
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                  }}
                                >
                                  {i18nStore.dictionary.expected}: {competenceValueAnswer.expected}
                                </Text>
                                <Text style={{ fontSize: '10px', color: 'black', marginLeft: '20px' }}>
                                  {i18nStore.dictionary.priority}: {competenceValueAnswer.priority}
                                </Text>
                                <Text
                                  style={{ fontSize: '10px', color: 'black', marginLeft: '20px', marginTop: '5px' }}
                                >
                                  {i18nStore.dictionary.assessmentSuggestionLabel}:{' '}
                                  {competenceValueAnswer.suggestion !== ''
                                    ? competenceValueAnswer.suggestion
                                    : i18nStore.dictionary.uninformed}
                                </Text>
                                <Text
                                  style={{ fontSize: '10px', color: 'black', marginLeft: '20px', marginTop: '5px' }}
                                >
                                  {i18nStore.dictionary.development}:{' '}
                                  {competenceValueAnswer.development && competenceValueAnswer.development !== ''
                                    ? competenceValueAnswer.development.title[i18nStore.language]
                                    : i18nStore.dictionary.uninformed}
                                </Text>
                              </View>
                            )
                          )
                        })}
                      </View>
                    )
                  })}
                </View>
              )
            })}
          </View>
        </Page>
      )}
    </Document>
  )
}

export default observer(AssessmentPDF)
