import { makeAutoObservable } from 'mobx'
import { TenantData } from '../models/tenant.model'
import { TenantService } from '../services/tenant.service'
import { ToastStore } from './toast.store'
import { Positions } from '../models/positions.model'
import { Developments } from '../models/developments.model'

export class TenantStore {
  private _tenant: TenantData | null = null
  private _positions: Positions[] | null = null
  private _developments: Developments[] | null = null
  toastStore: ToastStore

  constructor(toastStore: ToastStore) {
    makeAutoObservable(this)
    this.toastStore = toastStore
  }

  async getTenantById(id: string): Promise<any> {
    try {
      const tenantService = new TenantService()
      const tenant = await tenantService.getTenantById(id)
      this.tenant = tenant
    } catch (e: any) {
      this.toastStore.pushToast({
        message: e.toString(),
        type: 'error',
      })
      throw e.response.data
    }
  }

  async getTenantPositions(id: string): Promise<any> {
    try {
      const tenantService = new TenantService()
      const positions = await tenantService.getTenantPositions(id)
      this.positions = positions
    } catch (e: any) {
      this.toastStore.pushToast({
        message: e.toString(),
        type: 'error',
      })
      throw e.response.data
    }
  }

  async getTenantDevelopments(id: string): Promise<any> {
    try {
      const tenantService = new TenantService()
      const developments = await tenantService.getTenantDevelopments(id)
      this.developments = developments
    } catch (e: any) {
      this.toastStore.pushToast({
        message: e.toString(),
        type: 'error',
      })
      throw e.response.data
    }
  }

  async saveSettings(): Promise<boolean> {
    try {
      let saved = false
      if (this.tenant) {
        const tenantService = new TenantService()
        const tenant = await tenantService.saveSettings(this.tenant)
        this.tenant = tenant
        saved = true
        return saved
      }

      return saved
    } catch (e: any) {
      this.toastStore.pushToast({
        message: e.toString(),
        type: 'error',
      })
      throw e.response.data
    }
  }

  async addTenantPosition(tenantId: string, newPosition: string): Promise<Positions> {
    try {
      const tenantService = new TenantService()
      const tenant = await tenantService.addTenantPosition(tenantId, newPosition)
      return tenant
    } catch (e: any) {
      this.toastStore.pushToast({
        message: e.toString(),
        type: 'error',
      })
      throw e.response.data
    }
  }

  async deleteTenantPosition(tenantId: string, positionId: string): Promise<Positions> {
    try {
      const tenantService = new TenantService()
      const tenant = await tenantService.deleteTenantPosition(tenantId, positionId)
      return tenant
    } catch (e: any) {
      this.toastStore.pushToast({
        message: e.toString(),
        type: 'error',
      })
      throw e.response.data
    }
  }

  set tenant(tenantData: TenantData | null) {
    this._tenant = tenantData
  }

  get tenant() {
    return this._tenant
  }

  set positions(positions: Positions[] | null) {
    this._positions = positions
  }

  get positions() {
    return this._positions
  }

  set developments(developments: Developments[] | null) {
    this._developments = developments
  }

  get developments() {
    return this._developments
  }
}
