import { Menu, MenuList, MenuItem, ListItemText, IconButton, Link, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { StoreContext } from '../../stores/store.context'
import { MainMenuItem } from '../../models/menu.model'

interface MainMenuSubItemProps {
  items: MainMenuItem[]
}

const MainMenuSubItem: React.FC<MainMenuSubItemProps> = (props: MainMenuSubItemProps) => {
  const { i18nStore } = useContext(StoreContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <Fragment>
      <Fragment>
        <IconButton
          onClick={handleClick}
          sx={{ position: 'absolute', width: '100%', padding: 0, display: 'flex', margin: 0, height: '100%' }}
        ></IconButton>
        <Menu
          sx={{
            width: 320,
            maxWidth: '100%',
            '& .MuiPaper-root': {
              backgroundColor: 'background.default',
            },
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuList disablePadding>
            {props.items.map((item, i) => {
              return (
                <MenuItem key={i}>
                  <ListItemText>
                    <Typography
                      variant="body2"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mr: 2,
                      }}
                    >
                      <Link
                        underline="hover"
                        component={RouterLink}
                        to={item.routeLink}
                        onClick={handleClose}
                        color={'text.primary'}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        {item.name[i18nStore.language]}
                      </Link>
                    </Typography>
                  </ListItemText>
                </MenuItem>
              )
            })}
          </MenuList>
        </Menu>
      </Fragment>
    </Fragment>
  )
}

export default observer(MainMenuSubItem)
