import axios from 'axios'
import { TenantData } from '../models/tenant.model'
import { Positions } from '../models/positions.model'
import { Developments } from '../models/developments.model'

export class TenantService {
  async getTenantById(id: string): Promise<TenantData> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/tenant/${id}`)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async getTenantPositions(id: string): Promise<Positions[]> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/tenant/${id}/positions`)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async getTenantDevelopments(id: string): Promise<Developments[]> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/tenant/${id}/developments`)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async saveSettings(tenant: TenantData): Promise<TenantData> {
    try {
      const res = await axios.put(process.env.REACT_APP_API + `/tenant/${tenant._id}`, { tenant })
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async addTenantPosition(tenant: string, position: string): Promise<Positions> {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/tenant/${tenant}/position`, { position })
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async deleteTenantPosition(tenant: string, position: string): Promise<Positions> {
    try {
      const res = await axios.delete(process.env.REACT_APP_API + `/tenant/${tenant}/position/${position}`)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }
}
