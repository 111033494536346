const DictionaryPT = {
  manageUsers: 'Gerenciar colaboradores',
  manageUsersSubtitle: 'Gerencie seus colaboradores',
  showing: 'Exibindo',
  of: 'De',
  loadMore: 'Carregar mais',
  loading: 'Carregando',
  searchByUserName: 'Pesquise por nome ou cargo do funcionário',
  inviteEmployee: 'Convidar colaborador',
  requiredField: 'Campo obrigatório',
  invalidEmail: 'E-mail inválido',
  fillNewEmployeeDataTitle: 'Preencha os dados do novo colaborador',
  nameLabel: 'Nome',
  namePlaceholder: 'Informe o nome',
  lastNameLabel: 'Sobrenome',
  lastNamePlaceholder: 'Informe o sobrenome',
  emailPlaceholder: 'Informe o e-mail corporativo do colaborador',
  emailLabel: 'E-mail',
  sendInvite: 'Enviar convite',
  role: 'Função',
  inviteSended: 'Convite enviado com sucesso!',
  invalidDomain: 'Domínio inválido',
  user: 'Usuário',
  evaluator: 'Avaliador',
  delete: 'Excluir',
  areYouSureDeleteUser: 'Tem certeza de que deseja deletar esse usuário?',
  cannotBeUndoneAction: 'Essa ação não pode ser desfeita',
  noBack: 'Não, voltar',
  userDeleted: 'Usuário deletado com sucesso',
  statusNew: 'Novo',
  statusActive: 'Ativo',
  statusDraft: 'Rascunho',
  statusPending: 'Pendente',
  statusInvoiced: 'Faturado',
  resendActivationLink: 'Enviar link de ativação',
  activationLinkSended: 'Link de ativação enviado com sucesso',
  welcome: 'Seja bem vindo',
  activateAccountMessage: 'Para ativar sua conta você deve definir sua senha',
  passwordMustContainUppercase: 'Deve conter ao menos uma letra maiúscula',
  passwordMustContainNumber: 'Deve conter ao menos um número',
  passwordMustContainSpecialChar: 'Deve conter ao menos um caractere especial',
  passwordMustContainSixChars: 'Deve conter ao menos 6 caracteres',
  passwordPlaceholder: 'Digite sua senha',
  passwordLabel: 'Senha',
  confirmPasswordPlaceholder: 'Digite novamente sua senha',
  confirmPasswordLabel: 'Confirmação de senha',
  passwordDontMatch: 'Senhas não coincidem',
  invalidToken: 'Token inválido',
  activate: 'Ativar',
  userActivated: 'Usuário ativado com sucesso',
  redirecting: 'Redirecionando',
  notFoundData: 'Nenhum registro encontrado',
  leaving: 'Saindo',
  manageCompetencies: 'Gerenciar competências',
  manageCompetenciesSubtitle: 'Gerencie suas competências',
  searchByCompetenceName: 'Pesquisa pelo nome da competência',
  addCompetence: 'Adicionar competência',
  startAddingUser: 'Comece adicionando um novo usuário',
  startAddingCompetence: 'Comece adicionando uma nova competência',
  save: 'Salvar',
  fillNewCompetenceData: 'Preencha os dados da nova competência',
  competenceNamePTLabel: 'Nome da competência (PT)',
  competenceNamePTPlaceholder: 'Informe o nome a ser apresentado em Português',
  competenceNameENLabel: 'Nome da competência (EN)',
  competenceNameENPlaceholder: 'Informe o nome a ser apresentado em Inglês',
  competenceNameESLabel: 'Nome da competência (ES)',
  competenceNameESPlaceholder: 'Informe o nome a ser apresentado em Espanhol',
  competenceDescriptionPTLabel: 'Breve descritivo da competência (PT)',
  competenceDescriptionPTPlaceholder: 'Digite um resumo da competência a ser apresentado em Português',
  competenceDescriptionENLabel: 'Breve descritivo da competência (EN)',
  competenceDescriptionENPlaceholder: 'Digite um resumo da competência a ser apresentado em Inglês',
  competenceDescriptionESLabel: 'Breve descritivo da competência (ES)',
  competenceDescriptionESPlaceholder: 'Digite um resumo da competência a ser apresentado em Espanhol',
  next: 'Próximo',
  back: 'Voltar',
  saveDraft: 'Salvar rascunho',
  addCompetenceStep1Title: 'Informações básicas',
  addCompetenceStep2Title: 'Valores',
  addCompetenceStep3Title: 'Resumo',
  edit: 'Editar',
  publish: 'Publicar',
  draftSaved: 'Rascunho salvo',
  pleaseFillCurrentCompetenceValues: 'Por favor informe os dados da competência atual antes de adicionar uma nova',
  competence: 'Competência',
  values: 'Valores',
  competencePublished: 'Competência publicada com sucesso',
  manageAssessments: 'Gerenciar avaliações',
  manageAssessmentsSubtitle: 'Crie avaliações para seu time',
  createAssessment: 'Criar nova avaliação',
  searchByAssessmentName: 'Procurar pelo nome da avaliação',
  startAddingAssessment: 'Comece criando uma nova avaliação',
  addAssessmentStep1Title: 'Informações básicas',
  addAssessmentStep2Title: 'Competências',
  addAssessmentStep3Title: 'Colaboradores',
  addAssessmentStep4Title: 'Resumo',
  fillNewAssessmentData: 'Preencha os dados da nova avaliação',
  assessmentNamePTLabel: 'Nome da avaliação (PT)',
  assessmentNamePTPlaceholder: 'Nome que será apresentado em Português',
  assessmentNameENLabel: 'Nome da avaliação (EN)',
  assessmentNameENPlaceholder: 'Nome que será apresentado em Inglês',
  assessmentNameESLabel: 'Nome da avaliação (ES)',
  assessmentNameESPlaceholder: 'Nome que será apresentado em Espanhol',
  endDate: 'Data limite',
  informCompetences: 'Adicione as competências que irão compor a avaliação',
  confirm: 'Confirmar',
  selectCompetences: 'Selecione as competências',
  selectAll: 'Selecionar todos',
  informUsers: 'Adicione os usuários que poderão responder à pesquisa',
  addUser: 'Adicionar usuário',
  totalUsers: 'Total de usuários',
  totalCompetencies: 'Total de competências',
  selectUsers: 'Selecione os usuários',
  assessment: 'Avaliação',
  competencies: 'Competências',
  users: 'Usuários',
  assessmentPublished: 'Avaliação publicada com sucesso',
  myAssessments: 'Minhas avaliações',
  myAssessmentsSubtitle: 'Selecione uma das avaliações disponiveis',
  start: 'Iniciar',
  limiteDate: 'Data limite para envio',
  statusNotStarted: 'Não iniciado',
  statusInProgress: 'Em progresso',
  statusDone: 'Finalizado',
  continue: 'Continuar',
  noAssessmentFound: 'Nenhuma avaliação encontrada',
  selfEvaluation: 'Auto avaliação',
  reached: 'Atingido',
  expected: 'Esperado',
  priority: 'Prioridade',
  finish: 'Finalizar',
  backAssessments: 'Voltar para avaliações',
  view: 'Visualizar',
  managerEvaluation: 'Avaliação gestor',
  summary: 'Resumo',
  noNotifications: 'Nenhuma notificação encontrada',
  markAllAsRead: 'Marcar todas como lido',
  followLink: 'Seguir link',
  markAsRead: 'Marcar como lido',
  markAsUnread: 'Marcar como não lido',
  welcomeMessage: 'Olá, seja bem vindo',
  evaluate: 'Avaliar',
  areYouSuredeleteAssessment: 'Tem certeza de que deseja apagar essa avaliação?',
  allAnswersWillBeDeleted: 'Todas as respostas e o progresso dos usuários também serão apagados',
  selfEvaluationDone: 'Autoavaliação finalizada com sucesso',
  selfEvaluationDoneMessage: 'Uma notificação foi enviada para o seu gestor para que proceda com a próxima etapa.',
  managerEvaluationDone: 'Avaliação finalizada',
  managerEvaluationDoneMessage: 'Uma notificação foi enviada para o colaborador',
  statusWaiting: 'Aguardando',
  evaluationDone: 'Avaliação finalizada',
  asssessmentDeleted: 'Avaliação removida com sucesso',
  usersWaitingEvaluation: 'Usuários aguardando avaliação',
  viewAll: 'Ver todos',
  employee: 'Colaborador',
  lastUpdate: 'Última atualização',
  status: 'Situação',
  showingLastSix: 'Exibindo os últimos 6 registros',
  waitingEvaluations: 'Aguardando avaliações',
  allowCertification: 'Permite certificação',
  yes: 'Sim',
  no: 'Não',
  competenceDeleted: 'Competência removida com sucesso',
  areYouSureDeleteCompetence: 'Tem certeza de que deseja apagar essa competência?',
  hasCertification: 'Possui certificação?',
  validity: 'Validade',
  pendingAssessments: 'Avaliações pendentes',
  billingInfo: 'Informações de faturamento',
  manageBillingInfo: 'Gerencie informações de faturamento',
  billingDate: 'Data faturamento',
  value: 'Valor',
  profile: 'Perfil',
  keepYourDataUpdated: 'Mantenha seus dados atualizados',
  dataUpdated: 'Dados atualizados',
  update: 'Atualizar',
  profileImageUpdated: 'Imagem de perfil atualizada',
  changeImage: 'Alterar imagem',
  failedToSendMail: 'Falha ao enviar e-mail',
  instructionsResetPasswordSendByEmail: 'Instruções para recuperação de senha enviada por e-mail',
  ok: 'OK',
  resetPasswordTitle: 'Insira seu email e enviaremos um link com os passos para redefinir sua senha',
  send: 'Enviar',
  informEmail: 'Informe o e-mail',
  setNewPasswordMessage: 'Siga as instruções abaixo para redefinir sua senha',
  reset: 'Redefinir',
  passwordReseted: 'Senha atualizada com sucesso',
  welcomeTitle: 'Seja bem-vindo à plataforma de Mapeamento de Competências',
  hello: 'Olá',
  loginSubtitle:
    'Identifique quais as aptidões técnicas e comportamentais dentro de sua empresa. Reconheça os  profissionais alinhados às expectativas e retenha talentos',
  forgotMyPassword: 'Esqueci minha senha',
  rememberAccess: 'Lembrar acesso',
  signIn: 'Entrar',
  authenticating: 'Autenticando',
  bannerText01: 'Foque em seu potencial de',
  bannerText02: 'crescimento',
  bannerText03: 'Trabalhe seus pontos fracos e exiba suas',
  bannerText04: 'habilidades',
  bannerText05: 'Sua empresa possuí pessoas muito',
  bannerText06: 'talentosas',
  bannerText07: 'e nós o ajudaremos a',
  bannerText08: 'encontrá-las',
  statusFeedback: 'Feedback',
  waitingFeedback: 'Aguardando feedback',
  evaluationFeedbackDone: 'Feedback finalizado',
  feedbackDoneMessage: 'Etapa de feedback finalizada',
  waitingManager: 'Aguardando avaliador',
  evaluationinProgress: 'Avaliação em progresso',
  backAssessmentSummary: 'Voltar para resumo da avaliação',
  stepFinished: 'Etapa finalizada com sucesso',
  selfEvaluationinProgress: 'Autoavaliação em progresso',
  administrator: 'Administrador',
  settings: 'Configurações',
  systemSettings: 'Configurações da plataforma',
  allowEvaluatorsCreateAndDeleteCompetencies: 'Permite que avaliadores criem e apaguem suas próprias competências',
  allowEvaluatorsInviteAndDeleteUsers:
    'Permite que avaliadores convidem usuários para sua equipe, tal como apagar seus usuários',
  allowEvaluatorsCreateAndDeleteAssessments: 'Permite que avaliadores criem e apaguem suas próprias avaliações',
  settingsSaved: 'Configurações salvas',
  createdBy: 'Criado por',
  createdAt: 'Criado em',
  howItWorks: 'Como funciona',
  inviteEmployess: 'Convide os colaboradores',
  howItWorks01: 'Com apenas alguns dados você pode convidar os usuários da empresa para participarem',
  createCompetencies: 'Crie as competências',
  howItWorks02: 'Adicione as competências que serão avaliadas',
  createAssessmentCycle: 'Crie ciclos de avaliação',
  howItWorks03:
    'Agora basta criar um ciclo de avaliação, informar quais competências serão avaliadas nessa avaliação e quem poderá responder',
  selfAssessmentStep: 'Etapa de auto avaliação',
  howItWorks04:
    'Agora os usuários já podem responder as avaliações que foram disponibilizadas e se autoaliarem em cada competência',
  assessmentStep: 'Etapa de avaliação',
  howItWorks05: 'Cada gestor recebe as auto avaliações completadas do seu time e prossegue para a etapa de avaliação',
  feedbackStep: 'Etapa de feedback',
  howItWorks06:
    'Por fim o gestor e seu subordinado se reuniem para a etapa de feedback, e assim finalizam a etapa de avaliação',
  dataStudyAvailable: 'Estude os dados',
  howItWorks07:
    'Com os dados em mãos fica mais fácil tomar descisões e entender as necessidades de cada colaborador, seus pontos fracos e fortes. Identifique as aptidões técnicas e comportamentais dentro de sua empresa. Reconheça os  profissionais alinhados às expectativas e retenha talentos',
  achievements: 'Conquistas',
  about: 'Sobre',
  login: 'Entrar',
  accountedUsers: 'Usuários contabilizados',
  backHome: 'Voltar para página principal',
  assessmentSuggestionPlaceholder:
    'Ex.: Fazer um curso profissionalizante;Visitar clientes;Treinamento portal LMS da empresa',
  assessmentSuggestionLabel: 'Sugestão',
  doesNotDemonstrate: 'Não demonstra',
  basic: 'Básico',
  intermediate: 'Intermediário',
  advanced: 'Avançado',
  expert: 'Especialista',
  mastery: 'Domínio',
  high: 'Alta',
  medium: 'Média',
  low: 'Baixa',
  areYouSureFinishAssessment: 'Tem certeza de que deseja finalizar essa avaliação?',
  asssessmentFinished: 'Avaliação finalizada com sucesso',
  noMoreAvailableToUsers: 'A mesma não estará mais disponível para os usuários',
  yesContinue: 'Sim, continuar',
  weAreInClosedBeta: 'Nós estamos em etapa de beta fechado',
  knowMore: 'Saiba mais',
  closedBetaMessage01: 'No momento nós estamos fazendo um',
  closedBetaMessage02: 'beta fechado',
  closedBetaMessage03: 'para alguns de nossos clientes',
  alphaMessage01: 'Uma versão',
  alphaMessage02: 'Alpha',
  alphaMessage03: 'foi lançada',
  roadMapMessage01: 'Primeiro',
  roadMapMessage02: 'Roadmap',
  developmentProcessMenssage01: 'Prototipagem',
  developmentProcessMenssage02: 'e desenvolvimento do primeiro',
  developmentProcessMenssage03: 'MVP',
  timeLine: 'Timeline',
  closedBetaEndMessage01: 'Fim do',
  closedBetaEndMessage02: 'beta fechado',
  oficialReleaseMessage01: 'Lançamento da',
  oficialReleaseMessage02: 'primeira versão',
  oficialReleaseMessage03: 'estável',
  knowHow: 'Saiba como',
  stayTuned: 'Fique atento à novidades',
  newsEmailPlaceholder: 'Informe seu endereço de e-mail',
  thanksNewsletter: 'Obrigado por se cadastrar! Enviaremos novidades no e-mail cadastrado',
  privacyPolicy: 'Política de privacidade',
  poweredBy: 'Desenvolvido por',
  reportUserAssessmentTitle: 'Avaliações do colaborador',
  reportUserAssessmentSubtitle: 'Avalie o estado do colaborador na avaliação selecionada',
  generateReport: 'Gerar relatório',
  userReachedReportLabel: 'Atingido: Usuário',
  managerReachedReportLabel: 'Atingido: Gestor',
  lastAccess: 'Último acesso',
  generatePDF: 'Gerar PDF',
  competenceUpdated: 'Competência atualizada com sucesso',
  competenceAlreadyInUseMessage:
    'Essa competência já está em uso por uma ou mais pesquisas, por tanto não é possível adicionar novos valores',
  uninformed: 'Não informado',
  reportCompetenciesTitle: 'Relatório de competências',
  reportCompetenciesSubtitle: 'Veja o resultado da competência selecionada de todos usuários',
  version: 'Versão',
  performanceManagement: 'Gestão de desempenho',
  behavioralManagement: 'Gestão Comportamental',
  performanceDevelopment: 'Gestão de performance',
  performanceManagementText:
    'Tenha em mãos uma forma simples de análise de metas e competências de sua equipe. Através de avaliações cíclicas é possível fazer o acompanhamento do time e controle do desempenho ao longo de um período.',
  behavioralManagementText:
    'Compreenda o comportamento dos colaboradores da organização e aproveite ao máximo suas habilidades. Identifique padrões de comportamento e coloque as pessoas nos lugares e funções adequados, aumentando a satisfação e reduzindo o turnover.',
  performanceDevelopmentText:
    'Baseado em indicadores de desempenho é possível fazer o diagnóstico organizacional e alinhamento de  expectativas contínuos com seus colaboradores. Através do gerenciamento das habilidades e competências dos profissionais priorize os resultados alcançados e a eficiência dos processos.',
  deleteUserMessage:
    'Todos os dados relacionados a esse usuário serão removidos, avaliações, respostas, competências criadas, avaliações em que foi vinculado, etc.',
  transferUserData: 'Transferir dados do usuário',
  selectNewEvaluator: 'Selecione um novo avaliador',
  editEvaluatorWithLinkedUsers:
    'Antes de modificar o acesso desse usuário é necessário transferir seus dados enquanto avaliador para outro usuário com o mesmo acesso',
  assessmentExpired: 'Avaliação expirada',
  weAreLive: 'Estamos no ar!',
  startFeedback: 'Iniciar feedback',
  usersAdded: 'Usuários adicionados com sucesso',
  waitingEvaluator: 'Aguardando avaliador',
  position: 'Cargo',
  addNewPosition: 'Adicionar novo cargo',
  addPositionLabel: 'Informe o novo cargo',
  addedPosition: 'Cargo adicionado com sucesso',
  areYouSureDeletePosition: 'Tem certeza de que deseja remover esse cargo?',
  positionDeleted: 'Cargo removido com sucesso!',
  development: 'Desenvolvimento',
  notApplicable: 'Não se aplica',
  addEvidence: 'Adicionar evidência',
  replaceFile: 'Substituir arquivo',
  validate: 'Validar',
  evidenceValidated: 'Evidência validada com sucesso',
  evidenceAttached: 'Evidência anexada com sucesso',
  pricing: 'Preços',
  until: 'Até',
  contactUs: 'Entre em contato',
  supportPricingStarter: 'Suporte via e-mail',
  supportPricingPro: 'Suporte via whatsapp, telefone e e-mail',
  supportPricingEnterprise: 'Suporte via whatsapp, telefone e e-mail',
  infrastructurePricingStarter: 'Servidor compartilhado',
  infrastructurePricingPro: 'Servidor compartilhado',
  infrastructurePricingEnterprise: 'Servidor dedicado',
  requestQuote: 'Solicite um orçamento',
  month: 'Mês',
  storage: 'Armazenamento',
  exceededMaxSize: 'Excedeu o tamanho máximo',
  attachment: 'Anexo',
  maxFileSize: 'Tamanho máximo permitido',
  allowedFormats: 'Formatos permitidos',
  confirmAction: "Pressione enter ou clique no '+' para confirmar",
  exportExcel: 'Exportar excel',
}

export default DictionaryPT
