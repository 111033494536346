import {
  Box,
  Typography,
  Grid,
  Autocomplete,
  CircularProgress,
  TextField,
  debounce,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import ContentArea from '../../components/content-area'
import { StoreContext } from '../../stores/store.context'
import BreadCrumb from '../../components/breadcrumb'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import ButtonLoader from '../../components/button-loader'
import { AssessmentSteps } from '../../models/assessment.model'
import {
  Chart as ChartJS,
  RadialLinearScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
} from 'chart.js'
import { Pie, Radar } from 'react-chartjs-2'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

ChartJS.register(
  RadialLinearScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  CategoryScale,
  Filler,
  Tooltip,
  Legend
)

interface ChartData {
  competence: string
  legend: string
  labels: Array<string>
  datasets: [
    {
      label: string
      data: Array<number>
      backgroundColor: string
      borderColor: string
      borderWidth: number
    }
  ]
}

const ReportAssessmentPage: React.FC = () => {
  const { i18nStore, assessmentStore, authStore, reportStore, generalStore, themeStore, breadcrumbStore } =
    useContext(StoreContext)
  const [openAssessments, setOpenAssessments] = useState(false)
  const [selectedAssessment, setAssessment] = useState<string | null>(null)
  const [assessmentInputValue, setAssessmentInputValue] = useState('')
  const [openUsers, setOpenUsers] = useState(false)
  const [selectedUser, setUser] = useState<string | null>(null)
  const [userInputValue, setUserInputValue] = useState('')
  const [charts, setCharts] = useState<Array<ChartData>>([])
  const { promiseInProgress } = usePromiseTracker({ area: 'loading-assessments' })

  const fetchAssessmentsOnType = useMemo(
    () =>
      debounce((request: { input: string }) => {
        if (authStore.tokenData) {
          var filter = JSON.stringify({
            name: request.input,
            status: 'active|done',
            lang: i18nStore.language,
          })

          getAssessments(filter)
        }
      }, 1000),
    []
  )

  const getAssessments = async (filter: any) => {
    await trackPromise(
      assessmentStore.listAssessmentsByTenant(
        authStore.tokenData!.tenant,
        parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
        parseInt(process.env.REACT_APP_DATA_LIMIT as string),
        'createdAt',
        'desc',
        filter
      ),
      'loading-Assessments'
    )
  }

  useEffect(() => {
    breadcrumbStore.setBreadcrumbItems([
      {
        name: i18nStore.dictionary.reportUserAssessmentTitle,
        link: '/secure/reports/assessments',
      },
    ])

    reportStore.reportAssessmentUser = null
    getAssessments({})
  }, [])

  const getPieChart = (chartData: any) => {
    let labels = chartData.datasets.map((data: any) => data.label)
    let values = chartData.datasets.map((data: any) => data.data[0])

    const newData = {
      labels: labels,
      datasets: [
        {
          label: chartData.legend,
          data: values,
          backgroundColor: ['#ed6c0275', '#388e3c75', '#f4433675', '#1976d275'],
          hoverOffset: 4,
        },
      ],
    }

    return (
      <Pie
        height={300}
        options={{
          responsive: true,
          interaction: {
            mode: 'index' as const,
            intersect: false,
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: false,
              beginAtZero: true, // Start the y-axis from zero
              ticks: {
                stepSize: 1, // Set the step size to 1 to show all integer values
              },
            },
          },
        }}
        data={newData}
      />
    )
  }

  const generateLevelsDatasets = () => {
    if (reportStore.reportAssessmentUser) {
      reportStore.reportAssessmentUser.answers.forEach((answer) => {
        let labels: Array<string> = []
        let data: Array<number> = []

        answer.competence.competenceValues.forEach((competenceValue) => {
          // labels.push(competenceValue.name[i18nStore.language] as string)

          const maxLength = 20
          if ((competenceValue.name[i18nStore.language] as string).length > maxLength) {
            labels.push((competenceValue.name[i18nStore.language] as string).substring(0, maxLength) + '...')
          } else {
            labels.push(competenceValue.name[i18nStore.language] as string)
          }

          answer.competenceValues.forEach((competenceValueAnswer) => {
            if (competenceValueAnswer.competenceValue.toString() === competenceValue._id?.toString()) {
              data.push(competenceValueAnswer.reached)
            }
          })
        })

        setCharts((charts) => {
          let newValue: ChartData = {
            competence: answer.competence._id,
            legend: answer.competence.name[i18nStore.language] as string,
            labels: labels,
            datasets: [
              {
                label: i18nStore.dictionary.userReachedReportLabel,
                data: data,
                backgroundColor: '#1976d275',
                borderColor: themeStore.theme.palette.primary.main,
                borderWidth: 1,
              },
            ],
          }
          charts.push(newValue)
          return charts
        })
      })

      if (reportStore.reportAssessmentUser.answersManager) {
        reportStore.reportAssessmentUser.answersManager.forEach((answer) => {
          let reachedLevels: Array<number> = []
          let expectedLevels: Array<number> = []
          let priorityLevels: Array<number> = []

          answer.competence.competenceValues.forEach((competenceValue) => {
            answer.competenceValues.forEach((competenceValueAnswer) => {
              if (competenceValueAnswer.competenceValue.toString() === competenceValue._id?.toString()) {
                if (competenceValueAnswer.reached) {
                  reachedLevels.push(competenceValueAnswer.reached)
                }

                if (competenceValueAnswer.expected) {
                  expectedLevels.push(competenceValueAnswer.expected)
                }

                if (competenceValueAnswer.priority) {
                  priorityLevels.push(competenceValueAnswer.priority)
                }
              }
            })
          })

          setCharts((charts) => {
            charts.forEach((chart) => {
              if (chart.competence.toString() === answer.competence._id.toString()) {
                const newDatasetReached = {
                  label: i18nStore.dictionary.managerReachedReportLabel,
                  data: reachedLevels,
                  backgroundColor: '#ed6c0275',
                  borderColor: themeStore.theme.palette.warning.main,
                  borderWidth: 1,
                }

                const newDatasetExpected = {
                  label: i18nStore.dictionary.expected,
                  data: expectedLevels,
                  backgroundColor: '#388e3c75',
                  borderColor: themeStore.theme.palette.success.main,
                  borderWidth: 1,
                }

                const newDatasetPriority = {
                  label: i18nStore.dictionary.priority,
                  data: priorityLevels,
                  backgroundColor: '#f4433675',
                  borderColor: themeStore.theme.palette.error.main,
                  borderWidth: 1,
                }

                chart.datasets.push(newDatasetReached)
                chart.datasets.push(newDatasetExpected)
                chart.datasets.push(newDatasetPriority)
              }
            })
            return charts
          })
        })
      }
    }
  }

  const getSuggestions = (competenceId: string) => {
    if (reportStore.reportAssessmentUser && reportStore.reportAssessmentUser.answersManager) {
      return reportStore.reportAssessmentUser.answersManager.map((answers) => {
        if (competenceId === answers.competence._id.toString()) {
          return answers.competenceValues.map((competenceValue, i) => {
            return answers.competence.competenceValues.map((value) => {
              if (value._id?.toString() === competenceValue.competenceValue.toString()) {
                return competenceValue.suggestion ? (
                  <Typography variant="body1" sx={{ mb: 2 }} key={i}>
                    {value.name[i18nStore.language]}: {competenceValue.suggestion}
                  </Typography>
                ) : (
                  <Typography variant="body1" sx={{ mb: 2 }} key={i}>
                    {value.name[i18nStore.language]}: N/A
                  </Typography>
                )
              }
            })
          })
        }
      })
    }
  }

  const onGenerateReport = async () => {
    if (selectedAssessment && selectedUser) {
      await trackPromise(reportStore.generateReportAssessmentUser(selectedAssessment, selectedUser), 'generate-report')

      setCharts([])
      generateLevelsDatasets()
    }
  }

  return (
    <Fragment>
      <Box>
        <BreadCrumb />
        <Typography variant="h2">{i18nStore.dictionary.reportUserAssessmentTitle}</Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {i18nStore.dictionary.reportUserAssessmentSubtitle}
        </Typography>
      </Box>

      <ContentArea>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Autocomplete
              id="assessments-autocomplete"
              open={openAssessments}
              onOpen={() => setOpenAssessments(true)}
              onClose={() => setOpenAssessments(false)}
              onKeyUp={(e) => fetchAssessmentsOnType({ input: (e.target as any).value })}
              onChange={(event, newValue, reason) => {
                setAssessment(newValue ? newValue._id : null)
                if (reason === 'clear') {
                  getAssessments({})
                  return
                }
              }}
              onInputChange={(event, newInputValue) => setAssessmentInputValue(newInputValue)}
              inputValue={assessmentInputValue}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              includeInputInList
              getOptionLabel={(option: any) => option.name[i18nStore.language]}
              options={assessmentStore.assessments ? assessmentStore.assessments.paginatedData : []}
              loading={promiseInProgress}
              renderInput={(params) => (
                <>
                  <TextField
                    name="assessment"
                    {...params}
                    label={i18nStore.dictionary.assessment}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {promiseInProgress ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                </>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            {selectedAssessment && (
              <Autocomplete
                id="users-autocomplete"
                open={openUsers}
                onOpen={() => setOpenUsers(true)}
                onClose={() => setOpenUsers(false)}
                onChange={(event, newValue, reason) => setUser(newValue ? newValue.user._id : null)}
                onInputChange={(event, newInputValue) => setUserInputValue(newInputValue)}
                inputValue={userInputValue}
                isOptionEqualToValue={(option, value) => option.user._id === value.user._id}
                includeInputInList
                getOptionLabel={(option: any) => option.user.firstName + ' ' + option.user.lastName}
                options={
                  assessmentStore.assessments
                    ? assessmentStore.assessments.paginatedData.filter((value) => value._id === selectedAssessment)[0]
                        .users
                    : []
                }
                renderInput={(params) => (
                  <>
                    <TextField
                      name="user"
                      {...params}
                      label={i18nStore.dictionary.user}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  </>
                )}
              />
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={2}
            display="flex"
            justifyContent="end"
            alignItems={'center'}
            sx={{ marginLeft: 'auto' }}
          >
            <ButtonLoader
              variant="contained"
              onClick={onGenerateReport}
              disabled={!selectedUser}
              loadingText={i18nStore.dictionary.loading}
              text={i18nStore.dictionary.generateReport}
              area="generate-report"
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>

        {reportStore.reportAssessmentUser && (
          <Grid container spacing={4} columnSpacing={1} sx={{ my: 6 }}>
            <Grid item xs={12} textAlign={'center'}>
              <Typography variant="h4">
                {reportStore.reportAssessmentUser.assessment.name[i18nStore.language]} -{' '}
                {reportStore.reportAssessmentUser.user.firstName + ' ' + reportStore.reportAssessmentUser.user.lastName}
              </Typography>
            </Grid>

            <Grid item xs={12} textAlign={'center'}>
              <Chip
                label={assessmentStore.getStepLabel(
                  reportStore.reportAssessmentUser.assessment.users[0].step as AssessmentSteps
                )}
                color={'warning'}
                size="small"
              />
              <Chip
                label={generalStore.getStatusTranslated(reportStore.reportAssessmentUser.assessment.users[0].progress)}
                color={generalStore.getBackgroundByStatus(
                  reportStore.reportAssessmentUser.assessment.users[0].progress
                )}
                size="small"
                sx={{ ml: 4 }}
              />
            </Grid>

            <Grid container spacing={2} sx={{ mt: 4 }}>
              {charts && charts.length > 0 ? (
                charts.map((chart, i) => {
                  return (
                    <Grid item xs={4} key={i}>
                      <Typography variant="h4" textAlign={'center'} sx={{ mb: 2 }}>
                        {chart.legend}
                      </Typography>

                      {chart.labels.length > 2 ? (
                        <Radar
                          data={chart}
                          options={{
                            plugins: {
                              legend: {
                                align: 'center',
                              },
                            },
                            scales: {
                              r: {
                                max: 5,
                                min: 0,
                                angleLines: {
                                  color: themeStore.themeMode === 'dark' ? 'white' : themeStore.theme.palette.grey[500],
                                },
                                grid: {
                                  color: themeStore.themeMode === 'dark' ? 'white' : themeStore.theme.palette.grey[500],
                                },
                                ticks: {
                                  stepSize: 1,
                                  backdropColor: themeStore.theme.palette.warning.main,
                                  backdropPadding: 4,
                                  color: 'white',
                                },
                                pointLabels: {
                                  font: {
                                    size: 11,
                                  },
                                },
                              },
                            },
                          }}
                        />
                      ) : (
                        // <Bar
                        //   height={300}
                        //   options={{
                        //     responsive: true,
                        //     interaction: {
                        //       mode: 'index' as const,
                        //       intersect: false,
                        //     },
                        //     scales: {
                        //       x: {
                        //         stacked: true,
                        //       },
                        //       y: {
                        //         stacked: false,
                        //         beginAtZero: true, // Start the y-axis from zero
                        //         ticks: {
                        //           stepSize: 1, // Set the step size to 1 to show all integer values
                        //         },
                        //       },
                        //     },
                        //   }}
                        //   data={chart}
                        // />

                        getPieChart(chart)
                      )}
                      <Accordion sx={{ mt: 2 }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon color="primary" />}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          {i18nStore.dictionary.assessmentSuggestionLabel}
                        </AccordionSummary>
                        <AccordionDetails>{getSuggestions(chart.competence)}</AccordionDetails>
                      </Accordion>
                      {/* <Typography variant="subtitle1">{}</Typography> */}
                    </Grid>
                  )
                })
              ) : (
                <Grid item xs={12} textAlign={'center'}>
                  <Typography variant="body1">{i18nStore.dictionary.notFoundData}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </ContentArea>
    </Fragment>
  )
}

export default observer(ReportAssessmentPage)
