import { AppBar, Box, Container, Toolbar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import PageLoader from '../components/page-loader'
import { useLocation } from 'react-router-dom'
import ThemeSwitcher from '../components/theme-switcher'
import LanguageSwitcher from '../components/language-switcher'

const NotAuthenticatedLayout: React.FC = () => {
  const currentPath = useLocation().pathname
  return (
    <Fragment>
      <PageLoader />
      {currentPath !== '/login' &&
      currentPath !== '/' &&
      currentPath !== '/time-line' &&
      currentPath !== '/pricing' &&
      currentPath !== '/about' ? (
        <Fragment>
          <AppBar
            position="static"
            sx={{
              backgroundColor: 'background.default',
            }}
          >
            <Container maxWidth="md">
              <Toolbar disableGutters>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: 'none', md: 'flex' },
                  }}
                ></Box>

                <Box
                  sx={{
                    flexGrow: 1,
                    justifyContent: 'right',
                    display: { xs: 'none', md: 'flex' },
                  }}
                >
                  <ThemeSwitcher />
                  <LanguageSwitcher />
                </Box>
              </Toolbar>
            </Container>
          </AppBar>

          <Container maxWidth="md">
            <Box sx={{ mt: 5 }}>
              <Outlet />
            </Box>
          </Container>
        </Fragment>
      ) : (
        <Outlet />
      )}
    </Fragment>
  )
}

export default observer(NotAuthenticatedLayout)
