/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../stores/store.context'
import BreadCrumb from '../../components/breadcrumb'
import ContentArea from '../../components/content-area'
import ButtonLoader from '../../components/button-loader'
import { ModalOpts } from '../../models/modal.model'
import NotFoundData from '../../components/not-found'
import { SearchOutlined } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import { Assessment } from '../../models/assessment.model'
import AssessmentForm from './forms/assessment-form'
import AssessmentMenu from './menus/assessment-menu'
import { RolesEnum } from '../../enums/roles.enum'
import { UserData } from '../../models/user.model'
import PageLoader from '../../components/page-loader'
import { trackPromise } from 'react-promise-tracker'
import GridSwitcher from '../../components/grid-switcher'
import UserMenu from '../manage-users/menus/user-menu'

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: `0px 2px 5px -1px ${theme.palette.grey[300]}, 0px 1px 5px 0px ${theme.palette.grey[200]}, 0px 1px 15px 0px ${theme.palette.grey[100]}`,
}))

const ManageAssessmentsPage: React.FC = () => {
  const [search, setSearch] = useState('')
  const [tempSearch, setTempSearch] = useState('')
  const [gridView, setGridView] = useState<string>('card')

  const { assessmentStore, authStore, breadcrumbStore, i18nStore, modalStore, generalStore, tenantStore } =
    useContext(StoreContext)

  const searchByAssessmentName = (event: any) => setSearch(event.target.value as string)

  useEffect(() => {
    breadcrumbStore.setBreadcrumbItems([
      {
        name: i18nStore.dictionary.manageAssessments,
        link: '/secure/manage-assessments',
      },
    ])
    const loadAssessmentsByTenant = async () => {
      if (authStore.tokenData) {
        var filter = JSON.stringify({
          name: search,
          lang: i18nStore.language,
        })

        await trackPromise(
          assessmentStore.listAssessmentsByTenant(
            authStore.tokenData.tenant,
            assessmentStore.assessmentsListPage,
            parseInt(process.env.REACT_APP_DATA_LIMIT as string),
            'createdAt',
            'desc',
            filter
          ),
          'page-loader'
        )
      }
    }

    loadAssessmentsByTenant()
  }, [authStore, assessmentStore, breadcrumbStore, i18nStore.dictionary, search, assessmentStore.assessmentsListPage])

  return (
    <Fragment>
      <PageLoader />
      <Box>
        <BreadCrumb />
        <Typography variant="h2">{i18nStore.dictionary.manageAssessments}</Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {i18nStore.dictionary.manageAssessmentsSubtitle}
        </Typography>
      </Box>

      <ContentArea>
        <Grid container>
          <Grid container display="flex" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={5} display="flex">
              <TextField
                label={i18nStore.dictionary.searchByAssessmentName}
                value={tempSearch}
                onChange={(e) => setTempSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') searchByAssessmentName(e)
                }}
                fullWidth
                placeholder={i18nStore.dictionary.searchByAssessmentName}
                InputProps={{
                  startAdornment: (
                    <IconButton color="primary" onClick={() => setSearch(tempSearch)}>
                      <SearchOutlined />
                    </IconButton>
                  ),
                  endAdornment: tempSearch && (
                    <IconButton
                      onClick={() => {
                        setSearch('')
                        setTempSearch('')
                      }}
                    >
                      <CancelIcon sx={{ color: 'primary.main' }} />
                    </IconButton>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <GridSwitcher onChangeGridView={(e) => setGridView(e)} />
            </Grid>

            {tenantStore.tenant &&
              authStore.tokenData &&
              (tenantStore.tenant.settings.allowEvaluatorsCreateAndDeleteAssessments ||
                authStore.tokenData.role === RolesEnum.administrator) && (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  display="flex"
                  sx={{ justifyContent: { xs: 'center', sm: 'end' }, mt: { xs: 2, sm: 0 } }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      const modalOpts: ModalOpts = {
                        size: 'md',
                      }
                      modalStore.openModal(<AssessmentForm />, modalOpts)
                    }}
                  >
                    {i18nStore.dictionary.createAssessment}
                  </Button>
                </Grid>
              )}
          </Grid>

          <Grid container sx={{ my: 3 }}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {assessmentStore.assessments && (
            <Grid container spacing={2}>
              {gridView === 'card' && (
                <>
                  {assessmentStore.assessments.paginatedData.map((assessment: Assessment, index: number) => {
                    return (
                      <Grid item xs={12} sm={4} key={index}>
                        <StyledCard>
                          <CardHeader
                            action={<AssessmentMenu assessment={assessment} />}
                            title={
                              <Typography
                                variant="h5"
                                sx={{
                                  minHeight: '40px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: 'vertical',
                                  lineClamp: 2,
                                  boxOrient: 'vertical',
                                  webkitBoxPack: 'start',
                                  justifyContent: 'flex-start',
                                }}
                              >
                                {assessment.name[i18nStore.language]}
                              </Typography>
                            }
                            subheader={
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography color="text.primary" variant="caption">
                                    {i18nStore.dictionary.createdAt}:
                                  </Typography>
                                  <Typography variant="caption" color="text.primary" sx={{ ml: 1 }}>
                                    {new Date(assessment.createdAt).toLocaleDateString(i18nStore.locale, {
                                      hour: 'numeric',
                                      minute: 'numeric',
                                    })}
                                  </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <Typography color="text.primary" variant="caption">
                                    {i18nStore.dictionary.createdBy}:{' '}
                                  </Typography>
                                  <Typography color="text.primary" variant="body2" sx={{ ml: 1 }}>
                                    {(assessment.createdBy as UserData).firstName +
                                      ' ' +
                                      (assessment.createdBy as UserData).lastName}
                                  </Typography>
                                </Box>
                              </Box>
                            }
                          />
                          <Divider />
                          <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Typography variant="subtitle2">{i18nStore.dictionary.endDate}: </Typography>
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {new Date(assessment.endDate!).toLocaleDateString(i18nStore.locale)}
                              </Typography>
                            </Box>
                          </CardContent>
                          <CardActions disableSpacing>
                            <Chip
                              label={generalStore.getStatusTranslated(assessment.status)}
                              color={generalStore.getBackgroundByStatus(assessment.status)}
                              size="small"
                              sx={{ marginLeft: 'auto' }}
                            />
                          </CardActions>
                        </StyledCard>
                      </Grid>
                    )
                  })}
                </>
              )}

              {gridView === 'table' && (
                <TableContainer>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{i18nStore.dictionary.assessment}</TableCell>
                        <TableCell>{i18nStore.dictionary.createdAt}</TableCell>
                        <TableCell>{i18nStore.dictionary.endDate}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {assessmentStore.assessments.paginatedData.map((assessment: Assessment, index: number) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="body2">{assessment.name[i18nStore.language]}</Typography>
                            </TableCell>

                            <TableCell>
                              <Typography variant="body2">
                                {new Date(assessment.createdAt).toLocaleDateString('pt-BR', {
                                  hour: 'numeric',
                                  minute: 'numeric',
                                })}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="body2">
                                {new Date(assessment.endDate!).toLocaleDateString(i18nStore.locale)}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <AssessmentMenu assessment={assessment} />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {assessmentStore.assessments.totalPages === 0 && (
                <Grid item xs={12}>
                  <NotFoundData
                    children={
                      tenantStore.tenant &&
                      authStore.tokenData &&
                      (tenantStore.tenant.settings.allowEvaluatorsCreateAndDeleteAssessments ||
                        authStore.tokenData.role === RolesEnum.administrator) ? (
                        <Button
                          sx={{ mt: 2 }}
                          variant="text"
                          onClick={() => {
                            const modalOpts: ModalOpts = {
                              size: 'md',
                            }
                            modalStore.openModal(<AssessmentForm />, modalOpts)
                          }}
                        >
                          {i18nStore.dictionary.startAddingAssessment}
                        </Button>
                      ) : (
                        <></>
                      )
                    }
                  />
                </Grid>
              )}

              {assessmentStore.assessments.totalPages > 0 && (
                <Grid container sx={{ mt: 6, display: 'flex', alignItems: 'center' }}>
                  <Grid item xs={12} sm={3} display="flex" justifyContent="center" sx={{ marginLeft: 'auto' }}>
                    <ButtonLoader
                      variant="contained"
                      onClick={() => (assessmentStore.assessmentsListPage = assessmentStore.assessmentsListPage + 1)}
                      disabled={assessmentStore.assessments.totalPages === assessmentStore.assessmentsListPage}
                      loadingText={i18nStore.dictionary.loading}
                      text={i18nStore.dictionary.loadMore}
                      area="load-more"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={5}
                    sx={{ display: 'flex', mt: { xs: 2, sm: 0 }, justifyContent: { xs: 'center', sm: 'end' } }}
                  >
                    <Typography component="span" variant="subtitle2">
                      {i18nStore.dictionary.showing}
                    </Typography>
                    <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
                      {assessmentStore.assessments.paginatedData.length}
                    </Typography>
                    <Typography component="span" variant="subtitle2" sx={{ textTransform: 'lowercase' }}>
                      {i18nStore.dictionary.of}
                    </Typography>
                    <Typography component="span" variant="subtitle2" sx={{ ml: 1 }}>
                      {assessmentStore.assessments.totalItems}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </ContentArea>
    </Fragment>
  )
}

export default observer(ManageAssessmentsPage)
