import { makeAutoObservable } from 'mobx'
import { AuthService } from '../services/auth.service'
import { LoginRequestDto } from '../dto/request/login-request.dto'
import { ToastStore } from './toast.store'
import { TokenData } from '../models/token.model'
import { MainMenu } from '../models/menu.model'
import { Roles } from '../models/roles.model'
import jwt_decode from 'jwt-decode'
import { I18nStore } from './i18n.store'
import { UserStore } from './user.store'

export class AuthStore {
  private _authenticated: boolean = false
  private _forbidenText: string = '403 | Forbiden'
  private _tokenData: TokenData | undefined
  private _menu: MainMenu | null = { name: '', items: [] }
  private _toastStore: ToastStore | null
  private _i18nStore: I18nStore | null
  private _userStore: UserStore | null
  private _hasAccess: boolean = true
  private _moduleAccess: Array<string> | null = null

  constructor(i18nStore: I18nStore, userStore?: UserStore, toastStore?: ToastStore) {
    makeAutoObservable(this)
    this._authenticated = !this.isTokenExpired()
    this._toastStore = toastStore ? toastStore : null
    this._i18nStore = i18nStore ? i18nStore : null
    this._userStore = userStore ? userStore : null
  }

  login = async (loginRequest: LoginRequestDto): Promise<void> => {
    try {
      const authService = new AuthService()
      const token = await authService.login(loginRequest)
      localStorage.setItem('_cmtk', token)
      this.authenticated = true
      this.tokenData = jwt_decode(token)
    } catch (e: any) {
      this.authenticated = false
      if (this._toastStore) {
        this._toastStore.pushToast({ message: e.toString(), type: 'error' })
      }
    }
  }

  getMenu = async (role: Roles): Promise<void> => {
    try {
      const authService = new AuthService()
      const menu = await authService.getMenu(role)
      this.menu = menu

      if (this.menu) {
        let modules: Array<string> = this.menu.items.map((item) => {
          if (item.routeLink) {
            return item.routeLink
          }

          return ''
        })

        this.menu.items.forEach((item) => {
          if (item.subItems) {
            item.subItems.forEach((subItem) => {
              if (subItem.routeLink) {
                modules.push(subItem.routeLink)
              }
            })
          }
        })

        /// Everyone has access
        modules.push('/secure/user/profile')
        modules.push('/secure/user/achievements')

        modules = modules.filter((module) => module !== '')

        if (modules) {
          this.moduleAccess = modules
        }
      }
    } catch (e: any) {
      this.menu = null
      if (this._toastStore) {
        this._toastStore.pushToast({ message: e.toString(), type: 'error' })
      }
    }
  }

  getAccessToken = (): string | null | undefined => {
    if (!this.isTokenExpired()) {
      return localStorage.getItem('_cmtk')
    }
    this.authenticated = false
  }

  isTokenExpired = () => {
    var token: string | null = localStorage.getItem('_cmtk')

    if (token) {
      this.tokenData = jwt_decode(token)
      const exp = this._tokenData?.exp
      if (exp) {
        const expirationDatetimeInSeconds = exp * 1000
        return Date.now() >= expirationDatetimeInSeconds
      }

      return true
    }

    return true
  }

  logout = () => {
    localStorage.removeItem('_cmtk')
    this.forbidenText = this._i18nStore!.dictionary.leaving
    this.authenticated = false
    this.tokenData = undefined
    if (this._userStore) {
      this._userStore.user = null
    }
  }

  openMenuSubItem = (i: number, opened: boolean) => (this._menu!.items[i].opened = opened)

  get loggedUserFullName(): string {
    if (this.tokenData) {
      return this.tokenData.firstName + ' ' + this.tokenData.lastName
    }

    return ''
  }

  set menu(menu: MainMenu | null) {
    this._menu = menu
  }

  get menu() {
    return this._menu
  }

  set authenticated(authenticated: boolean) {
    this._authenticated = authenticated
  }

  get authenticated(): boolean {
    return this._authenticated
  }

  set tokenData(tokenData: TokenData | undefined) {
    this._tokenData = tokenData
  }

  get tokenData() {
    return this._tokenData
  }

  set forbidenText(text: string) {
    this._forbidenText = text
  }

  get forbidenText() {
    return this._forbidenText
  }

  get hasAccess() {
    return this._hasAccess
  }

  set hasAccess(hasAccess: boolean) {
    this._hasAccess = hasAccess
  }

  set moduleAccess(modules: Array<string> | null) {
    this._moduleAccess = modules
  }

  get moduleAccess() {
    return this._moduleAccess
  }
}
