import axios from 'axios'
import { Achievement } from '../models/achievement.model'

export class AchievementService {
  async getAchievements() {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/achievement/`)
      const parsedResponse: Array<Achievement> = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }
}
