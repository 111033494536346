import React, { Fragment, useContext, useState } from 'react'
import { Box, Container, IconButton, Link, Toolbar, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../stores/store.context'
import ThemeSwitcher from '../theme-switcher'
import AppBar from '@mui/material/AppBar'
import LanguageSwitcher from '../language-switcher'
import MenuIcon from '@mui/icons-material/Menu'

const WebSiteNavbar: React.FC = () => {
  const { i18nStore } = useContext(StoreContext)
  let [mobileMenu, toggleMobileMenu] = useState<boolean>(false)

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'background.default',
        }}
      >
        <Container maxWidth="md">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <ThemeSwitcher />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                justifyContent: 'right',
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: 2,
                }}
              >
                <Link underline="hover" component={RouterLink} to={'/'} sx={{ fontWeight: 600 }}>
                  Home
                </Link>
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: 2,
                }}
              >
                <Link underline="hover" component={RouterLink} to={'/about'} sx={{ fontWeight: 600 }}>
                  {i18nStore.dictionary.about}
                </Link>
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: 2,
                }}
              >
                <Link underline="hover" component={RouterLink} to={'/time-line'} sx={{ fontWeight: 600 }}>
                  {i18nStore.dictionary.timeLine}
                </Link>
              </Typography>
              {/* <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: 2,
                }}
              >
                <Link underline="hover" component={RouterLink} to={'/pricing'} sx={{ fontWeight: 600 }}>
                  {i18nStore.dictionary.pricing}
                </Link>
              </Typography> */}
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: 2,
                }}
              >
                <Link underline="hover" component={RouterLink} to={'/login'} sx={{ fontWeight: 600 }}>
                  {i18nStore.dictionary.login}
                </Link>
              </Typography>
              <LanguageSwitcher />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton size="large" onClick={() => toggleMobileMenu((mobileMenu = !mobileMenu))} color="primary">
                <MenuIcon />
              </IconButton>

              {mobileMenu && (
                <Fragment>
                  <Box
                    onClick={() => toggleMobileMenu((mobileMenu = !mobileMenu))}
                    sx={{
                      position: 'fixed',
                      height: '100vh',
                      left: 0,
                      top: 0,
                      backgroundColor: 'grey.500',
                      opacity: 0.5,
                      width: '100vw',
                      zIndex: 98,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      position: 'fixed',
                      height: '100vh',
                      left: 0,
                      top: 0,
                      backgroundColor: 'background.default',
                      width: '80vw',
                      zIndex: 99,
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column', px: 6, pt: 6 }}>
                      <LanguageSwitcher />
                    </Box>
                  </Box>
                </Fragment>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}

export default observer(WebSiteNavbar)
