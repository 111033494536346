import { Box, List, ListItem, ListItemText, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { StoreContext } from '../../../stores/store.context'

const LevelsBox: React.FC = () => {
  const { i18nStore } = useContext(StoreContext)

  return (
    <List>
      <ListItem disablePadding>
        <ListItemText
          primary={
            <Typography variant="body1" fontWeight={700} sx={{ display: 'flex' }}>
              <Box component="span" sx={{ width: '25px' }}>
                0:
              </Box>
              <Box component="span" fontWeight={500}>
                {i18nStore.dictionary.doesNotDemonstrate}
              </Box>
            </Typography>
          }
        />
      </ListItem>
      <ListItem disablePadding>
        <ListItemText
          primary={
            <Typography variant="body1" fontWeight={700} sx={{ display: 'flex' }}>
              <Box component="span" sx={{ width: '25px' }}>
                1:
              </Box>
              <Box component="span" fontWeight={500}>
                {i18nStore.dictionary.basic}
              </Box>
            </Typography>
          }
        />
      </ListItem>
      <ListItem disablePadding>
        <ListItemText
          primary={
            <Typography variant="body1" fontWeight={700} sx={{ display: 'flex' }}>
              <Box component="span" sx={{ width: '25px' }}>
                2:
              </Box>
              <Box component="span" fontWeight={500}>
                {i18nStore.dictionary.intermediate}
              </Box>
            </Typography>
          }
        />
      </ListItem>
      <ListItem disablePadding>
        <ListItemText
          primary={
            <Typography variant="body1" fontWeight={700} sx={{ display: 'flex' }}>
              <Box component="span" sx={{ width: '25px' }}>
                3:
              </Box>
              <Box component="span" fontWeight={500}>
                {i18nStore.dictionary.advanced}
              </Box>
            </Typography>
          }
        />
      </ListItem>
      <ListItem disablePadding>
        <ListItemText
          primary={
            <Typography variant="body1" fontWeight={700} sx={{ display: 'flex' }}>
              <Box component="span" sx={{ width: '25px' }}>
                4:
              </Box>
              <Box component="span" fontWeight={500}>
                {i18nStore.dictionary.expert}
              </Box>
            </Typography>
          }
        />
      </ListItem>
      <ListItem disablePadding>
        <ListItemText
          primary={
            <Typography variant="body1" fontWeight={700} sx={{ display: 'flex' }}>
              <Box component="span" sx={{ width: '25px' }}>
                5:
              </Box>
              <Box component="span" fontWeight={500}>
                {i18nStore.dictionary.mastery}
              </Box>
            </Typography>
          }
        />
      </ListItem>
    </List>
  )
}

export default observer(LevelsBox)
