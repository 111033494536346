import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Fragment, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { StoreContext } from '../../../stores/store.context'
import { Box, Button, Checkbox, Divider, FormHelperText, Grid, IconButton, TextField, Typography } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { CompetenceValue } from '../../../models/competence.model'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import DeleteIcon from '@mui/icons-material/Delete'

interface AddCompetenceStep2Props {
  step: number
  edit: boolean
}

const nameIsValid = (competence: CompetenceValue) =>
  competence?.name.pt === '' || competence?.name.en === '' || competence?.name.es === ''

const AddCompetenceStep2: React.FC<AddCompetenceStep2Props> = (props: AddCompetenceStep2Props) => {
  const { i18nStore, competenceStore, themeStore } = useContext(StoreContext)

  useEffect(() => {}, [competenceStore.competenceFormData])

  const initialValues = {
    competenceValues: competenceStore.competenceFormData.competenceValues ?? ([] as CompetenceValue[]),
  }

  const validationSchema = Yup.object().shape({
    competenceValues: Yup.array().of(
      Yup.object().shape({
        name: Yup.object().shape({
          pt: Yup.string().required(i18nStore.dictionary.requiredField),
          es: Yup.string().required(i18nStore.dictionary.requiredField),
          en: Yup.string().required(i18nStore.dictionary.requiredField),
        }),
        allowCertification: Yup.boolean(),
      })
    ),
  })

  const checkCurrentCompetenceValues = (): boolean => {
    // if (competenceStore.competenceFormData.status === 'active') {
    //   return true
    // }

    const currentValues = competenceStore.competenceFormData!.competenceValues!.at(-1)
    if (currentValues) return nameIsValid(currentValues)

    return false
  }

  const getCompetenceName = (index: number) => {
    const competenceRow = competenceStore.competenceFormData!.competenceValues![index]
    if (competenceRow.name[i18nStore.language] !== '') {
      return competenceRow.name[i18nStore.language]
    }

    if (i18nStore.language !== 'pt' && competenceRow.name['pt'] !== '') {
      return competenceRow.name['pt']
    }

    if (i18nStore.language !== 'pt' && competenceRow.name['es'] !== '') {
      return competenceRow.name['es']
    }
  }

  const handleRemoveCompetence = (competenceValue: CompetenceValue) => {
    competenceStore.competenceFormData = {
      ...competenceStore.competenceFormData,
      competenceValues: competenceStore.competenceFormData.competenceValues!.filter(
        (obj) => competenceValue._id !== obj._id
      ),
    }
    competenceStore.validateStep(props.step)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={(values) => {}}
    >
      {({ errors, touched, handleBlur, handleChange }) => {
        return (
          <Form>
            <Fragment>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  color="primary"
                  variant="text"
                  disabled={checkCurrentCompetenceValues()}
                  onClick={() => {
                    const competenceValuesAdd = {
                      name: { pt: '', en: '', es: '' },
                      description: { pt: '', en: '', es: '' },
                      showMore: false,
                      allowCertification: false,
                    }
                    competenceStore.addCompetenceValue(competenceValuesAdd)

                    if (competenceStore.competenceFormData) {
                      const competenceValues = competenceStore.competenceFormData.competenceValues
                      if (competenceValues!.length > 0) {
                        const previousIndex = competenceValues!.indexOf(competenceValues!.slice(-2, -1)[0])
                        if (
                          typeof competenceValues!.at(-2)?.showMore !== 'undefined' &&
                          !competenceValues!.at(-2)?.showMore
                        ) {
                          competenceStore.toggleRow(previousIndex)
                        }
                      }
                    }

                    competenceStore.validateStep(1)
                  }}
                >
                  <AddCircleIcon />
                  <Typography variant="body2" sx={{ ml: 2 }}>
                    {i18nStore.dictionary.addCompetence}
                  </Typography>
                </Button>
                {checkCurrentCompetenceValues() && competenceStore.competenceFormData.status !== 'active' && (
                  <Typography sx={{ ml: 3 }} color="warning.main" variant="caption">
                    {i18nStore.dictionary.pleaseFillCurrentCompetenceValues}
                  </Typography>
                )}

                {/* {props.edit && competenceStore.competenceFormData.status !== 'draft' && (
                  <Typography sx={{ ml: 3 }} color="warning.main" variant="caption">
                    {i18nStore.dictionary.competenceAlreadyInUseMessage}
                  </Typography>
                )} */}
              </Box>

              <Divider sx={{ mt: 2 }} />

              {competenceStore.competenceFormData && competenceStore.competenceFormData.competenceValues && (
                <Grid container sx={{ mt: 2, overflow: 'auto', maxHeight: '50vh' }}>
                  {competenceStore.competenceFormData.competenceValues.map((competenceValue, index) => (
                    <Grid
                      container
                      key={index}
                      sx={{
                        paddingX: 2,
                        paddingY: typeof competenceValue.showMore !== 'undefined' && !competenceValue.showMore ? 2 : 0,
                        borderRadius: '8px',
                        '&:nth-of-type(odd)': {
                          backgroundColor: themeStore.themeMode === 'light' ? 'grey.50' : 'grey.900',
                        },
                        '&:nth-of-type(even)': { backgroundColor: 'default' },
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{ mb: 0 }}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        {!nameIsValid(competenceValue) ? (
                          <CheckCircleIcon sx={{ color: 'success.light' }} fontSize="small" />
                        ) : (
                          <ErrorIcon color="warning" fontSize="small" />
                        )}

                        <Typography variant="h5" sx={{ marginRight: 'auto', marginLeft: 2 }}>
                          {i18nStore.dictionary.competence}: {getCompetenceName(index)}{' '}
                        </Typography>

                        <IconButton onClick={() => competenceStore.toggleRow(index)}>
                          {typeof competenceValue.showMore !== 'undefined' && !competenceValue.showMore ? (
                            <KeyboardArrowDownIcon color="primary" />
                          ) : (
                            <KeyboardArrowUpIcon color="primary" />
                          )}
                        </IconButton>

                        <IconButton
                          // disabled={competenceStore.competenceFormData.status === 'active'}
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleRemoveCompetence(competenceValue)}
                        >
                          <DeleteIcon sx={{ color: 'error.main' }} />
                        </IconButton>
                      </Grid>

                      <Grid
                        container
                        columnSpacing={4}
                        sx={{
                          display:
                            typeof competenceValue.showMore !== 'undefined' && !competenceValue.showMore
                              ? 'flex'
                              : 'none',
                        }}
                      >
                        <Grid item xs={12} sm={4}>
                          <CompetenceTextField
                            field={{ ref: competenceValue.name.pt!, name: 'name', translation: 'pt' }}
                            index={index}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                            label={i18nStore.dictionary.competenceNamePTLabel}
                            placeholder={i18nStore.dictionary.competenceNamePTPlaceholder}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <CompetenceTextField
                            field={{ ref: competenceValue.name.en!, name: 'name', translation: 'en' }}
                            index={index}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                            label={i18nStore.dictionary.competenceNameENLabel}
                            placeholder={i18nStore.dictionary.competenceNameENPlaceholder}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <CompetenceTextField
                            field={{ ref: competenceValue.name.es!, name: 'name', translation: 'es' }}
                            index={index}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            touched={touched}
                            errors={errors}
                            label={i18nStore.dictionary.competenceNameESLabel}
                            placeholder={i18nStore.dictionary.competenceNameESPlaceholder}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                          <Checkbox
                            onChange={(e) => {
                              handleChange(e)
                              competenceValue.allowCertification = !competenceValue.allowCertification
                            }}
                            name={`competenceValues[${index}].allowCertification`}
                            edge="start"
                            sx={{ color: 'primary.main' }}
                            size="small"
                            checked={Boolean(competenceValue.allowCertification)}
                            value={competenceValue.allowCertification}
                            tabIndex={-1}
                            disableRipple
                          />{' '}
                          <Typography variant="body1">{i18nStore.dictionary.allowCertification}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}

              <Box sx={{ mt: 3, paddingX: 2 }}>
                <Typography variant="body2">
                  Total competences: {competenceStore.competenceFormData!.competenceValues!.length}
                </Typography>
              </Box>
            </Fragment>
          </Form>
        )
      }}
    </Formik>
  )
}

export default observer(AddCompetenceStep2)

const CompetenceTextField = (props: {
  field: { ref: string; name: string; translation: string }
  index: number
  handleBlur: any
  handleChange: any
  touched: any
  errors: any
  label: string
  placeholder: string
}) => {
  const { competenceStore } = useContext(StoreContext)
  return (
    <Fragment>
      <TextField
        fullWidth
        type="text"
        value={props.field.ref}
        name={`competenceValues[${props.index}].${props.field.name}.${props.field.translation}`}
        onBlur={async (e) => {
          props.handleBlur(e)
          const newValue = e.target.value
          props.field.ref = newValue
          competenceStore.changeCompetenceValue(props.field.name, props.index, newValue, props.field.translation)
          competenceStore.validateStep(1)
        }}
        onChange={async (e) => {
          props.handleChange(e)
          const newValue = e.target.value
          props.field.ref = newValue
          competenceStore.changeCompetenceValue(props.field.name, props.index, newValue, props.field.translation)
          competenceStore.validateStep(1)
        }}
        placeholder={props.placeholder}
        variant="standard"
        label={props.label}
        inputProps={{
          autoComplete: 'competence-name-pt',
          form: {
            autocomplete: 'off',
          },
        }}
        error={Boolean(
          props.touched.competenceValues &&
            props.touched.competenceValues[props.index] &&
            props.touched.competenceValues[props.index][props.field.name] &&
            props.touched.competenceValues[props.index][props.field.name][props.field.translation] &&
            props.errors.competenceValues &&
            props.errors.competenceValues[props.index] &&
            (props.errors.competenceValues[props.index] as any)[props.field.name][props.field.translation]
        )}
      />
      {props.touched.competenceValues &&
        props.touched.competenceValues[props.index] &&
        props.touched.competenceValues[props.index][props.field.name] &&
        props.touched.competenceValues[props.index][props.field.name]![props.field.translation] &&
        props.errors.competenceValues &&
        props.errors.competenceValues[props.index] &&
        (props.errors.competenceValues[props.index] as any)[props.field.name][props.field.translation] && (
          <FormHelperText error>
            {(props.errors.competenceValues[props.index] as any)[props.field.name][props.field.translation]}
          </FormHelperText>
        )}
    </Fragment>
  )
}
