import { createBrowserRouter } from 'react-router-dom'
import AuthenticatedLayout from '../layout/authenticated-layout'
import DashboardPage from '../pages/dashboard'
import LoginPage from '../pages/login'
import NotFoundPage from '../pages/not-found'
import ManageUsersPage from '../pages/manage-users'
import BillingPage from '../pages/billing'
import ManageCompetenciesPage from '../pages/manage-competencies'
import '../request.interceptor'
import UserActivation from '../pages/user/user-activation'
import NotAuthenticatedLayout from '../layout/not-authenticated-layout'
import ManageAssessmentsPage from '../pages/manage-assessments'
import AssessmentMainPage from '../pages/assessment'
import AssessmentEvaluateUserPage from '../pages/assessment/assessment-evaluate-user'
import AssessmentSummaryPage from '../pages/assessment/assessment-summary'
import UserProfilePage from '../pages/user/user-profile'
import UserSetNewPassword from '../pages/user/user-set-new-password'
import SettingsPage from '../pages/settings'
import UserAchievementsPage from '../pages/user/user-achievements'
import Forbidden from '../pages/forbidden'
import HomePage from '../pages/home'
import ReportAssessmentPage from '../pages/reports/report-assessments'
import Timeline from '../pages/timeline'
import AboutPage from '../pages/about'
import ReportCompetencePage from '../pages/reports/report-competencies'
import PricingPage from '../pages/pricing'

const Router = createBrowserRouter([
  {
    path: '/secure',
    element: <AuthenticatedLayout />,
    children: [
      {
        index: true,
        path: 'dashboard',
        element: <DashboardPage />,
      },
      {
        path: 'billing',
        element: <BillingPage />,
      },
      {
        path: '/secure/settings',
        element: <SettingsPage />,
      },
      {
        path: 'user',
        children: [
          { index: true, path: 'profile', element: <UserProfilePage /> },
          { index: true, path: 'achievements', element: <UserAchievementsPage /> },
        ],
      },
      {
        path: 'assessment',
        children: [
          { index: true, path: '', element: <AssessmentMainPage /> },
          { path: ':id/user/:user/evaluate', element: <AssessmentEvaluateUserPage /> },
          { path: ':id/summary', element: <AssessmentSummaryPage /> },
        ],
      },
      {
        path: 'manage-users',
        children: [
          {
            index: true,
            path: '',
            element: <ManageUsersPage />,
          },
        ],
      },
      {
        path: 'manage-competencies',
        children: [
          {
            index: true,
            path: '',
            element: <ManageCompetenciesPage />,
          },
        ],
      },
      {
        path: 'manage-assessments',
        children: [
          {
            index: true,
            path: '',
            element: <ManageAssessmentsPage />,
          },
        ],
      },
      {
        path: 'reports',
        children: [
          { index: true, path: 'assessments', element: <ReportAssessmentPage /> },
          { path: 'competencies', element: <ReportCompetencePage /> },
        ],
      },
    ],
  },
  {
    path: '/',
    element: <NotAuthenticatedLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: '/about',
        element: <AboutPage />,
      },
      {
        path: '/time-line',
        element: <Timeline />,
      },
      {
        path: '/pricing',
        element: <PricingPage />,
      },
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/user/reset-password',
        element: <UserSetNewPassword />,
      },
      {
        path: '/user/activation/:activationHash',
        element: <UserActivation />,
      },
      {
        path: '/forbiden',
        element: <Forbidden />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
])

export default Router
