/* eslint-disable no-throw-literal */
import { makeAutoObservable } from 'mobx'
import { UserService } from '../services/user.service'
import { ToastStore } from './toast.store'
import { CreateUserRequest, UpdateUserRequest, UserData } from '../models/user.model'
import { Pagination } from '../models/pagination.model'

export class UserStore {
  private _users: Pagination<Array<UserData>> | null = null
  private _evaluators: Pagination<Array<UserData>> | null = null
  private _user: UserData | null = null
  toastStore: ToastStore

  constructor(toastStore: ToastStore) {
    makeAutoObservable(this)
    this.toastStore = toastStore
  }

  async resetPassword(email: string): Promise<any> {
    try {
      const userService = new UserService()
      const passwordReseted = await userService.resetPassword(email)

      if (passwordReseted) {
        return passwordReseted
      }
    } catch (e: any) {
      throw e.response.data.additionalProperties
    }
  }

  async setNewPassword(resetToken: string, password: string, confirmPassword: string): Promise<any> {
    try {
      const userService = new UserService()
      const passwordReseted = await userService.setNewPassword(resetToken, password, confirmPassword)
      if (passwordReseted) {
        return passwordReseted
      }
    } catch (e: any) {
      this.toastStore.pushToast({
        message: e.response.data.additionalProperties.message,
        type: 'error',
      })
    }
  }

  async updateUser(_id: string, user: UpdateUserRequest): Promise<UserData | undefined> {
    try {
      const userService = new UserService()
      const updated = await userService.updateUser(_id, user)
      return updated
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  async changeUserImage(file: File, _id: string): Promise<UserData | undefined> {
    try {
      const userService = new UserService()
      const updated: any = await userService.changeUserImage(file, _id)
      this.user = updated.response
      return updated
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  async listUsersByTenant(
    tenandId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const userService = new UserService()
      const res: Pagination<Array<UserData>> = await userService.listUsersByTenant(
        tenandId,
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      )

      res.paginatedData.map((user) => (user.checked = false))

      if (res && page === 1) {
        this.users = res
      } else {
        this.pushUsers(res.paginatedData)
      }
    } catch (e: any) {
      this.users = null
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  async listEvaluators(
    tenantId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const userService = new UserService()
      const res: Pagination<Array<UserData>> = await userService.listEvaluators(
        tenantId,
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      )

      this.evaluators = res
    } catch (e: any) {
      this.evaluators = null
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  async getUserDataById(_id: string): Promise<void> {
    try {
      const userService = new UserService()
      const res: UserData = await userService.getUserDataById(_id)
      this.user = res
    } catch (e: any) {
      this.user = null
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  async checkActivationToken(token: string): Promise<UserData | undefined> {
    try {
      const userService = new UserService()
      const res: UserData = await userService.checkActivationToken(token)
      return res
    } catch (e: any) {
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  async checkResetToken(token: string): Promise<UserData | undefined> {
    try {
      const userService = new UserService()
      const res: UserData = await userService.checkResetToken(token)
      return res
    } catch (e: any) {
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  async sendInvite(userData: CreateUserRequest): Promise<UserData> {
    try {
      const userService = new UserService()
      const userCreated = await userService.sendInvite(userData)
      return userCreated
    } catch (e: any) {
      this.toastStore.pushToast({
        message: e.toString(),
        type: 'error',
      })

      throw e
    }
  }

  async addNewsletter(email: string, token: string): Promise<boolean> {
    try {
      const userService = new UserService()
      const userAdded = await userService.addNewsletter(email, token)
      return userAdded
    } catch (e: any) {
      this.toastStore.pushToast({
        message: e.toString(),
        type: 'error',
      })

      throw e
    }
  }

  async sendActivationLink(id: string): Promise<UserData> {
    try {
      const userService = new UserService()
      const sended = await userService.sendActivationLink(id)
      return sended
    } catch (e: any) {
      this.toastStore.pushToast({
        message: e.toString(),
        type: 'error',
      })

      throw e
    }
  }

  async deleteUser(id: string): Promise<UserData | undefined> {
    try {
      const userService = new UserService()
      const deleted = await userService.deleteUser(id)
      return deleted
    } catch (e: any) {
      if (this.toastStore) {
        throw { code: e.code, message: e.message }
      }
    }
  }

  async checkEvaluatorLinkedUsers(id: string): Promise<UserData[] | undefined> {
    try {
      const userService = new UserService()
      const users = await userService.checkEvaluatorLinkedUsers(id)
      return users
    } catch (e: any) {
      if (this.toastStore) {
        throw { code: e.code, message: e.message }
      }
    }
  }

  async transferUserData(id: string, newUserId: string, action: string): Promise<UserData | undefined> {
    try {
      const userService = new UserService()
      const deleted = await userService.transferUserData(id, newUserId, action)
      return deleted
    } catch (e: any) {
      if (this.toastStore) {
        throw { code: e.code, message: e.message }
      }
    }
  }

  async activate(id: string, password: string): Promise<UserData | undefined> {
    try {
      const userService = new UserService()
      const activated = await userService.activate(id, password)
      return activated
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  set users(users: Pagination<Array<UserData>> | null) {
    this._users = users
  }

  get users() {
    return this._users
  }

  set evaluators(evaluators: Pagination<Array<UserData>> | null) {
    this._evaluators = evaluators
  }

  get evaluators() {
    return this._evaluators
  }

  get user() {
    return this._user
  }

  set user(user: UserData | null) {
    this._user = user
  }

  updateUsersList = (index: number, newObject: UserData) => {
    if (this._users) {
      this._users.paginatedData[index] = newObject
      this._users = { ...this._users }
    }
  }

  getUserInitials(firstName: string, lastName: string): string {
    if (firstName && firstName !== '' && lastName && lastName !== '') {
      let fullName = firstName + ' ' + lastName
      return fullName
        .match(/(^\S\S?|\b\S)?/g)!
        .join('')
        .match(/(^\S|\S$)?/g)!
        .join('')
        .toUpperCase()
    }

    return ''
  }

  pushUsers = (users: Array<UserData>) => (this._users!.paginatedData = [...this._users!.paginatedData, ...users])
  pushUser = (user: UserData) => (this._users!.paginatedData = [...this._users!.paginatedData, user])
}
