const DictionaryEN = {
  manageUsers: 'Manage users',
  manageUsersSubtitle: 'Manage your users',
  showing: 'Showing',
  of: 'Of',
  loadMore: 'Load more',
  loading: 'Loading',
  searchByUserName: 'Search by employee name or position',
  inviteEmployee: 'Invite employee',
  requiredField: 'Required field',
  invalidEmail: 'Invalid email',
  fillNewEmployeeDataTitle: 'Fill in the details of the new employee',
  nameLabel: 'Name',
  namePlaceholder: 'Enter first name',
  lastNameLabel: 'Last name',
  lastNamePlaceholder: 'Enter the last name',
  emailPlaceholder: "Enter the employee's corporate email",
  emailLabel: 'Email',
  sendInvite: 'Send invite',
  role: 'Role',
  inviteSended: 'Invitation sent successfully!',
  invalidDomain: 'Invalid domain',
  user: 'User',
  evaluator: 'Evaluator',
  delete: 'Delete',
  areYouSureDeleteUser: 'Are you sure you want to delete this user?',
  cannotBeUndoneAction: 'This action cannot be undone.',
  noBack: 'No, back',
  userDeleted: 'User deleted successfully',
  statusNew: 'New',
  statusActive: 'Active',
  statusDraft: 'Draft',
  statusPending: 'Pending',
  statusInvoiced: 'Invoiced',
  resendActivationLink: 'Send activation link',
  activationLinkSended: 'Activation link sent with success',
  welcome: 'Welcome',
  activateAccountMessage: 'To activate your account you must set your password',
  passwordMustContainUppercase: 'Must contain at least one uppercase letter',
  passwordMustContainNumber: 'Must contain at least one number',
  passwordMustContainSpecialChar: 'Must contain at least one special character',
  passwordMustContainSixChars: 'Must contain at least 6 characters',
  passwordPlaceholder: 'Enter your password',
  passwordLabel: 'Password',
  confirmPasswordPlaceholder: 'Retype your password',
  confirmPasswordLabel: 'Password confirmation',
  passwordDontMatch: "Password Don't Match",
  invalidToken: 'Invalid token',
  activate: 'Activate',
  userActivated: 'User activated successfully',
  redirecting: 'Redirecting',
  notFoundData: 'No data found',
  leaving: 'Leaving',
  manageCompetencies: 'Manage competencies',
  manageCompetenciesSubtitle: 'Manage your competencies',
  searchByCompetenceName: 'Search by competence name',
  addCompetence: 'Add competence',
  startAddingUser: 'Start adding a new user',
  startAddingCompetence: 'Start by adding a new competence',
  save: 'Save',
  fillNewCompetenceData: 'Fill in the new competence data',
  competenceNamePTLabel: 'Competence name (PT)',
  competenceNamePTPlaceholder: 'Enter the name to be displayed in Portuguese',
  competenceNameENLabel: 'Competence name (EN)',
  competenceNameENPlaceholder: 'Enter the name to be displayed in English',
  competenceNameESLabel: 'Competence Name (ES)',
  competenceNameESPlaceholder: 'Enter the name to be displayed in Spanish',
  competenceDescriptionPTLabel: 'Short description of competence (PT)',
  competenceDescriptionPTPlaceholder: 'Type a competency summary to be presented in Portuguese',
  competenceDescriptionENLabel: 'Short description of competence (EN)',
  competenceDescriptionENPlaceholder: 'Type a competency summary to be presented in English',
  competenceDescriptionESLabel: 'Short competence description (ES)',
  competenceDescriptionESPlaceholder: 'Type a competency summary to be presented in Spanish',
  next: 'Next',
  back: 'Back',
  saveDraft: 'Save draft',
  addCompetenceStep1Title: 'Basic info',
  addCompetenceStep2Title: 'Values',
  addCompetenceStep3Title: 'Summary',
  edit: 'Edit',
  publish: 'Publish',
  draftSaved: 'Draft saved',
  pleaseFillCurrentCompetenceValues: 'Please enter current competence data before adding a new one',
  competence: 'Competence',
  values: 'Values',
  competencePublished: 'Competence successfully published',
  manageAssessments: 'Manage assessments',
  manageAssessmentsSubtitle: 'Create assessments for your team',
  createAssessment: 'Create new assessment',
  searchByAssessmentName: 'Search by assessment name',
  startAddingAssessment: 'Start by creating a new assessment',
  addAssessmentStep1Title: 'Basic Information',
  addAssessmentStep2Title: 'Competences',
  addAssessmentStep3Title: 'Employes',
  addAssessmentStep4Title: 'Summary',
  fillNewAssessmentData: 'Fill in the new assessment data',
  assessmentNamePTLabel: 'Assessment name (PT)',
  assessmentNamePTPlaceholder: 'Name that will be presented in Portuguese',
  assessmentNameENLabel: 'Assessment name (EN)',
  assessmentNameENPlaceholder: 'Name that will be displayed in English',
  assessmentNameESLabel: 'Assessment name (ES)',
  assessmentNameESPlaceholder: 'Name that will be presented in Spanish',
  endDate: 'End date',
  informCompetences: 'Add the competences that will compose the assessment',
  confirm: 'Confirm',
  selectCompetences: 'Select competencies',
  selectAll: 'Select all',
  informUsers: 'Add users who will be able to respond to the survey',
  addUser: 'Add user',
  totalUsers: 'Total users',
  totalCompetencies: 'Total competencies',
  selectUsers: 'Select users',
  assessment: 'Assessment',
  competencies: 'Competencies',
  users: 'Users',
  assessmentPublished: 'Assessment successfully published',
  myAssessments: 'My assessments',
  myAssessmentsSubtitle: 'Select one of the available assessments',
  start: 'Start',
  limiteDate: 'Deadline date for sending',
  statusNotStarted: 'Not started',
  statusInProgress: 'In progress',
  statusDone: 'Done',
  continue: 'Continue',
  noAssessmentFound: 'No assessment found',
  selfEvaluation: 'Self evaluation',
  reached: 'Reached',
  expected: 'Expected',
  priority: 'Priority',
  finish: 'Finish',
  backAssessments: 'Back to Assessments',
  view: 'View',
  managerEvaluation: 'Manager evaluation',
  summary: 'Summary',
  noNotifications: 'No notifications found',
  markAllAsRead: 'Mark all as read',
  followLink: 'Follow link',
  markAsRead: 'Mark as read',
  markAsUnread: 'Mark as unread',
  welcomeMessage: 'Hello, welcome',
  evaluate: 'Evaluate',
  areYouSuredeleteAssessment: 'Are you sure you want to delete this assessment?',
  allAnswersWillBeDeleted: 'All responses and user progress will also be deleted',
  selfEvaluationDone: 'Self-assessment completed successfully',
  selfEvaluationDoneMessage: 'A notification has been sent to your manager to proceed with the next step',
  managerEvaluationDone: 'Evaluation completed',
  managerEvaluationDoneMessage: 'A notification has been sent to the employee',
  statusWaiting: 'Waiting',
  evaluationDone: 'Assessment done',
  asssessmentDeleted: 'Assessment successfully removed',
  usersWaitingEvaluation: 'Users waiting for evaluation',
  viewAll: 'View all',
  employee: 'Employee',
  lastUpdate: 'Last update',
  status: 'Status',
  showingLastSix: 'Displaying the last 6 records',
  waitingEvaluations: 'Waiting for evaluations',
  allowCertification: 'Allow certification',
  yes: 'Yes',
  no: 'No',
  competenceDeleted: 'Competence successfully removed',
  areYouSureDeleteCompetence: 'Are you sure you want to delete this competency?',
  hasCertification: 'Has certification?',
  validity: 'Validity',
  pendingAssessments: 'Pending assessments',
  billingInfo: 'Billing Information',
  manageBillingInfo: 'Manage billing information',
  billingDate: 'Billing date',
  value: 'Value',
  profile: 'Profile',
  keepYourDataUpdated: 'Keep your data up to date',
  dataUpdated: 'Data updated',
  update: 'Update',
  profileImageUpdated: 'Profile image updated',
  changeImage: 'Change image',
  failedToSendMail: 'Failed to send email',
  instructionsResetPasswordSendByEmail: 'Password recovery instructions sent by email',
  ok: 'OK',
  resetPasswordTitle: "Enter your email and we'll send you a link with steps to reset your password",
  send: 'Send',
  informEmail: 'Enter the email',
  setNewPasswordMessage: 'Follow the instructions below to reset your password',
  reset: 'Reset',
  passwordReseted: 'Password updated successfully',
  welcomeTitle: 'Welcome to the Competency Mapping platform',
  hello: 'Hello',
  loginSubtitle:
    'Identify the technical and behavioral skills within your company. Recognize professionals aligned with expectations and retain talent',
  forgotMyPassword: 'Forgot my password',
  rememberAccess: 'Remember Access',
  signIn: 'Sign in',
  authenticating: 'Authenticating',
  bannerText01: 'Show your potential',
  bannerText02: 'growth',
  bannerText03: 'Work your weaknesses and show off yours',
  bannerText04: 'skills',
  bannerText05: 'Your company has very',
  bannerText06: 'talented',
  bannerText07: 'people and we will help you',
  bannerText08: 'find them',
  statusFeedback: 'Feedback',
  waitingFeedback: 'Waiting feedback',
  evaluationFeedbackDone: 'Feedback done',
  feedbackDoneMessage: 'Feedback step done',
  waitingManager: 'Waiting for appraiser',
  evaluationinProgress: 'Assessment in progress',
  backAssessmentSummary: 'Back to assessment summary',
  stepFinished: 'Step completed successfully',
  selfEvaluationinProgress: 'Self-evaluation in progress',
  administrator: 'Administrator',
  settings: 'Settings',
  systemSettings: 'Platform Settings',
  allowEvaluatorsCreateAndDeleteCompetencies: 'Allows evaluators to create and delete competencies',
  allowEvaluatorsInviteAndDeleteUsers: 'Allow evaluators to invite users to their team, also delete their users',
  allowEvaluatorsCreateAndDeleteAssessments: 'Allows evaluators to create and delete assessments',
  settingsSaved: 'Settings saved',
  createdBy: 'Created by',
  createdAt: 'Created at',
  howItWorks: 'How it works',
  inviteEmployess: 'Invite employees',
  howItWorks01: 'With just a few pieces of data you can invite company users to participate',
  createCompetencies: 'Create the competencies',
  howItWorks02: 'Add the competencies that will be evaluated',
  createAssessmentCycle: 'Create assessment cycles',
  howItWorks03:
    'Now all you have to do is create an evaluation cycle, inform which competencies will be evaluated in this evaluation and who will be able to respond',
  selfAssessmentStep: 'Self Assessment Step',
  howItWorks04: 'Now users can answer the assessments that were made available and self-align in each competency',
  assessmentStep: 'Assessment step',
  howItWorks05: "Each manager receives their team's completed self-assessments and proceeds to the assessment stage",
  feedbackStep: 'Feedback step',
  howItWorks06:
    'Finally, the manager and his subordinate meet for the feedback stage, and thus finalize the evaluation stage',
  dataStudyAvailable: 'Study the data',
  howItWorks07:
    'With data in hand, it is easier to make decisions and understand the needs of each employee, their strengths and weaknesses. Identify what are the technical and behavioral skills within your company. Recognize professionals aligned with expectations and retain talents',
  achievements: 'Achievements',
  about: 'About',
  login: 'Login',
  accountedUsers: 'Accounted users',
  backHome: 'Back to main page',
  assessmentSuggestionPlaceholder: 'Eg.: Take a professional course;Visit customers;Company LMS training portal',
  assessmentSuggestionLabel: 'Suggestion',
  doesNotDemonstrate: 'Does not demonstrate',
  basic: 'Basic',
  intermediate: 'Intermediate',
  advanced: 'Advanced',
  expert: 'Expert',
  mastery: 'Mastery',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  areYouSureFinishAssessment: 'Are you sure you want to finish this assessment?',
  asssessmentFinished: 'Assessment completed successfully',
  noMoreAvailableToUsers: 'It will no longer be available to users',
  yesContinue: 'Yes, continue',
  weAreInClosedBeta: 'We are in closed beta stage',
  knowMore: 'Know more',
  closedBetaMessage01: 'We are currently running a',
  closedBetaMessage02: 'closed beta',
  closedBetaMessage03: 'version for some of our customers',
  alphaMessage01: 'An',
  alphaMessage02: 'Alpha',
  alphaMessage03: 'version has been released',
  roadMapMessage01: 'First',
  roadMapMessage02: 'Roadmap',
  developmentProcessMenssage01: 'Prototyping',
  developmentProcessMenssage02: 'and development of the first',
  developmentProcessMenssage03: 'MVP',
  timeLine: 'Timeline',
  closedBetaEndMessage01: 'End of',
  closedBetaEndMessage02: 'closed beta',
  oficialReleaseMessage01: 'First stable',
  oficialReleaseMessage02: 'version',
  oficialReleaseMessage03: 'released',
  knowHow: 'Know how',
  stayTuned: 'Stay tuned for news',
  newsEmailPlaceholder: 'Enter your email address',
  thanksNewsletter: 'Thanks for subscribing! We will send news to the registered email',
  privacyPolicy: 'Privacy Policy',
  poweredBy: 'Powered by',
  reportUserAssessmentTitle: 'Employee assessments',
  reportUserAssessmentSubtitle: "Check the employee's status on the selected assessment",
  generateReport: 'Generate report',
  userReachedReportLabel: 'Reached: User',
  managerReachedReportLabel: 'Reached: Manager',
  lastAccess: 'Last access',
  generatePDF: 'Generate PDF',
  competenceUpdated: 'Competence updated successfully',
  competenceAlreadyInUseMessage:
    'This competence is already in use by one or more assessments, so it is not possible to add new values',
  uninformed: 'Uninformed',
  reportCompetenciesTitle: 'Competencies report',
  reportCompetenciesSubtitle: 'View the result of the selected competence of all users',
  version: 'Version',
  performanceManagement: 'Performance Management',
  behavioralManagement: 'Behavioral Management',
  performanceDevelopment: 'Performance Development',
  performanceManagementText:
    "Have a simple way to analyze your team's goals and competencies. Through cyclical evaluations, it is possible to monitor the team and control performance over a period.",
  behavioralManagementText:
    "Understand the behavior of the organization's employees and make the most of their skills. Identify patterns of behavior and place people in the right places and roles, increasing satisfaction and reducing turnover.",
  performanceDevelopmentText:
    'Based on performance indicators, it is possible to make an organizational diagnosis and continuous alignment of expectations with your employees. By managing the skills and competences of professionals, prioritize the results achieved and the efficiency of the processes.',
  deleteUserMessage:
    'All data related to that user will be removed, assessments, responses, competencies created, assessments they were linked to, etc.',
  transferUserData: 'Transfer user data',
  selectNewEvaluator: 'Select a new evaluator',
  editEvaluatorWithLinkedUsers:
    "Before modifying this user's access, it is necessary to transfer their data while evaluator to another user with the same access",
  assessmentExpired: 'Assessment Expired',
  weAreLive: "We're live!",
  startFeedback: 'Start feedback',
  usersAdded: 'Users added successfully',
  waitingEvaluator: 'Waiting for evaluator',
  position: 'Position',
  addNewPosition: 'Add new position',
  addPositionLabel: 'Inform the new position',
  addedPosition: 'Position added successfully',
  areYouSureDeletePosition: 'Are you sure you want to remove this position?',
  positionDeleted: 'Position successfully removed!',
  development: 'Development',
  notApplicable: 'No aplica',
  addEvidence: 'Add evidence',
  replaceFile: 'Replace file',
  validate: 'Validate',
  evidenceValidated: 'Successfully validated evidence',
  evidenceAttached: 'Evidence attached successfully',
  pricing: 'Pricing',
  until: 'Up to',
  contactUs: 'Contact us',
  supportPricingStarter: 'Email support only',
  supportPricingPro: 'Support via whatsapp, phone and email',
  supportPricingEnterprise: 'Support via whatsapp, phone and email',
  infrastructurePricingStarter: 'Shared server',
  infrastructurePricingPro: 'Shared server',
  infrastructurePricingEnterprise: 'Dedicated server',
  requestQuote: 'Request a quote',
  month: 'Mo',
  storage: 'Storage',
  exceededMaxSize: 'Exceeded max size',
  attachment: 'Attachment',
  maxFileSize: 'Maximum size allowed',
  allowedFormats: 'Allowed formats',
  confirmAction: "Press enter or click the '+' to confirm",
  exportExcel: 'Export excel',
}

export default DictionaryEN
