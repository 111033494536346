import React, { useContext, useEffect, useState } from 'react'
import { Autocomplete, TextField, Grid, InputAdornment, IconButton, Box, Typography, Button } from '@mui/material'
import { Option } from '../models/option'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { StoreContext } from '../stores/store.context'

interface DynamicSelectProps {
  label: string
  buttonLabel: string
  options: Option[]
  onChange: (e: any, newValue: Option, operation: string) => void
  onAdd: (newValue: string) => void
  onRemove: (removeValue: Option) => void
  positionAdded: boolean | null
  initialValue: Option | null
}

const DynamicSelect: React.FC<DynamicSelectProps> = ({
  label,
  buttonLabel,
  options,
  onChange,
  onAdd,
  onRemove,
  positionAdded,
  initialValue,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [autoCompleteValue, setAutocompleteInputValue] = useState<Option | null>(null)
  const { i18nStore } = useContext(StoreContext)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value)

  useEffect(() => {
    setInputValue('')
  }, [positionAdded])

  useEffect(() => {
    setAutocompleteInputValue(initialValue)
  }, [])

  const handleAdd = (e: any) => {
    if (inputValue.trim()) {
      const newOption = { label: inputValue.trim(), value: inputValue.trim() }
      onAdd(inputValue.trim())
      setAutocompleteInputValue(newOption)
      onChange(e, newOption, 'add')
    }
  }

  const handleAutocompleteChange = (e: any, newValue: Option | null) => {
    if (newValue) {
      setAutocompleteInputValue(newValue)
    } else {
      setAutocompleteInputValue(null)
    }
    onChange(e, newValue!, 'change')
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          options={options}
          getOptionLabel={(option) => option.label}
          onChange={(e, newValue) => handleAutocompleteChange(e, newValue!)}
          renderInput={(params) => <TextField {...params} label={label} id="valueToSearch" name="valueToSearch" />}
          renderOption={(props, option, state) => (
            <Box sx={{ display: 'flex' }} key={option.value}>
              <Box
                {...props}
                component="li"
                sx={{
                  width: '400px',
                  maxWidth: '400px',
                }}
              >
                <Typography variant="body2">{option.label}</Typography>
              </Box>

              {state.index !== 0 && (
                <Button onClick={() => onRemove(option)} sx={{ ml: 'auto' }}>
                  <RemoveIcon color="error" />
                </Button>
              )}
            </Box>
          )}
          value={autoCompleteValue}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      </Grid>
      {autoCompleteValue?.value === 'add' && (
        <Grid item xs={12} sm={6}>
          <TextField
            label={buttonLabel}
            id="valueToAdd"
            name="valueToAdd"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAdd(e)
              }
            }}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" color="primary" onClick={handleAdd}>
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="caption">{i18nStore.dictionary.confirmAction}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default DynamicSelect
