/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import ButtonLoader from '../../../components/button-loader'
import { StoreContext } from '../../../stores/store.context'
import { SearchOutlined } from '@mui/icons-material'
import CancelIcon from '@mui/icons-material/Cancel'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import { UserData } from '../../../models/user.model'
import NotFoundData from '../../../components/not-found'
import { action } from 'mobx'
import { trackPromise } from 'react-promise-tracker'

interface UsersListModalProps {
  emitSelected: Function
  excludeUsers?: Array<string>
}

const UsersListModal: React.FC<UsersListModalProps> = (props: UsersListModalProps) => {
  const { modalStore, i18nStore, authStore, userStore } = useContext(StoreContext)
  const [search, setSearch] = useState('')
  const [searchPosition, setSearchPosition] = useState('')
  const [tempSearch, setTempSearch] = useState('')
  const [selectedUsers, setUsers] = useState([] as Array<UserData>)
  let [checkedAll, setCheckedAll] = useState(false)
  let [page, setPage] = useState<number>(parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string))

  const searchBy = (event: any) => {
    if (tempSearch.includes(':')) {
      var searchField = tempSearch.split(':')[0].toLowerCase().trim()
      var searchValue = tempSearch.split(':')[1].toLowerCase().trim()
      if (searchField) {
        if (searchField === 'position' || searchField === 'cargo') {
          setSearchPosition(searchValue)
          setSearch('')
        }
      }
    } else {
      setSearchPosition('')
      setSearch(event && event.target ? (event.target.value as string) : tempSearch)
    }
  }

  const handleToggle = (user: UserData, index: number) => {
    let newChecked = [...selectedUsers]

    const exists = selectedUsers.some((obj: any) => obj._id === user._id)
    if (!exists) {
      newChecked.push(user)
    } else {
      newChecked = newChecked.filter((obj: any) => obj._id !== user._id)
    }

    user = { ...user, checked: !user.checked }
    userStore.updateUsersList(index, user)

    setUsers(newChecked)

    if (newChecked.length === 0) {
      setCheckedAll(false)
    }
  }

  const handleToggleAll = () => {
    if (userStore.users) {
      setCheckedAll((checkedAll = !checkedAll))
      let newChecked = [...selectedUsers]
      userStore.users.paginatedData.forEach((user: UserData, index: number) => {
        if (checkedAll) {
          newChecked.push(user)
        } else {
          newChecked = []
        }

        user = { ...user, checked: checkedAll }
        userStore.updateUsersList(index, user)
        setUsers(newChecked)
      })
    }
  }

  useEffect(() => {
    const listUsers = async () => {
      if (authStore.tokenData) {
        var filter = JSON.stringify({
          name: search,
          position: searchPosition,
          role: 'user',
        })

        await trackPromise(
          userStore.listUsersByTenant(
            authStore.tokenData.tenant,
            page,
            parseInt(process.env.REACT_APP_DATA_LIMIT as string),
            'createdAt',
            'desc',
            filter
          ),
          'load-more'
        )

        if (props.excludeUsers && userStore.users) {
          const newUsersList = userStore.users.paginatedData.filter((user) => {
            return !props.excludeUsers?.some((excludedUser) => user._id.toString() === excludedUser.toString())
          })

          action(() => {
            userStore.users!.paginatedData = newUsersList
          })()
        }
      }
    }
    listUsers()
  }, [authStore, userStore, i18nStore.dictionary, search, searchPosition, page])

  const confirm = async () => {
    props.emitSelected(selectedUsers)
    modalStore.handleClose()
  }

  return (
    <Fragment>
      <Typography variant="h4" color={'text.primary'} align="center" sx={{ my: 3 }}>
        {i18nStore.dictionary.selectUsers}
      </Typography>

      {userStore.users && (
        <Grid container>
          <Grid item xs={12} display="flex">
            <TextField
              label={i18nStore.dictionary.searchBy}
              value={tempSearch}
              onChange={(e) => setTempSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') searchBy(e)
              }}
              fullWidth
              placeholder={i18nStore.dictionary.searchByUserName}
              InputProps={{
                startAdornment: (
                  <IconButton color="primary" onClick={() => searchBy(tempSearch)}>
                    <SearchOutlined />
                  </IconButton>
                ),
                endAdornment: tempSearch && (
                  <IconButton
                    onClick={() => {
                      setSearch('')
                      setTempSearch('')
                    }}
                  >
                    <CancelIcon sx={{ color: 'primary.main' }} />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {userStore.users.paginatedData.length > 0 ? (
              <Fragment>
                <List sx={{ width: '100%', maxWidth: 360 }}>
                  <ListItem disablePadding>
                    <ListItemButton role={undefined} onClick={() => handleToggleAll()} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          sx={{ color: 'primary.main' }}
                          size="small"
                          checked={checkedAll}
                          value={checkedAll}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={i18nStore.dictionary.selectAll} />
                    </ListItemButton>
                  </ListItem>

                  {userStore.users.paginatedData.map((user, index) => {
                    const labelId = `checkbox-list-label-${index}`

                    return (
                      <ListItem key={index} disablePadding>
                        <ListItemButton role={undefined} onClick={() => handleToggle(user, index)} dense>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              sx={{ color: 'primary.main' }}
                              size="small"
                              checked={user.checked}
                              value={user._id}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={user && user.firstName + ' ' + user.lastName}
                            secondary={
                              user &&
                              user.position && (
                                <Typography variant="body2" color="primary">
                                  {user.position.position}
                                </Typography>
                              )
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
                </List>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4 }}>
                  <ButtonLoader
                    onClick={() => setPage((page = page + 1))}
                    variant="text"
                    loadingText={i18nStore.dictionary.loading}
                    text={i18nStore.dictionary.loadMore}
                    area="load-more"
                    sx={{ display: 'flex', pointerEvents: 'all', paddingX: 0 }}
                    disabled={userStore.users.totalPages === page}
                  />
                </Box>
              </Fragment>
            ) : (
              <NotFoundData />
            )}
          </Grid>
        </Grid>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4 }}>
        <Button
          disableElevation
          size="large"
          type="button"
          variant="text"
          color="primary"
          onClick={() => modalStore.handleClose()}
        >
          {i18nStore.dictionary.back}
        </Button>

        {userStore.users && userStore.users.paginatedData.length > 0 && (
          <ButtonLoader loadingText="Processando..." text={i18nStore.dictionary.confirm} onClick={() => confirm()} />
        )}
      </Box>
    </Fragment>
  )
}

export default observer(UsersListModal)
