import { GlobalStyles as MUIGlobalStyles, Theme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { StoreContext } from '../stores/store.context'

const GlobalStyles = (props: { theme: Theme }) => {
  const { themeStore } = useContext(StoreContext)

  const globalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        webkitAppearance: 'none',
        '.MuiFormHelperText-root.Mui-error': {
          marginTop: '5px',
        },
        '.MuiAutocomplete-paper': {
          backgroundColor: `${props.theme.palette.background.default} !important`,
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          overflowX: 'hidden',
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          background: themeStore.themeMode === 'dark' ? props.theme.palette.grey[900] : props.theme.palette.grey[100],
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  )

  return globalStyles
}

export default observer(GlobalStyles)
