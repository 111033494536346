/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Box,
  Typography,
  Divider,
  TableContainer,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  styled,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext, useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Assessment } from '../../../models/assessment.model'
import { StoreContext } from '../../../stores/store.context'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import NotFoundData from '../../../components/not-found'

interface AssessmentWaitingUser {
  assessmentId: string
  assessmentName: string | undefined
  userId: string
  userName: string
  status: string
  updatedAt: Date | undefined
}
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  // boxShadow: `0px 1px 2px -1px ${theme.palette.grey[100]}, 0px 1px 2px 0px ${theme.palette.grey[500]}, 0px 1px 10px 0px ${theme.palette.grey[400]} !important`,
}))

const WaitingUsersTable: React.FC = () => {
  const { i18nStore, assessmentStore, authStore, generalStore } = useContext(StoreContext)

  const [usersWaiting, setUsersWaiting] = useState<Array<AssessmentWaitingUser>>([])

  useEffect(() => {
    const listAssessmentsByTenant = async () => {
      if (authStore.tokenData) {
        await assessmentStore.listAssessmentsByTenant(
          authStore.tokenData.tenant,
          parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
          parseInt(process.env.REACT_APP_DATA_LIMIT as string),
          'createdAt',
          'desc',
          JSON.stringify({ progress: 'waiting_manager_evaluation' })
        )

        if (assessmentStore.assessments) {
          let assessmentUser: Array<AssessmentWaitingUser> = []
          assessmentStore.assessments.paginatedData.forEach((assessment: Assessment, i: number) => {
            assessment.users.forEach((assessmentUserData) => {
              if (assessmentUserData.progress === 'waiting_manager_evaluation' && assessmentUser.length < 6) {
                assessmentUser.push({
                  assessmentId: assessment._id,
                  assessmentName: assessment.name[i18nStore.language],
                  userId: assessmentUserData.user._id,
                  userName: assessmentUserData.user.firstName,
                  status: assessmentUserData.progress,
                  updatedAt: assessmentUserData.updatedAt,
                })
              }
            })
          })

          if (usersWaiting.length === 0) {
            setUsersWaiting((prevValue) => {
              prevValue = assessmentUser
              return prevValue
            })
          }
        }
      }
    }

    listAssessmentsByTenant()
  }, [assessmentStore, authStore.tokenData, i18nStore.language])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4">{i18nStore.dictionary.usersWaitingEvaluation}</Typography>
          <Link underline="none" component={RouterLink} to={''} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle2">{i18nStore.dictionary.viewAll}</Typography>
            <ArrowForwardIcon sx={{ color: 'primary.main', ml: 1 }} fontSize="small" />
          </Link>
        </Box>

        <Divider sx={{ my: 2 }} />

        <StyledTableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{i18nStore.dictionary.assessment}</TableCell>
                <TableCell>{i18nStore.dictionary.employee}</TableCell>
                <TableCell>{i18nStore.dictionary.status}</TableCell>
                <TableCell>{i18nStore.dictionary.lastUpdate}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersWaiting.length > 0 ? (
                usersWaiting.map((user, i) => (
                  <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {user.assessmentName}
                    </TableCell>
                    <TableCell>{user.userName}</TableCell>
                    <TableCell>
                      <Chip
                        label={generalStore.getStatusTranslated(user.status)}
                        color={generalStore.getBackgroundByStatus(user.status)}
                        size="small"
                        sx={{ marginLeft: 'auto' }}
                      />
                    </TableCell>
                    <TableCell>
                      {' '}
                      {user.updatedAt &&
                        new Date(user.updatedAt).toLocaleDateString('pt-BR', {
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                    </TableCell>
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={`/secure/assessment/${user.assessmentId}/user/${user.userId}/evaluate?edit=true&step=managers_evaluation&progress=waiting_manager_evaluation`}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Typography variant="subtitle2">{i18nStore.dictionary.evaluate}</Typography>
                        <ArrowForwardIcon sx={{ color: 'primary.main', ml: 1 }} fontSize="small" />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <NotFoundData customText={i18nStore.dictionary.waitingEvaluations} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="caption">{i18nStore.dictionary.showingLastSix}</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default observer(WaitingUsersTable)
