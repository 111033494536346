import { Typography, Box, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext } from 'react'
import { trackPromise } from 'react-promise-tracker'
import ButtonLoader from '../../../components/button-loader'
import { StoreContext } from '../../../stores/store.context'
import WarningIcon from '@mui/icons-material/Warning'

interface DeleteCompetenceModalProps {
  id: string
}

const DeleteCompetenceModal: React.FC<DeleteCompetenceModalProps> = (props: DeleteCompetenceModalProps) => {
  const { modalStore, i18nStore, competenceStore, toastStore } = useContext(StoreContext)

  const deleteCompetence = async () => {
    try {
      const deleted = await trackPromise(competenceStore.deleteCompetence(props.id), 'delete-competence')
      if (deleted) {
        toastStore.pushToast({ message: i18nStore.dictionary.competenceDeleted, type: 'success' })
      }
      modalStore.handleClose()
    } catch (e: any) {
      toastStore.pushToast({ message: e.toString(), type: 'error' })
      modalStore.handleClose()
    }
  }

  return (
    <Fragment>
      <WarningIcon color="warning" sx={{ alignSelf: 'center', fontSize: '5rem' }} />

      <Typography variant="h4" color={'text.primary'} align="center" sx={{ my: 3 }}>
        {i18nStore.dictionary.areYouSureDeleteCompetence}
      </Typography>

      <Typography variant="body2" color={'secondary.lighter'} align="center" sx={{ mb: 3 }}>
        {i18nStore.dictionary.cannotBeUndoneAction}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          disableElevation
          size="large"
          type="button"
          variant="text"
          color="primary"
          onClick={() => modalStore.handleClose()}
        >
          {i18nStore.dictionary.noBack}
        </Button>

        <ButtonLoader
          loadingText="Processando..."
          text={i18nStore.dictionary.yesContinue}
          area="delete-competence"
          disabled={!props.id}
          onClick={() => deleteCompetence()}
        />
      </Box>
    </Fragment>
  )
}

export default observer(DeleteCompetenceModal)
