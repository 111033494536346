import {
  Box,
  Typography,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  styled,
  IconButton,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import ContentArea from '../../components/content-area'
import { StoreContext } from '../../stores/store.context'
import BreadCrumb from '../../components/breadcrumb'
import { Assessment } from '../../models/assessment.model'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { useNavigate } from 'react-router-dom'
import NotFoundData from '../../components/not-found'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ButtonLoader from '../../components/button-loader'

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: `0px 2px 5px -1px ${theme.palette.grey[300]}, 0px 1px 5px 0px ${theme.palette.grey[200]}, 0px 1px 15px 0px ${theme.palette.grey[100]}`,
}))

const AssessmentMainPage: React.FC = () => {
  let [page, setPage] = useState<number>(parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string))

  const { assessmentStore, authStore, breadcrumbStore, i18nStore, generalStore, toastStore } = useContext(StoreContext)
  const navigate = useNavigate()

  useEffect(() => {
    breadcrumbStore.setBreadcrumbItems([
      {
        name: i18nStore.dictionary.myAssessments,
        link: '/secure/assessments',
      },
    ])

    const listUserAssessments = async () => {
      if (authStore.tokenData) {
        await assessmentStore.listUserAssessments(
          authStore.tokenData._id,
          page,
          parseInt(process.env.REACT_APP_DATA_LIMIT as string),
          'createdAt',
          'desc',
          ''
        )
      }
    }

    listUserAssessments()
  }, [authStore, assessmentStore, breadcrumbStore, i18nStore.dictionary, page])

  return (
    <Fragment>
      <Box>
        <BreadCrumb />
        <Typography variant="h2">{i18nStore.dictionary.myAssessments}</Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {i18nStore.dictionary.myAssessmentsSubtitle}
        </Typography>
      </Box>

      <ContentArea>
        <Grid container spacing={2}>
          {assessmentStore.userAssessments && assessmentStore.userAssessments.paginatedData.length > 0 ? (
            assessmentStore.userAssessments.paginatedData.map((assessment: Assessment, index: number) => {
              const userAssessmentProgress = assessment.users.filter((assessment) => {
                return assessment.user._id === authStore.tokenData!._id
              })

              return (
                <Grid item xs={12} sm={4} key={index}>
                  <StyledCard>
                    <CardHeader
                      title={<Typography variant="h5">{assessment.name[i18nStore.language]}</Typography>}
                      subheader={
                        <Typography variant="caption" color="text.primary">
                          {new Date(assessment.createdAt).toLocaleDateString(i18nStore.locale, {
                            hour: 'numeric',
                            minute: 'numeric',
                          })}
                        </Typography>
                      }
                    />
                    <Divider />
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle2">{i18nStore.dictionary.endDate}: </Typography>
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          {new Date(assessment.endDate!).toLocaleDateString(i18nStore.locale)}
                        </Typography>
                      </Box>
                    </CardContent>
                    <CardActions
                      disableSpacing
                      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                      {assessment.users && (
                        <Chip
                          label={generalStore.getStatusTranslated(userAssessmentProgress[0].progress)}
                          color={generalStore.getBackgroundByStatus(userAssessmentProgress[0].progress)}
                          size="small"
                        />
                      )}
                      {userAssessmentProgress[0].progress !== 'done' &&
                      userAssessmentProgress[0].progress !== 'waiting_manager_evaluation' &&
                      userAssessmentProgress[0].progress !== 'manager_evaluation_in_progress' &&
                      userAssessmentProgress[0].progress !== 'feedback' ? (
                        <IconButton
                          onClick={() => {
                            let isExpired = assessmentStore.checkIsExpired(assessment.endDate!)
                            if (isExpired) {
                              toastStore.pushToast({ message: i18nStore.dictionary.assessmentExpired, type: 'error' })
                            } else {
                              navigate(
                                `/secure/assessment/${assessment._id}/user/${authStore.tokenData!._id}/evaluate${
                                  userAssessmentProgress[0].progress === 'in_progress'
                                    ? `?edit=true&progress=${userAssessmentProgress[0].progress}`
                                    : `?progress=${userAssessmentProgress[0].progress}`
                                }`
                              )
                            }
                          }}
                        >
                          <Typography variant="subtitle2" color="text.primary">
                            {userAssessmentProgress[0].progress === 'not_started'
                              ? i18nStore.dictionary.start
                              : i18nStore.dictionary.continue}
                          </Typography>
                          <PlayArrowIcon sx={{ ml: 1, color: 'primary.main' }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() =>
                            navigate(
                              `/secure/assessment/${assessment._id}/user/${authStore.tokenData!._id}/evaluate${
                                userAssessmentProgress[0].progress === 'done' ||
                                userAssessmentProgress[0].progress === 'waiting_manager_evaluation' ||
                                userAssessmentProgress[0].progress === 'manager_evaluation_in_progress' ||
                                userAssessmentProgress[0].progress === 'feedback'
                                  ? `?view=true&step=${userAssessmentProgress[0].step}&progress=${userAssessmentProgress[0].progress}`
                                  : ''
                              }`
                            )
                          }
                        >
                          <VisibilityIcon sx={{ color: 'text.primary' }} />
                        </IconButton>
                      )}
                    </CardActions>
                  </StyledCard>
                </Grid>
              )
            })
          ) : (
            <Box sx={{ m: 'auto' }}>
              <NotFoundData customText={i18nStore.dictionary.noAssessmentFound} />
            </Box>
          )}

          {assessmentStore.userAssessments && assessmentStore.userAssessments.totalPages > 0 && (
            <Grid container sx={{ mt: 6, display: 'flex', alignItems: 'center' }}>
              <Grid item xs={12} sm={3} display="flex" justifyContent="center" sx={{ marginLeft: 'auto' }}>
                <ButtonLoader
                  variant="contained"
                  onClick={() => setPage((page = page + 1))}
                  disabled={assessmentStore.userAssessments.totalPages === page}
                  loadingText={i18nStore.dictionary.loading}
                  text={i18nStore.dictionary.loadMore}
                  area="auth"
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                sx={{ display: 'flex', mt: { xs: 2, sm: 0 }, justifyContent: { xs: 'center', sm: 'end' } }}
              >
                <Typography component="span" variant="subtitle2">
                  {i18nStore.dictionary.showing}
                </Typography>
                <Typography component="span" variant="subtitle2" sx={{ mx: 1 }}>
                  {assessmentStore.userAssessments.paginatedData.length}
                </Typography>
                <Typography component="span" variant="subtitle2" sx={{ textTransform: 'lowercase' }}>
                  {i18nStore.dictionary.of}
                </Typography>
                <Typography component="span" variant="subtitle2" sx={{ ml: 1 }}>
                  {assessmentStore.userAssessments.totalItems}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ContentArea>
    </Fragment>
  )
}

export default observer(AssessmentMainPage)
