import { Box, Container } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import PageLoader from '../components/page-loader'
import Forbidden from '../pages/forbidden'
import { StoreContext } from '../stores/store.context'
import { RolesEnum } from '../enums/roles.enum'

const AuthenticatedLayout: React.FC = () => {
  const { authStore, tenantStore } = useContext(StoreContext)
  const authenticated = authStore.authenticated
  const currentPath = useLocation().pathname

  useEffect(() => {
    const getMenu = async () => {
      await authStore.getMenu(authStore.tokenData!.role)

      if (currentPath.split('/')[4] === 'summary') {
        if (
          authStore.tokenData?.role === RolesEnum.evaluator ||
          authStore.tokenData?.role === RolesEnum.administrator
        ) {
          authStore.hasAccess = true
        } else {
          authStore.hasAccess = false
        }
      } else {
        if (currentPath.split('/')[2] === 'assessment') {
          if (
            authStore.tokenData?.role === RolesEnum.evaluator ||
            authStore.tokenData?.role === RolesEnum.user ||
            authStore.tokenData?.role === RolesEnum.administrator
          ) {
            authStore.hasAccess = true
          } else {
            authStore.hasAccess = false
          }
        } else {
          if (authStore.moduleAccess && !authStore.moduleAccess.includes(currentPath)) {
            authStore.hasAccess = false
          } else {
            authStore.hasAccess = true
          }
        }
      }
    }

    const getTenant = async () => await tenantStore.getTenantById(authStore.tokenData!.tenant)

    if (authStore.tokenData) {
      getMenu()
      getTenant()
    }
  }, [authStore, currentPath, tenantStore])

  return (
    <>
      {authenticated && authStore.hasAccess ? (
        <>
          <PageLoader />
          <Navbar />
          <Container maxWidth="md">
            <Box sx={{ mt: 5 }}>
              <Outlet />
            </Box>
          </Container>
        </>
      ) : (
        <Forbidden />
      )}

      <Footer />
    </>
  )
}

export default observer(AuthenticatedLayout)
