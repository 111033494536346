import { Grid, Typography, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext, Fragment } from 'react'
import { StoreContext } from '../../../stores/store.context'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useNavigate } from 'react-router-dom'

interface AssessmentEvaluateUserSuccessProps {
  step?: string | null
  view?: string | null
}

const AssessmentEvaluateUserSuccess: React.FC<AssessmentEvaluateUserSuccessProps> = (
  props: AssessmentEvaluateUserSuccessProps
) => {
  const { i18nStore, assessmentStore, authStore } = useContext(StoreContext)
  const navigate = useNavigate()

  return (
    <Fragment>
      {assessmentStore.assessmentEvaluationForm && (
        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={12} textAlign="center">
            <CheckCircleIcon color="success" sx={{ fontSize: '5rem' }} />
            <Typography variant="h2" sx={{ mt: 3 }}>
              {assessmentStore.assessmentUserData?.step === 'evaluation_completed'
                ? i18nStore.dictionary.managerEvaluationDone
                : i18nStore.dictionary.stepFinished}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }} textAlign="center">
            {
              <Button
                variant="text"
                onClick={() =>
                  authStore.tokenData!.role === 'user'
                    ? navigate(`/secure/assessment/`)
                    : navigate(`/secure/assessment/${assessmentStore.assessment?._id}/summary`)
                }
              >
                {authStore.tokenData!.role === 'user'
                  ? i18nStore.dictionary.backAssessments
                  : i18nStore.dictionary.backAssessmentSummary}
              </Button>
            }
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}

export default observer(AssessmentEvaluateUserSuccess)
