import { Box, Link, Container, Typography, Button, FormHelperText, Stack, TextField, Grid } from '@mui/material'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Fragment, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../stores/store.context'
import { observer } from 'mobx-react-lite'
import HomePeople from '../../assets/images/home-people.png'
import Footer from '../../components/footer'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import WebsiteNavBar from '../../components/navbar/website-navbar'
import { Formik } from 'formik'
import * as Yup from 'yup'
import SendIcon from '@mui/icons-material/Send'
import { trackPromise } from 'react-promise-tracker'
import ButtonLoader from '../../components/button-loader'
import CelebrationIcon from '@mui/icons-material/Celebration'

declare global {
  interface Window {
    grecaptcha: any
  }
}

const HomePage: React.FC = () => {
  const { i18nStore, userStore, themeStore } = useContext(StoreContext)
  const [addedNewsletter, setAddedNewsletter] = useState(false)
  const navigate = useNavigate()

  const formSchema = Yup.object().shape({
    email: Yup.string().email(i18nStore.dictionary.invalidEmail).required(i18nStore.dictionary.requiredField),
  })

  const handleNewsletter = (email: string) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute('6Lcy_pUlAAAAACTOxcCf3cQ9lEAbLbd4LpY15uwE', { action: 'homepage' })
        .then(async (token: any) => {
          if (token) {
            const added = await trackPromise(userStore.addNewsletter(email, token), 'invite-user')
            if (added) {
              setAddedNewsletter(true)
            }
          }
        })
    })
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js?render=6Lcy_pUlAAAAACTOxcCf3cQ9lEAbLbd4LpY15uwE'
    script.id = 'gRecaptcha'

    if (!document.getElementById(script.id)) {
      document.body.appendChild(script)
    }
  })

  useEffect(
    () => () => {
      var recaptcha = document.getElementById('gRecaptcha')
      if (recaptcha) {
        recaptcha.parentNode!.removeChild(recaptcha)
        var recaptchaBadge = document.getElementsByClassName('grecaptcha-badge')[0]
        recaptchaBadge.parentNode!.removeChild(recaptchaBadge)
      }
    },
    []
  )

  return (
    <Fragment>
      <WebsiteNavBar />
      <Container maxWidth="md" sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body2" fontWeight={400} sx={{ display: 'flex', alignItems: 'center' }}>
            <CelebrationIcon fontSize="small" sx={{ mr: 2, color: 'warning.main' }} />{' '}
            <Box component="span">{i18nStore.dictionary.weAreLive} -&nbsp;</Box>
            <Link component={RouterLink} to={'/time-line'}>
              {i18nStore.dictionary.knowMore}
            </Link>
          </Typography>
        </Box>
      </Container>
      <Container maxWidth="md" sx={{ position: 'relative', mt: 8 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h2" color="text.primary" fontWeight={400} sx={{ maxWidth: '335px' }}>
                {i18nStore.dictionary.bannerText05}{' '}
                <Box component="span" fontWeight={700} color="primary.main">
                  {i18nStore.dictionary.bannerText06},{' '}
                </Box>
                {i18nStore.dictionary.bannerText07}{' '}
                <Box component="span" fontWeight={700} color="primary.main">
                  {i18nStore.dictionary.bannerText08}
                </Box>
              </Typography>

              <Button variant="outlined" sx={{ mt: 2 }} onClick={() => navigate('/about')}>
                {i18nStore.dictionary.knowHow}
              </Button>
            </Box>

            <Box component="img" src={HomePeople} sx={{ margin: 'auto', pt: 4, width: '60%' }} />
          </Box>
        </Box>

        <Box
          sx={{
            mt: 8,
            width: '100vw',
            left: 'calc(-50vw + 50%)',
            position: 'relative',
            background: `linear-gradient(90deg, ${themeStore.theme.palette.primary.main} 0%,  ${themeStore.theme.palette.primary.main} 100%)`,
            py: 4,
          }}
        >
          <Container maxWidth="md" sx={{ position: 'relative' }}>
            <Typography variant="h2" color="primary.contrastText" fontWeight={400} sx={{ maxWidth: '335px' }}>
              {i18nStore.dictionary.stayTuned}
            </Typography>

            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={formSchema}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={(values) => handleNewsletter(values.email)}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, touched, values, isValid }) => {
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    <Grid container sx={{ mt: 2 }}>
                      <Grid item xs={12} sm={6}>
                        {!addedNewsletter ? (
                          <Stack spacing={1}>
                            <Box sx={{ display: 'flex' }}>
                              <TextField
                                fullWidth
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                placeholder={i18nStore.dictionary.newsEmailPlaceholder}
                                variant="outlined"
                                label={i18nStore.dictionary.email}
                                inputProps={{
                                  autoComplete: 'user-email',
                                  form: {
                                    autocomplete: 'off',
                                  },
                                }}
                                sx={{
                                  '.MuiOutlinedInput-input': {
                                    color: 'primary.contrastText',
                                  },
                                }}
                              />
                              <ButtonLoader
                                loadingText={i18nStore.dictionary.loading}
                                text={
                                  <>
                                    <Typography color="primary.contrastText">{i18nStore.dictionary.send}</Typography>
                                    <SendIcon sx={{ color: 'primary.contrastText', ml: 2 }} />
                                  </>
                                }
                                area="invite-user"
                                disabled={!isValid}
                                variant="text"
                                sx={{ '&:disabled': { opacity: 0.3, color: 'primary.contrastText' }, ml: 4 }}
                              />
                            </Box>

                            {touched.email && errors.email && <FormHelperText>{errors.email}</FormHelperText>}
                          </Stack>
                        ) : (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography color="primary.contrastText">
                              {i18nStore.dictionary.thanksNewsletter}
                            </Typography>
                            {/* <CelebrationIcon sx={{ color: 'yellow', ml: 2 }} /> */}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </form>
                )
              }}
            </Formik>
          </Container>
        </Box>
      </Container>
      <Footer />
    </Fragment>
  )
}

export default observer(HomePage)
