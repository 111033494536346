/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Box,
  Typography,
  Divider,
  TableContainer,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  styled,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useContext, useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Assessment } from '../../../models/assessment.model'
import { StoreContext } from '../../../stores/store.context'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import NotFoundData from '../../../components/not-found'

interface PendingSendAssessmentsTable {
  assessmentId: string
  assessmentName: string | undefined
  userId: string
  userName: string
  status: string
  updatedAt: Date | undefined
  endDate: Date
}
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingBottom: '1rem',
}))

const WaitingUsersTable: React.FC = () => {
  const { i18nStore, themeStore, assessmentStore, authStore, generalStore } = useContext(StoreContext)

  const [userAssessments, setUserAssessments] = useState<Array<PendingSendAssessmentsTable>>([])

  useEffect(() => {
    const listAssessmentsByUser = async () => {
      if (authStore.tokenData) {
        await assessmentStore.listUserAssessments(
          authStore.tokenData._id,
          parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
          parseInt(process.env.REACT_APP_DATA_LIMIT as string),
          'createdAt',
          'desc',
          JSON.stringify({ progress: 'not_started|in_progress' })
        )

        if (assessmentStore.userAssessments) {
          let assessmentUser: Array<PendingSendAssessmentsTable> = []
          assessmentStore.userAssessments.paginatedData.forEach((assessment: Assessment, i: number) => {
            assessment.users.forEach((assessmentUserData) => {
              if (assessmentUser.length < 6 && assessmentUserData.progress !== 'done') {
                assessmentUser.push({
                  assessmentId: assessment._id,
                  assessmentName: assessment.name[i18nStore.language],
                  userId: assessmentUserData.user._id,
                  userName: assessmentUserData.user.firstName,
                  status: assessmentUserData.progress,
                  updatedAt: assessmentUserData.updatedAt,
                  endDate: assessment.endDate!,
                })
              }
            })
          })

          if (userAssessments.length === 0) {
            setUserAssessments((prevValue) => {
              prevValue = assessmentUser
              return prevValue
            })
          }
        }
      }
    }

    listAssessmentsByUser()
  }, [assessmentStore, authStore.tokenData, i18nStore.language])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4">{i18nStore.dictionary.pendingAssessments}</Typography>
          <Link
            underline="none"
            component={RouterLink}
            to={'/secure/assessment'}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography variant="subtitle2">{i18nStore.dictionary.viewAll}</Typography>
            <ArrowForwardIcon sx={{ color: 'primary.main', ml: 1 }} fontSize="small" />
          </Link>
        </Box>
        <Divider sx={{ my: 2 }} />

        <StyledTableContainer>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  'th:first-of-type': {
                    borderTopLeftRadius: '10px',
                  },
                  'th:last-of-type': {
                    borderTopRightRadius: '10px',
                  },
                  th: {
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                  },
                }}
              >
                <TableCell>{i18nStore.dictionary.assessment}</TableCell>
                <TableCell>{i18nStore.dictionary.employee}</TableCell>
                <TableCell>{i18nStore.dictionary.status}</TableCell>
                <TableCell>{i18nStore.dictionary.lastUpdate}</TableCell>
                <TableCell>{i18nStore.dictionary.endDate}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userAssessments.length > 0 ? (
                userAssessments.map((assessmentUser, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      '&:last-child td': { border: 0 },
                      '&:not(:last-child) td': {
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px',
                        borderBottomColor: 'grey.300',
                      },
                      '&:nth-of-type(odd)': {
                        backgroundColor: themeStore.themeMode === 'dark' ? 'grey.800' : 'grey.100',
                      },
                      '&:nth-of-type(even)': {
                        backgroundColor: 'background.default',
                      },
                    }}
                  >
                    <TableCell scope="row">{assessmentUser.assessmentName}</TableCell>
                    <TableCell>{assessmentUser.userName}</TableCell>
                    <TableCell>
                      <Chip
                        label={generalStore.getStatusTranslated(assessmentUser.status)}
                        color={generalStore.getBackgroundByStatus(assessmentUser.status)}
                        size="small"
                        sx={{ marginLeft: 'auto' }}
                      />
                    </TableCell>
                    <TableCell>
                      {assessmentUser.updatedAt &&
                        new Date(assessmentUser.updatedAt).toLocaleDateString('pt-BR', {
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                    </TableCell>
                    <TableCell>
                      {assessmentUser.endDate &&
                        new Date(assessmentUser.endDate).toLocaleDateString('pt-BR', {
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                    </TableCell>
                    <TableCell>
                      {assessmentStore.checkIsExpired(assessmentUser.endDate!) ? (
                        <Chip label={i18nStore.dictionary.assessmentExpired} color="error" size="small" />
                      ) : (
                        <Link
                          component={RouterLink}
                          to={`/secure/assessment/${assessmentUser.assessmentId}/user/${
                            assessmentUser.userId
                          }/evaluate${
                            assessmentUser.status === 'in_progress'
                              ? '?edit=true&progress=in_progress'
                              : '?progress=not_started'
                          }`}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <Typography variant="subtitle2">
                            {assessmentUser.status === 'in_progress'
                              ? i18nStore.dictionary.continue
                              : i18nStore.dictionary.start}
                          </Typography>
                          <ArrowForwardIcon sx={{ color: 'primary.main', ml: 1 }} fontSize="small" />
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <NotFoundData customText={i18nStore.dictionary.waitingEvaluations} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="caption">{i18nStore.dictionary.showingLastSix}</Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default observer(WaitingUsersTable)
