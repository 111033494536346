import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  styled,
} from '@mui/material'
import { Fragment, useContext, useEffect, useState } from 'react'
import WebsiteNavBar from '../../components/navbar/website-navbar'
import Footer from '../../components/footer'
// import StarIcon from '@mui/icons-material/StarBorder'
import { StoreContext } from '../../stores/store.context'
import { observer } from 'mobx-react-lite'

const PricingPage: React.FC = () => {
  const { i18nStore } = useContext(StoreContext)
  const [tiers, setTiers] = useState<Array<any> | null>(null)

  const PricingList = styled('ul')({
    margin: 0,
    padding: 0,
    listStyle: 'none',
  })

  useEffect(() => {
    setTiers([
      {
        title: 'Starter',
        price: '12',
        description: [
          `${i18nStore.dictionary.until} 20 ${i18nStore.dictionary.users}`,
          i18nStore.dictionary.infrastructurePricingStarter,
          i18nStore.dictionary.until +
            ' 1 GB ' +
            i18nStore.dictionary.of.toLowerCase() +
            ' ' +
            i18nStore.dictionary.storage.toLowerCase(),
          i18nStore.dictionary.supportPricingStarter,
        ],
        buttonVariant: 'outlined',
      },
      {
        title: 'Pro',
        //   subheader: 'Most popular',
        price: '16',
        description: [
          `${i18nStore.dictionary.until} 100 ${i18nStore.dictionary.users}`,
          i18nStore.dictionary.infrastructurePricingPro,
          i18nStore.dictionary.until +
            ' 5 GB ' +
            i18nStore.dictionary.of.toLowerCase() +
            ' ' +
            i18nStore.dictionary.storage.toLowerCase(),
          i18nStore.dictionary.supportPricingPro,
        ],
        buttonVariant: 'contained',
      },
      {
        title: 'Enterprise',
        price: i18nStore.dictionary.requestQuote,
        description: [
          `100+ ${i18nStore.dictionary.users}`,
          i18nStore.dictionary.infrastructurePricingEnterprise,
          i18nStore.dictionary.until +
            ' 10 GB ' +
            i18nStore.dictionary.of.toLowerCase() +
            ' ' +
            i18nStore.dictionary.storage.toLowerCase(),
          i18nStore.dictionary.supportPricingEnterprise,
        ],
        buttonVariant: 'outlined',
      },
    ])
  }, [i18nStore.dictionary])

  return (
    <Fragment>
      <WebsiteNavBar />

      <Container maxWidth="md" sx={{ position: 'relative' }}>
        {tiers && (
          <Grid container spacing={5} alignItems="flex-end" sx={{ mt: 5 }}>
            {tiers.map((tier) => (
              <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                <Card>
                  <CardHeader
                    title={<Typography sx={{ textAlign: 'center', color: 'white' }}>{tier.title}</Typography>}
                    //   subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    //   action={tier.title === 'Pro' ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <Typography component="h2" variant="h3" color="text.primary" sx={{ mr: 1 }}>
                        {tier.title !== 'Enterprise' ? 'R$' : ''} {tier.price}
                      </Typography>
                      <Typography variant="h6" color="text.primary">
                        {tier.title !== 'Enterprise'
                          ? `${i18nStore.dictionary.user.toLowerCase()}/${i18nStore.dictionary.month.toLowerCase()}`
                          : ''}
                      </Typography>
                    </Box>
                    <PricingList>
                      {tier.description.map((line: any, index: number) => (
                        <Typography component="li" variant="subtitle2" align="center" key={index}>
                          {line}
                        </Typography>
                      ))}
                    </PricingList>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={tier.buttonVariant as any}>
                      {i18nStore.dictionary.contactUs}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
      <Footer />
    </Fragment>
  )
}

export default observer(PricingPage)
