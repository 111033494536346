import { IconButton, Chip, Menu, MenuItem, Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../stores/store.context'
import NotFoundData from './not-found'
import NotificationsIcon from '@mui/icons-material/Notifications'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread'
import ButtonLoader from './button-loader'
import { trackPromise } from 'react-promise-tracker'
import { Notification } from '../models/notification.model'
import DraftsIcon from '@mui/icons-material/Drafts'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'

const Notifications = () => {
  let [page, setPage] = useState<number>(parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string))

  const { authStore, i18nStore, notificationStore, themeStore } = useContext(StoreContext)

  const [anchorElNotification, setAnchorElNotification] = useState<null | HTMLElement>(null)
  const handleOpenNotificationMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElNotification(event.currentTarget)
  const handleCloseNotificationMenu = () => setAnchorElNotification(null)

  const navigate = useNavigate()

  const getNotifications = useCallback(async () => {
    await notificationStore.list(
      authStore.tokenData!._id,
      page,
      parseInt(process.env.REACT_APP_DATA_LIMIT as string),
      'createdAt',
      'desc',
      ''
    )
  }, [authStore.tokenData, notificationStore, page])

  useEffect(() => {
    getNotifications()
  }, [getNotifications, notificationStore])

  const markAs = async (notification: Notification) => {
    const status = notification.status === 'unread' ? 'read' : 'unread'
    await trackPromise(notificationStore.markAs(notification._id, status), 'mark-as')
    getNotifications()
  }

  const markAllAsRead = async () => {
    await trackPromise(notificationStore.markAllAsRead(authStore.tokenData!._id), 'mark-all-as-read')
    getNotifications()
  }

  const deleteNotification = async (notification: Notification) => {
    await trackPromise(notificationStore.deleteNotification(notification._id), 'delete')
    getNotifications()
  }

  const getBackgroundColor = (notification: Notification): string => {
    let backgroundColor = 'grey.100'

    if (notification.status === 'unread') {
      if (themeStore.themeMode === 'dark') {
        backgroundColor = 'grey.800'
      } else {
        backgroundColor = 'grey.100'
      }
    } else {
      backgroundColor = 'background.default'
    }

    return backgroundColor
  }

  return (
    <Fragment>
      <IconButton sx={{ ml: 2, position: 'relative' }} onClick={handleOpenNotificationMenu}>
        <NotificationsIcon color="warning" />
        {notificationStore.notifications && (
          <Chip
            label={notificationStore.notReadedAmount}
            color="error"
            size="small"
            sx={{
              position: 'absolute',
              width: '15px',
              height: '15px',
              top: 0,
              right: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '.MuiChip-label': {
                padding: '0',
                fontSize: '10px',
              },
            }}
          />
        )}
      </IconButton>

      <Menu
        sx={{
          mt: '45px',
          '& .MuiPaper-root': {
            backgroundColor: 'background.default',
            boxShadow: `0px 2px 5px -1px ${themeStore.theme.palette.grey[100]}, 0px 1px 5px 0px ${themeStore.theme.palette.grey[500]}, 0px 1px 15px 0px ${themeStore.theme.palette.grey[400]} !important`,
          },
        }}
        id="menu-appbar"
        anchorEl={anchorElNotification}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElNotification)}
        onClose={handleCloseNotificationMenu}
      >
        {notificationStore.notifications && notificationStore.notifications.paginatedData.length > 0 && (
          <MenuItem
            sx={{
              display: 'flex',
              alignItems: 'space-between',
              pointerEvents: 'none',
              '&:hover': { backgroundColor: 'initial' },
            }}
          >
            <ButtonLoader
              onClick={() => markAllAsRead()}
              variant="text"
              loadingText={i18nStore.dictionary.loading}
              text={i18nStore.dictionary.markAllAsRead}
              area="mark-all-as-read"
              sx={{ display: 'flex', pointerEvents: 'all', paddingX: 0 }}
            />
          </MenuItem>
        )}
        {notificationStore.notifications && notificationStore.notifications.paginatedData.length > 0 ? (
          notificationStore.notifications.paginatedData.map((notification, i) => {
            return (
              <MenuItem
                key={i}
                sx={{
                  display: 'flex',
                  alignItems: { xs: 'flex-end', sm: 'space-between' },
                  flexDirection: { xs: 'column', sm: 'row' },
                  pointerEvents: 'none',
                  backgroundColor: getBackgroundColor(notification),
                  '&:hover': { backgroundColor: 'initial' },
                  whiteSpace: 'break-spaces',
                  '&:not(:last-child)': {
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '.5px',
                    borderBottomColor: themeStore.theme.palette.grey[300],
                  },
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', flexGrow: 1 }}>
                  <Typography variant="subtitle1">{notification.title[i18nStore.language]}</Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      lineClamp: 2,
                      boxOrient: 'vertical',
                      webkitBoxPack: 'start',
                      justifyContent: 'flex-start',
                      maxWidth: '450px',
                    }}
                  >
                    {notification.description[i18nStore.language]}
                  </Typography>
                </Box>
                <Box sx={{ ml: { xs: 0, sm: 2 }, display: 'flex' }}>
                  <ButtonLoader
                    onClick={() => markAs(notification)}
                    variant="text"
                    loadingText={i18nStore.dictionary.loading}
                    text={notification.status === 'unread' ? <DraftsIcon /> : <MarkAsUnreadIcon />}
                    area="mark-as"
                    sx={{ display: 'flex', alignItems: 'center', pointerEvents: 'all' }}
                    popover={{
                      text:
                        notification.status === 'unread'
                          ? i18nStore.dictionary.markAsRead
                          : i18nStore.dictionary.markAsUnread,
                    }}
                  />
                  {notification && notification.link !== '' && (
                    <ButtonLoader
                      onClick={() => {
                        handleCloseNotificationMenu()

                        if (notification.status === 'unread') {
                          markAs(notification)
                        }

                        if (notification.link) navigate(notification.link)
                      }}
                      variant="text"
                      loadingText={i18nStore.dictionary.loading}
                      text={<OpenInNewIcon />}
                      area="open-in"
                      sx={{ display: 'flex', alignItems: 'center', pointerEvents: 'all' }}
                      popover={{ text: i18nStore.dictionary.followLink }}
                    />
                  )}
                  <ButtonLoader
                    onClick={() => deleteNotification(notification)}
                    variant="text"
                    loadingText={i18nStore.dictionary.loading}
                    text={<DeleteIcon color="error" />}
                    area="delete"
                    popover={{ text: i18nStore.dictionary.delete }}
                    sx={{ display: 'flex', alignItems: 'center', pointerEvents: 'all' }}
                  />
                </Box>
              </MenuItem>
            )
          })
        ) : (
          <MenuItem onClick={handleCloseNotificationMenu}>
            <NotFoundData
              customText={i18nStore.dictionary.noNotifications}
              icon={<BeachAccessIcon sx={{ fontSize: '3.5rem', mb: 2 }} />}
            />
          </MenuItem>
        )}
        {notificationStore.notifications && notificationStore.notifications.totalPages > 1 && (
          <MenuItem
            sx={{
              display: 'flex',
              alignItems: 'space-between',
              pointerEvents: 'none',
              '&:hover': { backgroundColor: 'initial' },
            }}
          >
            <ButtonLoader
              onClick={() => setPage((page = page + 1))}
              variant="text"
              loadingText={i18nStore.dictionary.loading}
              text={i18nStore.dictionary.loadMore}
              area="load-more"
              sx={{ display: 'flex', pointerEvents: 'all', paddingX: 0 }}
              disabled={notificationStore.notifications.totalPages === page}
            />
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  )
}

export default observer(Notifications)
