import { Theme } from '@mui/material'
import { makeAutoObservable } from 'mobx'
import Palette from '../theme/palette'
import { createTheme } from '@mui/material'
import componentsOverride from '../theme/overrides'
import typography from '../theme/typography'

class ThemeStore {
  private _themeMode: string = 'light'
  private _theme: Theme

  constructor() {
    makeAutoObservable(this)
    this._theme = createTheme({
      palette: Palette(this.themeMode),
      typography: typography,
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1280,
          lg: 1920,
          xl: 1536,
        },
      },
    })
    this._theme.components = componentsOverride(this._theme)
  }

  setThemeMode(mode: string) {
    this.themeMode = mode
    this._theme = createTheme({ ...this._theme, palette: Palette(mode), typography: typography })
    this._theme.components = componentsOverride(this._theme)
    localStorage.setItem('cm_theme', mode)
  }

  get themeMode() {
    const userChoice = localStorage.getItem('cm_theme')
    if (userChoice) {
      this.themeMode = userChoice
    }
    return this._themeMode
  }

  set themeMode(themeMode: string) {
    this._themeMode = themeMode
  }

  get theme() {
    return this._theme
  }
}

export default ThemeStore
