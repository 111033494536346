import { Typography, Box, Button, Autocomplete, CircularProgress, TextField, debounce } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useMemo, useState } from 'react'
import { trackPromise, usePromiseTracker } from 'react-promise-tracker'
import ButtonLoader from '../../../components/button-loader'
import { StoreContext } from '../../../stores/store.context'
import WarningIcon from '@mui/icons-material/Warning'

interface TransferUserDataModalProps {
  id: string
  message: string
  action: string
  callback?: any
}

const TransferUserDataModal: React.FC<TransferUserDataModalProps> = (props: TransferUserDataModalProps) => {
  const { modalStore, i18nStore, userStore, toastStore, authStore } = useContext(StoreContext)
  const [to, setTo] = useState('')
  const [openEvaluators, setOpenEvaluators] = useState(false)
  const { promiseInProgress } = usePromiseTracker({ area: 'loading-evaluators' })

  const fetchEvaluatorsOnType = useMemo(
    () =>
      debounce((request: { input: string }) => {
        if (authStore.tokenData) {
          var filter = JSON.stringify({
            name: request.input,
            status: 'active|new',
          })

          getEvaluators(filter)
        }
      }, 500),
    []
  )

  const getEvaluators = async (filter: any) => {
    await trackPromise(
      userStore.listEvaluators(
        authStore.tokenData!.tenant,
        parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
        parseInt(process.env.REACT_APP_DATA_LIMIT as string),
        'createdAt',
        'desc',
        filter
      ),
      'loading-evaluators'
    )

    if (userStore.evaluators) {
      userStore.evaluators.paginatedData = userStore.evaluators.paginatedData.filter(
        (user) => user._id.toString() !== props.id.toString()
      )
    }
  }

  useEffect(() => {
    getEvaluators({})
  }, [authStore.tokenData, modalStore, userStore])

  const transferUserData = async () => {
    try {
      const dataTransfered = await trackPromise(
        userStore.transferUserData(props.id, to, props.action),
        'transfer-user-data'
      )
      if (dataTransfered) {
        if (props.action === 'delete') {
          toastStore.pushToast({ message: i18nStore.dictionary.userDeleted, type: 'success' })
          if (authStore.tokenData) {
            await userStore.listUsersByTenant(
              authStore.tokenData.tenant,
              parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string),
              parseInt(process.env.REACT_APP_DATA_LIMIT as string)
            )
          }
        } else {
          if (props.callback) {
            props.callback()
          }
        }
      }
      modalStore.handleClose()
    } catch (e: any) {
      modalStore.handleClose()
      toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  return (
    <Fragment>
      <WarningIcon color="warning" sx={{ alignSelf: 'center', fontSize: '5rem' }} />

      <Typography variant="h4" color={'text.primary'} align="center" sx={{ my: 3 }}>
        {i18nStore.dictionary.transferUserData}
      </Typography>

      <Typography variant="body2" color={'secondary.lighter'} align="center" sx={{ mb: 3 }}>
        {props.message}
      </Typography>

      <Typography variant="body2" color={'secondary.lighter'} align="center" sx={{ mb: 3 }}>
        {i18nStore.dictionary.selectNewEvaluator}
      </Typography>

      {userStore.evaluators && (
        <Autocomplete
          sx={{ mb: 3 }}
          id="evaluators-autocomplete"
          open={openEvaluators}
          onOpen={() => {
            setOpenEvaluators(true)
          }}
          onClose={() => setOpenEvaluators(false)}
          onChange={(event, newValue, reason) => {
            if (newValue) {
              setTo(newValue._id)
            }

            if (reason === 'clear') {
              setTo('')
              getEvaluators({})
              return
            }
          }}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          includeInputInList
          onInputChange={(event, newInputValue) => fetchEvaluatorsOnType({ input: newInputValue })}
          getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
          options={userStore.evaluators.paginatedData}
          loading={promiseInProgress}
          renderInput={(params) => (
            <TextField
              name="evaluator"
              {...params}
              label={i18nStore.dictionary.evaluator}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {promiseInProgress ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      )}

      <Typography variant="body2" color={'secondary.lighter'} fontWeight={700} align="center" sx={{ mb: 3 }}>
        {i18nStore.dictionary.cannotBeUndoneAction}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          disableElevation
          size="large"
          type="button"
          variant="text"
          color="primary"
          onClick={() => modalStore.handleClose()}
          sx={{ mr: 2 }}
        >
          {i18nStore.dictionary.noBack}
        </Button>

        <ButtonLoader
          loadingText="Processando..."
          text={i18nStore.dictionary.yesContinue}
          area="transfer-user-data"
          disabled={!to || to === ''}
          onClick={() => transferUserData()}
        />
      </Box>
    </Fragment>
  )
}

export default observer(TransferUserDataModal)
