import { Box, Button, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext } from 'react'
import ButtonLoader from '../../../components/button-loader'
import { StoreContext } from '../../../stores/store.context'
import SendIcon from '@mui/icons-material/Send'
import { useSearchParams } from 'react-router-dom'
import { RolesEnum } from '../../../enums/roles.enum'

interface AssessmentEvaluateUserNavigationProps {
  isManagerStep: Function
  handleSave: Function
  step?: string
}

const AssessmentEvaluateUserNavigation: React.FC<AssessmentEvaluateUserNavigationProps> = (
  props: AssessmentEvaluateUserNavigationProps
) => {
  const { i18nStore, assessmentStore, authStore } = useContext(StoreContext)
  const [searchParams] = useSearchParams()

  const checkCompetenceValid = () => {
    if (assessmentStore.assessmentEvaluationForm && assessmentStore.assessment) {
      let progress = props.isManagerStep()
        ? assessmentStore.assessmentEvaluationForm.currentProgressManager
        : assessmentStore.assessmentEvaluationForm.currentProgress

      let current = props.isManagerStep()
        ? assessmentStore.assessmentEvaluationForm.currentCompetenceManager
        : assessmentStore.assessmentEvaluationForm.currentCompetence

      if (props.step) {
        if (props.step === 'evaluation_feedback') {
          progress = assessmentStore.assessmentEvaluationForm.currentProgressFeedback
          current = assessmentStore.assessmentEvaluationForm.currentCompetenceFeedback
        }
      }

      if (progress !== 100) {
        const allValuesAreTrue = assessmentStore.assessment.competencies[current - 1].competenceValues.every(
          (competenceValue) => competenceValue.isValid === true
        )
        return allValuesAreTrue
      }
    }
    return false
  }

  const handleProgress = () => {
    if (assessmentStore.assessmentEvaluationForm) {
      let progress = props.isManagerStep()
        ? assessmentStore.assessmentEvaluationForm.currentProgressManager
        : assessmentStore.assessmentEvaluationForm.currentProgress

      if (!props.isManagerStep()) {
        if (assessmentStore.assessmentEvaluationForm.currentCompetence >= 1) {
          progress =
            ((assessmentStore.assessmentEvaluationForm.currentCompetence - 1) /
              assessmentStore.assessment!.competencies.length) *
            100
          assessmentStore.assessmentEvaluationForm = {
            ...assessmentStore.assessmentEvaluationForm,
            currentProgress: progress,
          }

          if (progress === 100) {
            props.handleSave(false)
          }
        }
      } else {
        if (props.step && props.step === 'evaluation_feedback') {
          if (assessmentStore.assessmentEvaluationForm.currentCompetenceFeedback >= 1) {
            progress =
              ((assessmentStore.assessmentEvaluationForm.currentCompetenceFeedback - 1) /
                assessmentStore.assessment!.competencies.length) *
              100
            assessmentStore.assessmentEvaluationForm = {
              ...assessmentStore.assessmentEvaluationForm,
              currentProgressFeedback: progress,
            }

            if (progress === 100) {
              props.handleSave(false)
            }
          }
        } else {
          if (assessmentStore.assessmentEvaluationForm.currentCompetenceManager >= 1) {
            progress =
              ((assessmentStore.assessmentEvaluationForm.currentCompetenceManager - 1) /
                assessmentStore.assessment!.competencies.length) *
              100
            assessmentStore.assessmentEvaluationForm = {
              ...assessmentStore.assessmentEvaluationForm,
              currentProgressManager: progress,
            }

            if (progress === 100) {
              props.handleSave(false)
            }
          }
        }
      }
    }
  }

  const getButtonVisibility = (button: string): string => {
    if (assessmentStore.assessmentEvaluationForm && assessmentStore.assessment) {
      if (button === 'next') {
        if (props.isManagerStep()) {
          if (props.step && props.step === 'evaluation_feedback') {
            return assessmentStore.assessmentEvaluationForm.currentCompetenceFeedback ===
              assessmentStore.assessment.competencies.length
              ? 'hidden'
              : 'flex'
          }

          return assessmentStore.assessmentEvaluationForm.currentCompetenceManager ===
            assessmentStore.assessment.competencies.length
            ? 'hidden'
            : 'flex'
        }

        return assessmentStore.assessmentEvaluationForm.currentCompetence ===
          assessmentStore.assessment.competencies.length
          ? 'hidden'
          : 'flex'
      }

      if (button === 'prev') {
        if (props.isManagerStep()) {
          if (props.step && props.step === 'evaluation_feedback') {
            return assessmentStore.assessmentEvaluationForm.currentCompetenceFeedback === 1 ? 'hidden' : 'flex'
          }
          return assessmentStore.assessmentEvaluationForm.currentCompetenceManager === 1 ? 'hidden' : 'flex'
        }
        return assessmentStore.assessmentEvaluationForm.currentCompetence === 1 ? 'hidden' : 'flex'
      }
    }

    return 'hidden'
  }

  const removeButtonFromDOM = (): boolean => {
    if (assessmentStore.assessmentEvaluationForm && assessmentStore.assessment) {
      if (props.isManagerStep() && assessmentStore.assessmentUserData) {
        if (assessmentStore.assessmentUserData.step === 'evaluation_completed') {
          return true
        }

        if (props.step && props.step === 'evaluation_feedback') {
          return (
            assessmentStore.assessmentEvaluationForm.currentCompetenceFeedback <
            assessmentStore.assessment.competencies.length
          )
        }

        return (
          assessmentStore.assessmentEvaluationForm.currentCompetenceManager <
          assessmentStore.assessment.competencies.length
        )
      }

      return assessmentStore.assessmentEvaluationForm.currentCompetence < assessmentStore.assessment.competencies.length
    }

    return false
  }

  const handleNext = () => {
    if (assessmentStore.assessmentEvaluationForm) {
      assessmentStore.assessmentEvaluationForm = {
        ...assessmentStore.assessmentEvaluationForm,
        currentCompetence: !props.isManagerStep()
          ? assessmentStore.assessmentEvaluationForm.currentCompetence + 1
          : assessmentStore.assessmentEvaluationForm.currentCompetence,
        currentCompetenceManager:
          props.isManagerStep() && props.step !== 'evaluation_feedback'
            ? assessmentStore.assessmentEvaluationForm.currentCompetenceManager + 1
            : assessmentStore.assessmentEvaluationForm.currentCompetenceManager,
        currentCompetenceFeedback:
          props.isManagerStep() && props.step === 'evaluation_feedback'
            ? assessmentStore.assessmentEvaluationForm.currentCompetenceFeedback + 1
            : assessmentStore.assessmentEvaluationForm.currentCompetenceFeedback,
      }

      handleProgress()
    }
  }

  const handlePrevious = () => {
    if (assessmentStore.assessmentEvaluationForm) {
      assessmentStore.assessmentEvaluationForm = {
        ...assessmentStore.assessmentEvaluationForm,
        currentCompetence: !props.isManagerStep()
          ? assessmentStore.assessmentEvaluationForm.currentCompetence - 1
          : assessmentStore.assessmentEvaluationForm.currentCompetence,
        currentCompetenceManager: props.isManagerStep()
          ? assessmentStore.assessmentEvaluationForm.currentCompetenceManager - 1
          : assessmentStore.assessmentEvaluationForm.currentCompetenceManager,
        currentCompetenceFeedback:
          props.isManagerStep() && props.step === 'evaluation_feedback'
            ? assessmentStore.assessmentEvaluationForm.currentCompetenceFeedback - 1
            : assessmentStore.assessmentEvaluationForm.currentCompetenceFeedback,
      }

      handleProgress()
    }
  }

  const getNavStep = () => {
    let navStep = <></>
    if (props.isManagerStep()) {
      if (
        assessmentStore.assessmentEvaluationForm &&
        assessmentStore.assessment &&
        assessmentStore.assessmentEvaluationForm.currentCompetenceManager <=
          assessmentStore.assessment.competencies.length
      ) {
        navStep = (
          <Typography>
            {i18nStore.dictionary.competence} {assessmentStore.assessmentEvaluationForm.currentCompetenceManager} de{' '}
            {assessmentStore.assessment.competencies.length}
          </Typography>
        )
      }
    } else {
      if (
        assessmentStore.assessmentEvaluationForm &&
        assessmentStore.assessment &&
        assessmentStore.assessmentEvaluationForm.currentCompetence <= assessmentStore.assessment.competencies.length
      ) {
        navStep = (
          <Typography>
            {i18nStore.dictionary.competence} {assessmentStore.assessmentEvaluationForm.currentCompetence} de{' '}
            {assessmentStore.assessment.competencies.length}
          </Typography>
        )
      }
    }
    return navStep
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Button
        variant="text"
        onClick={handlePrevious}
        sx={{
          mr: 2,
          visibility: getButtonVisibility('prev'),
        }}
      >
        {i18nStore.dictionary.back}
      </Button>
      {getNavStep()}
      {removeButtonFromDOM() && authStore.tokenData?.role !== RolesEnum.administrator && (
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={!checkCompetenceValid() && !searchParams.get('view')}
          sx={{
            visibility: getButtonVisibility('next'),
          }}
        >
          {i18nStore.dictionary.next}
        </Button>
      )}
      {!removeButtonFromDOM() && !searchParams.get('view') && authStore.tokenData?.role !== RolesEnum.administrator && (
        <ButtonLoader
          onClick={() => handleNext()}
          disabled={!checkCompetenceValid()}
          variant="contained"
          loadingText={i18nStore.dictionary.loading}
          color="success"
          text={
            <Fragment>
              <Typography>{i18nStore.dictionary.finish}</Typography>
              <SendIcon sx={{ ml: 2 }} />
            </Fragment>
          }
          area="finishing-evaluation"
          sx={{
            alignItems: 'center',
          }}
        />
      )}
      {removeButtonFromDOM() && authStore.tokenData?.role === RolesEnum.administrator && (
        <ButtonLoader
          onClick={() => handleNext()}
          variant="contained"
          loadingText={i18nStore.dictionary.loading}
          color="success"
          text={
            <Fragment>
              <Typography>{i18nStore.dictionary.next}</Typography>
              <SendIcon sx={{ ml: 2 }} />
            </Fragment>
          }
          area="finishing-evaluation"
          sx={{
            alignItems: 'center',
          }}
        />
      )}
    </Box>
  )
}

export default observer(AssessmentEvaluateUserNavigation)
