import { Menu, MenuList, MenuItem, ListItemIcon, ListItemText, IconButton, Divider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { StoreContext } from '../../../stores/store.context'
import AssessmentIcon from '@mui/icons-material/Assessment'
import { Assessment } from '../../../models/assessment.model'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ModalOpts } from '../../../models/modal.model'
import AssessmentForm from '../forms/assessment-form'
import { useNavigate } from 'react-router-dom'
import DeleteAssessmentModal from '../modals/delete-assessment.modal'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { RolesEnum } from '../../../enums/roles.enum'
import DoneIcon from '@mui/icons-material/Done'
import FinishAssessmentModal from '../modals/finish-assessment.modal'
import PersonIcon from '@mui/icons-material/Person'
import UsersListModal from '../../manage-users/modals/users-list.modal'
import { UserData } from '../../../models/user.model'

interface AssessmentMenuProps {
  assessment: Assessment
}

const AssessmentMenu: React.FC<AssessmentMenuProps> = (props: AssessmentMenuProps) => {
  const { i18nStore, modalStore, tenantStore, authStore, assessmentStore, toastStore } = useContext(StoreContext)

  const [excludeUsers, setExcludeUsers] = useState<Array<string>>(props.assessment.users.map((data) => data.user._id))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const navigate = useNavigate()

  const checkAccess = (): boolean => {
    if (tenantStore.tenant && authStore.tokenData) {
      return (
        tenantStore.tenant.settings.allowEvaluatorsCreateAndDeleteAssessments ||
        authStore.tokenData.role === RolesEnum.administrator
      )
    }
    return false
  }

  const onSelectUsers = async (users: Array<UserData>) => {
    if (props.assessment && users.length > 0) {
      let usersAdd: Array<{ user: string; progress: string; step: string }> = []
      users.forEach((user) => {
        usersAdd.push({
          user: user._id,
          progress: 'not_started',
          step: 'self_evaluation',
        })
      })
      let assessment = await assessmentStore.addUsersToAssessment(usersAdd, props.assessment._id)
      if (assessment) {
        toastStore.pushToast({ message: i18nStore.dictionary.usersAdded, type: 'success' })
        setExcludeUsers(assessment.users.map((data: any) => data.user))
      }
    }
  }

  return (
    <Fragment>
      <IconButton color="primary" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        sx={{
          width: 320,
          maxWidth: '100%',
          '& .MuiPaper-root': {
            backgroundColor: 'background.default',
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuList>
          {props.assessment.status === 'active' && (
            <MenuItem
              onClick={() => {
                handleClose()
                const modalOpts: ModalOpts = {
                  size: 'sm',
                }
                modalStore.openModal(
                  <UsersListModal emitSelected={onSelectUsers} excludeUsers={excludeUsers} />,
                  modalOpts
                )
              }}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" sx={{ color: 'primary.main' }} />
              </ListItemIcon>
              <ListItemText>{i18nStore.dictionary.addUser}</ListItemText>
            </MenuItem>
          )}

          {(props.assessment.status === 'active' || props.assessment.status === 'done') && (
            <MenuItem
              onClick={() => {
                navigate(`/secure/assessment/${props.assessment._id}/summary`)
              }}
            >
              <ListItemIcon>
                <AssessmentIcon fontSize="small" sx={{ color: 'warning.main' }} />
              </ListItemIcon>
              <ListItemText>{i18nStore.dictionary.summary}</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              const modalOpts: ModalOpts = {
                size: 'lg',
              }
              modalStore.openModal(<AssessmentForm _id={props.assessment._id} />, modalOpts)
              handleClose()
            }}
          >
            {props.assessment.status !== 'done' ? (
              <Fragment>
                <ListItemIcon>
                  <EditIcon fontSize="small" sx={{ color: 'warning.main' }} />
                </ListItemIcon>
                <ListItemText>{i18nStore.dictionary.edit}</ListItemText>
              </Fragment>
            ) : (
              <Fragment>
                <ListItemIcon>
                  <RemoveRedEyeIcon fontSize="small" sx={{ color: 'primary.main' }} />
                </ListItemIcon>
                <ListItemText>{i18nStore.dictionary.view}</ListItemText>
              </Fragment>
            )}
          </MenuItem>

          {props.assessment.status === 'active' && (
            <MenuItem
              onClick={() => {
                modalStore.openModal(<FinishAssessmentModal id={props.assessment._id} />, { size: 'sm' })
                handleClose()
              }}
            >
              <Fragment>
                <ListItemIcon>
                  <DoneIcon fontSize="small" sx={{ color: 'success.main' }} />
                </ListItemIcon>
                <ListItemText>{i18nStore.dictionary.finish}</ListItemText>
              </Fragment>
            </MenuItem>
          )}

          {checkAccess() && <Divider />}
          {checkAccess() && (
            <MenuItem
              onClick={() => {
                modalStore.openModal(<DeleteAssessmentModal id={props.assessment._id} />, { size: 'sm' })
                handleClose()
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" sx={{ color: 'error.main' }} />
              </ListItemIcon>
              <ListItemText>{i18nStore.dictionary.delete}</ListItemText>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Fragment>
  )
}

export default observer(AssessmentMenu)
